export const progressStateColors = {
  past: "var(--primary-color)",
  present: "#ffffff",
  future: "#dedede",
};
export const riskProfileOptions = [
  { code: "conservative", display_name: "Conservative" },
  { code: "risk-averse", display_name: "Risk Averse" },
  { code: "balance", display_name: "Balance" },
  { code: "growth", display_name: "Growth" },
  { code: "aggressive", display_name: "Aggressive" },
];
export const lifeStageOptions = [
  { code: "single", display_name: "Single" },
  { code: "married", display_name: "Married" },
  {
    code: "married-with-young-children",
    display_name: "Married with Young Children",
  },
  {
    code: "married-with-older-children",
    display_name: "Married with Older Children",
  },
  { code: "near-retirement", display_name: "Near Retirement" },
  { code: "retirement", display_name: "Retirement" },
  { code: "others", display_name: "Others" },
];
export const financialAndFamilyGoalOptions = [
  { code: "saving-and-investments", display_name: "Saving and Investments" },
  { code: "regular-income", display_name: "Regular Income" },
  { code: "retirement", display_name: "Retirement" },
  { code: "child", display_name: "Child" },
  { code: "protection", display_name: "Protection" },
];
