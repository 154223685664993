import styled from "styled-components";
import * as mq from "../../../../styles/mediaQueries";
export const HeaderWrapper = styled.div`
  border: 1px solid #e2e2e2;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  background-color: #fff;
  margin: 16px auto;
  border-radius: 45px;
  cursor: pointer;
  display: flex;
  transition: all 0.3s ease;
  width: 86%;
  ${mq.md} {
    width: 80%;
  }
  ${mq.sm} {
    width: 100%;
    border-radius: 0px;
    background: var(--tertiary-shade);
    margin-top: 0;
    margin-bottom: 15px;
    /* padding-right: 10px; */
    overflow: scroll;
    z-index: 100;
    right: 0;
    left: 0;
    top: 8rem;
    position: fixed;
    border: 0 20px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const HeaderLabel = styled.div`
  display: inline-block;
  flex: 1 1;
  text-align: center;
  font-size: 0.8rem;
  padding: 16px 0;
  border-radius: 45px;
  font-family: "Inter";
  font-weight: 500;
  background-color: ${props =>
    props.active ? "var(--primary-color-dark)" : "#fff"};
  color: ${props => (props.active ? "#fff" : "var(--primary-text)")};
  ${mq.sm} {
    background-color: var(--tertiary-shade);
    border-bottom: 5px solid
      ${props =>
        props.active ? "var(--primary-color-dark)" : "var(--tertiary-shade)"};
    color: ${props => (props.active ? "var(--primary-color-dark)" : "#43464a")};
    border-radius: 0;
    // font-weight: bold;
    font-size: 11px;
    min-width: 35%;
    font-weight: 700;
    padding: 10px 0;
    margin: 0 5px;
  }
`;
export const HeaderContainer = styled.div`
  width: 100%;
  ${mq.sm} {
    margin-top: 130px;
    padding: 0;
  }
`;
