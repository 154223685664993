import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "styled-components/macro";
import ShareQuotesCard from "../../pages/product-page/ShareQuotes/ShareQuotesCard";
import ShareShortlistedQuotes from "../../pages/product-page/components/ShortlistedQuotes/ShareShortlistedQuotes";
import { clearQuotes } from "../../pages/product-page/components/comparePlanPopup.slice";
import { FaShare } from "react-icons/fa";
const ShareQuoteBtn = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isNewCompare = location.pathname.includes("new-compare");
  const [showModal, setShowModal] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const { quotesLoading } = useSelector(state => state.quotes);
  const companies = Object.keys(quotesLoading);
  const mainCompanies = companies.filter(val => val !== "undefined");
  const handleClose = () => {
    setCurrentSection(0);
    setShowModal(false);
  };
  const { quotesList } = useSelector(state => state?.newCompare);
  return (
    <>
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          dispatch(clearQuotes());
          if (
            (mainCompanies?.length &&
              mainCompanies?.some(item => quotesLoading[item] === true)) ||
            (isNewCompare && quotesList && quotesList.length < 1)
          ) {
            toast.info(
              isNewCompare
                ? "Please select at least one quote to share"
                : "Some Quotes are loading.",
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              },
            );
          } else setShowModal(true);
        }}
        style={{
          fontFamily: "Inter",
          fontWeight: "700",
          fontSize: ".6rem",
          height: "1.8rem",
        }}
      >
        <FaShare className="icon-left" style={{ marginRight: "1px" }} />
        {"  "} Share Quote
      </button>

      {/* ----------------modal content--------------- */}

      {isNewCompare && quotesList.length >= 1 && (
        <ShareQuotesCard
          show={showModal}
          handleClose={handleClose}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
      )}

      {location.pathname === "/quote" ? (
        <ShareQuotesCard
          show={showModal}
          handleClose={handleClose}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
      ) : (
        !isNewCompare && (
          <ShareShortlistedQuotes show={showModal} handleClose={handleClose} />
        )
      )}
    </>
  );
};

export default ShareQuoteBtn;
