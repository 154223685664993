import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../Landing/input.slice";

const useInputPageHook = () => {
  const {
    terminputpage: termInputPage,
    termsettings,
    termageband,
    termannualincome,
    termoccupation,
    termeducation,
    terminputscompany,
    termcompanies,
  } = useSelector(state => state.frontendBoot?.frontendData);
  const {
    termsuitabilityflagstatus,
    termsuitabilitydisclaimer,
    termwhatsappflagstatus,
    termdisclaimer,
    terminputpage1,
    terminputpage2,
    terminputpage3,
    terminsurancedescription,
    termleadpagestatus,
  } = termInputPage || {};
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const isEnquiry = window.location.href.includes("enquiryId");

  useEffect(() => {
    dispatch(setData({ whatsapp_flag: checked ? "Yes" : "No" }));
  }, [checked, dispatch]);

  return {
    checked,
    setChecked,
    isEnquiry,
    dispatch,
    termSuitabilityFlagStatus: termsuitabilityflagstatus,
    termSuitabilityDisclaimer: termsuitabilitydisclaimer,
    parsedTermSuitabilityDisclaimer: parse(termsuitabilitydisclaimer),
    termWhatsappFlagStatus: termwhatsappflagstatus,
    termDisclaimer: termdisclaimer,
    parsedTermDisclaimer: parse(termdisclaimer),
    termInputPage1: terminputpage1,
    termInputPage2: terminputpage2,
    termInputPage3: terminputpage3,
    termLeadPageStatus: termleadpagestatus,
    termInsuranceDescription: terminsurancedescription,
    parsedTermInsuranceDescription: parse(terminsurancedescription),
    inputPageBackgroundImage: termsettings?.background_image,
    termAgeBand: termageband,
    termAnnualIncome: termannualincome,
    termOccupation: termoccupation,
    termEducation: termeducation,
    isOTPJourney: termsettings?.otp_journey,
    termSettings: termsettings,
    termInputsCompany: terminputscompany,
    termCompanies: termcompanies,
  };
};

export default useInputPageHook;
