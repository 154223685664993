import { CompareHeader } from "./header";
import { ProductCards } from "./components/ProductCards";
import styled from "styled-components/macro";
import { CompareFeaturesSections } from "./components/CompareFeaturesSections";
import { LoadEnquiry } from "../../components";
import * as mq from "../../styles/mediaQueries";
import ComparePageFilters from "./components/ComparePageFilters";
// import { useDefaultFilters } from "../product-page/Filters/helper";
// import { RiFilterOffLine } from "react-icons/ri";

import CompareFiltersMobile from "./components/CompareFiltersMobile";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import Footer from "../../components/Footer";

function ComparePage() {
  const matches = useMediaQuery(mq.smForHook);
  return (
    <>
      <StyledContainer>
        <div
          css={`
            position: sticky;
            top: 0;
            z-index: 100;
          `}
        >
          {matches ? (
            <div>
              <CompareFiltersMobile />
            </div>
          ) : (
            <ComparePageFilters />
          )}
        </div>
        <CompareHeader />
        <ComparePageMain />
      </StyledContainer>
      <Footer />
    </>
  );
}

export default ComparePage;

const StyledContainer = styled.div`
  max-width: 1170px;
  width: 90%;
  margin: auto;
`;

function ComparePageMain({ ...props }) {
  return (
    <LoadEnquiry>
      <div
        id="compare-page-main"
        css={`
          margin-bottom: 3em;
          height: auto;
        `}
        {...props}
      >
        <ProductCards />
        <CompareFeaturesSections />
      </div>
    </LoadEnquiry>
  );
}
