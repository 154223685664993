import moment from "moment";

// getAge("01/01/2000") ====> 23 years

export const getAge = dob =>
  `${moment().diff(moment(dob, "YYYY-MM-DD"), "year")} years`;

// getAgeValue("01/01/2000") ====> 23

export const getAgeValue = dob =>
  moment().diff(moment(dob, "YYYY-MM-DD"), "year");
