import { useInputPageHook } from "../../hooks";
import useCommunicationForm from "./useCommunicationForm";
import {
  InputFormBackButtonStyled,
  InputFormButtonWrapperStyled,
  InputFormContinueButtonStyled,
  InputWrapperStyled,
} from "../../styles/inputForms.styled";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader/ButtonLoader";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  lowerCase,
} from "../../../ProposalPage/inputUtils/onInput";
import { saveOtpData } from "../../../Landing/input.slice";
import OTPPopupForm from "../OTPPopupForm";
import { InputFormsTitle, InputPageTextInput } from "../../components";

const CommunicationForm = ({ current, setCurrent }) => {
  const {
    termInputPage3 = "<h1>How can we reach out to you ?</h1>",
    termSuitabilityFlagStatus,
    isOTPJourney,
    dispatch,
  } = useInputPageHook();
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    clearErrors,
    email,
    setEmail,
    mobileNumber,
    setMobileNumber,
    otp,
    setOtp,
    name,
    onNameChange,
    isEnquiryLoading,
    otpResponse,
    otpVerified,
  } = useCommunicationForm();

  return (
    <InputFormsTitle
      title={termInputPage3}
      current={current}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputWrapperStyled>
        <InputPageTextInput
          {...register("name")}
          label="Full Name*"
          size="large"
          capitalize
          onInput={allowOnlyUniqueAlphabetsNoSpace}
          onChange={e => {
            onNameChange(e);
            register("name").onChange(e);
          }}
          error={errors.name && errors.name.message}
          value={name}
          inputProps={{ minLength: 2, maxLength: 30, autoFocus: true }}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageTextInput
          label="Email*"
          size="large"
          {...register("email")}
          onChange={e => {
            e.target.value = e.target.value.replace(" ", "");
            lowerCase(e);
            register("email").onChange(e);
            setEmail(e.target.value);
          }}
          error={errors.email && errors.email.message}
          value={email}
          inputProps={{ minLength: 2, maxLength: 50, inputMode: "email" }}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageTextInput
          label="Mobile Number*"
          size="large"
          {...register("mobile")}
          onChange={e => {
            setMobileNumber(e);
            register("mobile").onChange(e);
            trigger("mobile");
            clearErrors("mobile");
          }}
          error={errors.mobile}
          helperText={errors.mobile && errors.mobile.message}
          value={mobileNumber}
          onInput={allowOnlyNumbers}
          inputProps={{ maxLength: 10, inputMode: "numeric" }}
        />
      </InputWrapperStyled>
      <InputFormButtonWrapperStyled>
        <InputFormBackButtonStyled
          disabled={isEnquiryLoading && true}
          onClick={() => {
            if (termSuitabilityFlagStatus === "Yes")
              setCurrent(prev => prev - 1);
            else setCurrent(prev => prev - 2);
          }}
        >
          Back
        </InputFormBackButtonStyled>
        {isOTPJourney === "Yes" && otpVerified === false ? (
          <InputFormContinueButtonStyled
            onClick={e => {
              e.preventDefault();
              trigger("name");
              trigger("email");
              trigger("mobile");
              if (email && !errors?.email && mobileNumber && !errors?.mobile) {
                dispatch(
                  saveOtpData({
                    mobile: mobileNumber,
                    email: email,
                    section: "term",
                  }),
                );
                setOtp(true);
              }
            }}
          >
            View Quotes
          </InputFormContinueButtonStyled>
        ) : otpResponse?.status === true &&
          otpResponse.message === "Verified" ? (
          <InputFormContinueButtonStyled
            type="submit"
            disabled={isEnquiryLoading && true}
          >
            {isEnquiryLoading ? <ButtonLoader /> : "View Quotes"}
          </InputFormContinueButtonStyled>
        ) : (
          <InputFormContinueButtonStyled
            type="submit"
            disabled={isEnquiryLoading && true}
          >
            {isEnquiryLoading ? <ButtonLoader /> : "View Quotes"}
          </InputFormContinueButtonStyled>
        )}
      </InputFormButtonWrapperStyled>
      <OTPPopupForm
        mobileNumber={mobileNumber}
        email={email}
        setOtp={setOtp}
        otp={otp}
      />
    </InputFormsTitle>
  );
};

export default CommunicationForm;
