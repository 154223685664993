import { setData } from "../../Landing/input.slice";
import { useInputPageHook } from "../hooks";
import {
  SuitabilityCheckBoxStyled,
  SuitabilityContainerStyled,
} from "../styles/inputForms.styled";

const Suitability = () => {
  const {
    dispatch,
    termSuitabilityDisclaimer,
    parsedTermSuitabilityDisclaimer,
  } = useInputPageHook();
  return (
    <SuitabilityContainerStyled>
      <div>
        <SuitabilityCheckBoxStyled
          size="small"
          name={"suitability_check"}
          // defaultChecked={checked}
          onChange={e =>
            dispatch(
              setData({
                suitability: { suitability_check: e.target.checked },
              }),
            )
          }
          disableRipple
        />
      </div>
      <div>
        {termSuitabilityDisclaimer && (
          <span>{parsedTermSuitabilityDisclaimer}</span>
        )}
      </div>
    </SuitabilityContainerStyled>
  );
};

export default Suitability;
