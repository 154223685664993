import {
  useGetCompareFeaturesQuery,
  useGetCompareProductsQuery,
  useGetQuoteQuery,
  useGetRidersQuery,
} from "../../riders/services/rider";
import styled from "styled-components/macro";
import { FeatureSection, useFeatureSection } from "../FeatureSection";
import { useEffect, useState } from "react";
import { RiderAddRemove } from "./RiderAddRemove";
// import { amount } from "../../../utils";
import CorrectIcon from "../../../assets/images/correct.png";
import WrongIcon from "../../../assets/images/wrong.png";
import { useDispatch, useSelector } from "react-redux";
import * as mq from "../../../styles/mediaQueries";
import { OverlayTrigger } from "react-bootstrap";
import { FiCheck, FiX } from "react-icons/fi";
import {
  addCompareFeaturesData,
  hasChildCount,
  resetChildCount,
  selectCompareFeatureesData,
  selectShowDifference,
  setSelectedFeatures,
  setShowSelected,
} from "../compare.slice";
import { FullScreenLoader } from "../../../components";
import { getAnnualIncome } from "../../../components/navbar/helper";
import renderTooltip from "../../../components/Tooltip/Tooltip";
import { Checkbox } from "@mui/material";
import {
  basicFeaturesFields,
  insurerFields,
  planBenefitsFields,
  planTypeFields,
  premiumPaymentFields,
  selectedDetailsFields,
} from "../compare_fields";
import { selectTermFilters } from "../../product-page/Filters/Filters.slice";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import { FaInfoCircle } from "react-icons/fa";
function CompareFeaturesSections({ ...props }) {
  const { isLoading } = useGetCompareProductsQuery();
  const { showSelected } = useSelector(state => state.compare);
  const [showFeatures, setShowFeatures] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!showFeatures.length) {
      dispatch(setShowSelected(false));
    }
    dispatch(setSelectedFeatures(showFeatures));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFeatures]);
  if (isLoading) return <FullScreenLoader />;
  return (
    <>
      <div
        css={`
          ${mq.sm} {
            margin-top: 225px;
          }
        `}
        {...props}
      >
        {((showSelected &&
          showFeatures.some(item => selectedDetailsFields.includes(item))) ||
          !showSelected) && (
          <FeatureSection heading="Selected Details">
            <FeatureRow
              featureName="cover_upto_age"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="pay_till_age"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="cover_amt"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
          </FeatureSection>
        )}
        {((showSelected &&
          showFeatures.some(item => basicFeaturesFields.includes(item))) ||
          !showSelected) && (
          <FeatureSection heading="Basic Features">
            <FeatureRow
              featureName="max_cover_amt"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="max_entry_age"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="max_maturity_age"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="max_pt"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
          </FeatureSection>
        )}
        {((showSelected &&
          showFeatures.some(item => planTypeFields.includes(item))) ||
          !showSelected) && (
          <FeatureSection heading="Plan Type">
            <FeatureRow
              featureName="basic_life_cover"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="rop"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="survival_benefit"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="increasing_cover"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="reducing_cover"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
          </FeatureSection>
        )}
        {((showSelected &&
          showFeatures.some(item => premiumPaymentFields.includes(item))) ||
          !showSelected) && (
          <FeatureSection heading="Premium Payment Options">
            <FeatureRow
              featureName="regular_pay"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="limited_pay"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="single_pay"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
          </FeatureSection>
        )}
        {((showSelected &&
          showFeatures.some(item => planBenefitsFields.includes(item))) ||
          !showSelected) && (
          <FeatureSection heading="Plan Benefits">
            <FeatureRow
              featureName="inbuilt_benefits"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="add_on_benefits"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
          </FeatureSection>
        )}
        {((showSelected &&
          showFeatures.some(item => insurerFields.includes(item))) ||
          !showSelected) && (
          <FeatureSection heading="Insurer Details">
            <FeatureRow
              featureName="claims_ratio"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
            <FeatureRow
              featureName="formation_year"
              showFeatures={showFeatures}
              setShowFeatures={setShowFeatures}
            />
          </FeatureSection>
        )}
      </div>
    </>
  );
}

export { CompareFeaturesSections };

function FeatureRow({
  featureName = "",
  showFeatures,
  setShowFeatures,
  ...props
}) {
  const { heading } = useFeatureSection();

  const dispatch = useDispatch();
  const { data, isLoading } = useGetCompareProductsQuery();

  const showDifference = useSelector(selectShowDifference);

  const compareFeaturesData = useSelector(selectCompareFeatureesData);

  const { showSelected } = useSelector(state => state.compare);
  const [isSameValues, setIsSameValues] = useState(false);
  const sections = Object.values(compareFeaturesData).map(product =>
    product ? product.find(section => section.name === heading) : null,
  );

  const values = sections.map(section =>
    section
      ? Object.values(section.values).find(value => value.key === featureName)
      : null,
  );

  const displayName = values?.filter(i => i?.key === featureName)[0]?.name;

  useEffect(() => {
    if (isLoading) return;
    if (featureName === "add_on_benefits") {
      setIsSameValues(false);
      return;
    }
    if (showDifference && featureName !== "add_on_benefits") {
      dispatch(resetChildCount());
      const filteredValues = values.filter(val => val !== null);

      const isSameValues = filteredValues.every(
        compareValue =>
          compareValue?.compare_value === filteredValues[0]?.compare_value,
      );

      setIsSameValues(isSameValues);
      return;
    }

    setIsSameValues(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDifference, compareFeaturesData, heading, featureName, isLoading]);

  if (isLoading) return <p>Loading...</p>;

  if (isSameValues) {
    return null;
  }
  dispatch(hasChildCount(heading));
  const { products } = data.data;
  if (showSelected && !showFeatures.includes(featureName)) return <></>;

  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        font-family: "Inter";
        font-weight: 700;
        border-top: 1px solid #ececec;
        padding: 1.6em 0;
        ${mq.sm} {
          padding: 0.3em 0;
          text-align: left;
        }
        transition: all 0.2s;
        border-radius: 4px;
        &:hover {
          box-shadow: rgb(100 100 111 / 20%) 0px 3px 12px 0px;
        }
        & > div {
          width: 25%;
          ${mq.sm} {
            width: 50%;
            margin: 5px 0;
            :first-child {
              border-bottom: 1px solid #ececec;
              width: 100%;
            }
          }
        }
      `}
      {...props}
    >
      <div
        css={`
          text-transform: capitalize;
          position: relative;
          padding-left: 1em;
          &::before {
            content: "";
            left: 0px;
            top: -0.1em;
            background-color: #f5bc00;
            border-radius: 0px 10px 10px 0px;
            width: 0.39em;
            height: 1.6em;
            position: absolute;
            left: 0;
          }
        `}
      >
        <div
          css={`
            ${mq.sm} {
              margin-bottom: 5px;
              .MuiCheckbox-root {
                vertical-align: top !important;
              }
              .MuiSvgIcon-root {
                font-size: 1rem !important;
              }
            }
          `}
        >
          <Checkbox
            size="small"
            style={{ padding: 0 }}
            checked={showFeatures.includes(featureName)}
            onClick={e => {
              if (e.target.checked) {
                setShowFeatures(prev => [...prev, featureName]);
              } else
                setShowFeatures(prev =>
                  prev.filter(item => item !== featureName),
                );
            }}
          />{" "}
          <>
            {displayName}
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 0, hide: 100 }}
              overlay={renderTooltip({
                desc: values[0]?.description,
              })}
            >
              <FaInfoCircle className="mx-3" />
            </OverlayTrigger>
          </>
        </div>
        {showFeatures.includes(featureName) && (
          <div
            css={`
              color: var(--primary-color);
              text-decoration: underline;
              padding-left: 24px;
              cursor: pointer;
            `}
            onClick={() => {
              dispatch(setShowSelected(!showSelected));
            }}
          >
            {!showSelected ? "Show only selected" : "Show all rows"}
          </div>
        )}
      </div>
      {products.map(product => (
        <FeatureValueCheck
          featureName={featureName}
          displayName={displayName}
          product={product}
        />
      ))}
    </div>
  );
}

function FeatureValueCheck({ featureName, product, displayName, ...props }) {
  const { data: quoteData } = useGetQuoteQuery({
    company_alias: product.company_alias,
    differentQuote: product.different_quote === "Y",
  });
  const [skipRiders, setSkipRiders] = useState(!quoteData);
  const { isLoading: isRidersLoading } = useGetRidersQuery(
    {
      productId: product.product_id,
      termInsuranceId:
        quoteData &&
        quoteData?.data.filter(
          item => item?.product?.id === product.product_id,
        )[0]
          ? quoteData?.data.filter(
              item => item?.product?.id === product.product_id,
            )[0]?.term_insurance_id
          : "",
    },
    { skip: skipRiders },
  );
  if (featureName === "add_on_benefits")
    return (
      <OptionalBenefits
        featureName={featureName}
        displayName={displayName}
        isRidersLoading={isRidersLoading}
        quoteData={quoteData}
        product={product}
        setSkipRiders={setSkipRiders}
        {...props}
      />
    );
  if (featureName === "Inbuilt Benefits")
    return (
      <InbuiltBenefits
        featureName={featureName}
        isRidersLoading={isRidersLoading}
        quoteData={quoteData}
        product={product}
        setSkipRiders={setSkipRiders}
        {...props}
      />
    );
  return (
    <FeatureValue featureName={featureName} product={product} {...props} />
  );
}

function OptionalBenefits({
  featureName,
  displayName,
  product,
  quoteData,
  isRidersLoading,
  setSkipRiders,
  ...props
}) {
  const [hideRiders, setHideRiders] = useState([]);
  const [recalculateRiders, setRecalculateRiders] = useState([]);
  const addToReCalculateRiders = rider_shortname =>
    setRecalculateRiders([...recalculateRiders, ...rider_shortname]);
  const removeFromReCalculateRiders = recalc_riders =>
    setRecalculateRiders(
      recalculateRiders.filter(
        rider => !recalc_riders.includes(rider.rider_shortname),
      ),
    );
  const addToHideRiders = rider_shortname =>
    setHideRiders([...hideRiders, ...rider_shortname]);
  // const dispatch = useDispatch();
  const removeFromHideRiders = rider_shortname =>
    setHideRiders(hideRiders.filter(rider => rider_shortname === rider));

  const { ridersList } = useSelector(state => state.quotes);
  const isLoading = isRidersLoading;

  useEffect(() => {
    if (quoteData) {
      setSkipRiders(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData]);

  if (isLoading) return <FeatureValueWrap>Loading...</FeatureValueWrap>;

  if (!ridersList[product.product_id]?.length)
    return <FeatureValueWrap>No Addons Benefits Available</FeatureValueWrap>;

  if (ridersList[product.product_id])
    return (
      <FeatureValueWrap {...props}>
        {ridersList[product.product_id] &&
          ridersList[product.product_id].map(rider => {
            if (!rider) return <></>;
            return (
              <RiderAddRemove
                item={rider}
                product={product}
                key={rider?.rider_shortname}
                addToReCalculateRiders={addToReCalculateRiders}
                removeFromReCalculateRiders={removeFromReCalculateRiders}
                hideRiders={hideRiders}
                addToHideRiders={addToHideRiders}
                removeFromHideRiders={removeFromHideRiders}
                recalculateRiders={recalculateRiders}
                productId={product.product_id}
              />
            );
          })}
      </FeatureValueWrap>
    );
}
function InbuiltBenefits({
  featureName,
  product,
  quoteData,
  isRidersLoading,
  setSkipRiders,
  ...props
}) {
  const isLoading = isRidersLoading;

  const { ridersList } = useSelector(state => state.quotes);
  if (isLoading) return <FeatureValueWrap>Loading...</FeatureValueWrap>;
  if (!ridersList[product.product_id])
    return <FeatureValueWrap></FeatureValueWrap>;

  if (
    ridersList[product.product_id] &&
    ridersList[product.product_id]
      .map(item => item.rider_type)
      .includes("Free") === false
  ) {
    return <FeatureValueWrap>No In-built Benefits Available</FeatureValueWrap>;
  }

  if (ridersList[product.product_id])
    return (
      <FeatureValueWrap {...props}>
        {ridersList[product.product_id] &&
          ridersList[product.product_id].map(rider => {
            return rider.rider_type === "Free" ? (
              <>
                <div
                  css={`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1em;
                    padding: 0.5em 2em;
                    font-size: 0.76rem;
                    margin: 0 1em;
                    margin-bottom: 1em;
                    text-align: left;
                    height: auto;
                    background-color: #f1f2f3;
                    ${mq.sm} {
                      padding: 0.5em;
                      font-size: 10px;
                      min-height: 35px;
                      height: auto;
                      align-items: flex-start;
                    }
                  `}
                  {...props}
                >
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 0, hide: 100 }}
                    overlay={renderTooltip({
                      desc: rider.rider_description,
                    })}
                  >
                    <div
                      //title={rider.rider_description}
                      css={`
                        display: flex;
                        align-items: center;
                        gap: 1em;
                        ${mq.sm} {
                          gap: 0.5em;
                          align-items: flex-start;
                        }
                      `}
                    >
                      <input
                        type="checkbox"
                        id={rider.rider_shortname + product.product_id}
                        checked={true}
                      />

                      <label
                        htmlFor={rider.rider_shortname + product.product_id}
                      >
                        {rider.rider_name}
                      </label>
                    </div>
                  </OverlayTrigger>

                  <div
                    css={`
                      min-width: max-content;
                    `}
                  >
                    Free
                  </div>
                </div>
              </>
            ) : (
              <></>
            );
          })}
      </FeatureValueWrap>
    );
}

const arrays = ["inbuilt_benefits", "optional_benefits"];

const booleans = ["Y", "N"];

const addYears = [
  "max_entry_age",
  "max_maturity_age",
  "max_pt",
  "cover_upto_age",
];

function FeatureValue({ featureName, product, ...props }) {
  const dispatch = useDispatch();
  const { heading } = useFeatureSection();
  const { filters } = useSelector(state => state.filters);
  const matches = useMediaQuery(`${mq.smForHook}`);
  const { product_id, company_alias, differentQuote, different_quote } =
    product;
  const { selected_insurers, ...termFilters } = useSelector(selectTermFilters);
  const { data, isLoading, isFetching } = useGetCompareFeaturesQuery({
    product_id,
  });
  const { data: quoteData } = useGetQuoteQuery(
    company_alias
      ? {
          company_alias,
          termFilters,
          differentQuote: (differentQuote || different_quote) === "Y",
        }
      : {
          product_id,
          differentQuote: (differentQuote || different_quote) === "Y",
        },
  );
  const quotes =
    quoteData?.data === undefined
      ? []
      : quoteData?.data?.filter(item => item?.product.id === product_id);
  const quote = quotes[0];

  useEffect(() => {
    if (data) {
      dispatch(addCompareFeaturesData({ product_id, data: data.compare }));
    }
  }, [data, dispatch, product_id, filters]);

  if (isLoading || isFetching)
    return <FeatureValueWrap>Loading...</FeatureValueWrap>;

  const { compare } = data;
  if (!quote) return null;
  let featureObject = {};

  if (["pay_till_age", "cover_amt"].includes(featureName)) {
    featureObject = compare.find(
      feature => feature.name === "Selected Details",
    );
  } else {
    featureObject = compare.find(feature => feature.name === heading);
  }

  if (!featureObject) return <></>;
  if (featureName === "cover_upto_age") {
    const featureValue = quote.term_coverupto;
    return (
      <FeatureValueWrap
        different={featureValue !== termFilters.term_coverupto}
        {...props}
      >
        {featureValue} Years
      </FeatureValueWrap>
    );
  }
  if (featureName === "pay_till_age") {
    const featureValue = quote.term_pay_till;
    return (
      <FeatureValueWrap
        different={featureValue !== termFilters.term_pay_till}
        {...props}
      >
        {featureValue} Years
      </FeatureValueWrap>
    );
  }
  if (featureName === "cover_amt") {
    const featureValue = quote.term_sum_assured;
    return (
      <FeatureValueWrap
        different={featureValue !== termFilters.term_sum_assured}
        {...props}
      >
        {getAnnualIncome(featureValue)}
      </FeatureValueWrap>
    );
  }

  const featureValues = Object.keys(featureObject.values);

  let featureValueCode = featureValues.find(
    featureCode => featureObject.values[featureCode]?.key === featureName,
  );

  if (!featureValueCode) return <FeatureValueWrap></FeatureValueWrap>;

  let featureValue = featureObject.values[featureValueCode].compare_value;

  if (arrays.includes(featureValueCode)) {
    featureValue = featureValue?.split("\n").map(value => <div>{value}</div>);
  }

  if (booleans.includes(featureValue)) {
    if (featureValue === "Y") {
      if (matches) {
        featureValue = (
          <Icon>
            <FiCheck />
          </Icon>
        );
      } else {
        featureValue = <StyledBooleanIcon src={CorrectIcon} alt="Yes" />;
      }
    }

    if (featureValue === "N")
      if (matches) {
        featureValue = (
          <CrossIcon>
            <FiX />
          </CrossIcon>
        );
      } else {
        featureValue = <StyledBooleanIcon src={WrongIcon} alt="No" />;
      }

    // featureValue = <StyledBooleanIcon src={WrongIcon} alt="No" />;
  }

  if (addYears.includes(featureName)) featureValue = featureValue + " Years";

  if (featureName === "max_cover_amt") {
    if (parseInt(featureValue) === 4000000000) {
      featureValue = "No Limit, Subject to Underwriting";
    } else {
      featureValue = getAnnualIncome(featureValue);
    }
  }
  return <FeatureValueWrap {...props}>{featureValue}</FeatureValueWrap>;
}

const FeatureValueWrap = styled.div`
  text-align: center;
  border-left: 1px solid #ececec;
  height: 100%;
  color: ${props => props.different && "red"};
  ${mq.sm} {
    font-family: "Inter";
    font-weight: 500;
  }
`;

const StyledBooleanIcon = styled.img`
  width: 10px;
  height: 10px;
  ${
    "" /* ${mq.sm} {
    width: 12%;
    height: auto;
  } */
  }
`;

const Icon = styled.span`
  font-size: 1.8em;
  color: #2cd44a;
  font-weight: 800;
`;
const CrossIcon = styled.span`
  font-size: 1.8em;
  color: #ff4133;
  font-weight: 800;
`;
