import { useState } from "react";
import {
  BiRightArrowStyled,
  DescriptionWrapperStyled,
  DynamicWrapperStyled,
  InputPageMessageContainerStyled,
  InputPageMessageWrapperStyled,
  ListItemWrapperStyled,
} from "../styles/inputForms.styled";
import { useInputPageHook } from "../hooks";
import ListItem from "./ListItem";
import { FaInfoCircle } from "react-icons/fa";

const InputPageMessage = () => {
  const { termInsuranceDescription = "", parsedTermInsuranceDescription = "" } =
    useInputPageHook();
  const [show, setShow] = useState(false);
  return (
    <>
      <InputPageMessageContainerStyled>
        <InputPageMessageWrapperStyled>
          <h3>Term Insurance</h3>
          {termInsuranceDescription ? (
            <>
              <DescriptionWrapperStyled>
                <BiRightArrowStyled
                  show={show}
                  onClick={() => setShow(show => !show)}
                />
                <DynamicWrapperStyled show={show}>
                  {parsedTermInsuranceDescription}
                </DynamicWrapperStyled>
              </DescriptionWrapperStyled>
            </>
          ) : (
            <>
              <h1>
                Buy Term Insurance plan in few simple steps
                <FaInfoCircle className="down" onClick={() => setShow(!show)} />
              </h1>
              <ListItemWrapperStyled show={show}>
                <ListItem>Compare Term Insurance Plans</ListItem>
                <ListItem>Instant Policy Issuance</ListItem>
                <ListItem>Free Claims Assistance</ListItem>
                <ListItem>Get Plan Recommendation in Seconds</ListItem>
              </ListItemWrapperStyled>
            </>
          )}
        </InputPageMessageWrapperStyled>
      </InputPageMessageContainerStyled>
    </>
  );
};

export default InputPageMessage;
