import { createSlice } from "@reduxjs/toolkit";
import { setData } from "../../Landing/input.slice";
import { riderApi } from "../../riders/services/rider";
import {
  clearAddedRiders,
  clearQuotes,
  replaceQuotes,
  replaceRidersCollection,
} from "../Quotes/quotes.slice";
import { clearQuotes as clearCompareQuotes } from "../components/comparePlanPopup.slice";
import { getTermCoverUpto, getTermSumAssured } from "./helper";
import { patchEnquiries } from "./serviceApi";

const filter = createSlice({
  name: "filters",
  initialState: {
    preferredInsurer: "Select Insurer",
    payTill: "65 years",
    increasingIncome: false,
    coverUpto: "65 years",
    nomineeGets: "Lumpsum",
    coverAmount: "25 lakhs",
    monthlyAmount: "30 thousand",
    totalAmount: "50 lakhs",
    payFrequency: "0",
    payFrequencyOptions: [],
    isFilterUpdating: false,
    filters: {
      term_sum_assured: "",
      default_sum_assured: "",
      term_pay_frequency: 0,
      term_paytype_option: "",
      term_policy_term: 0,
      term_limited_pay_term: "",
      term_covertype: "",
      term_payout_option: "",
      term_plan_type: "",
      term_coverupto: 0,
      term_pay_till: 0,
      selected_insurers: [],
    },
    defaultFilters: {},
    filterOptions: {
      term_sum_assured: [],
      max_cover_amount: 0,
      term_pay_frequency: [],
      term_coverupto: [],
      term_pay_till: [],
      term_covertype: [],
      term_plan_type: [],
      term_payout_option: [],
      monthly_option: {
        term: [],
        increasing: [],
      },
      lumpsum_monthly_option: {
        percent: [],
        term: [],
        increasing: [],
      },
    },
    LMI: [],
    MI: [],
  },
  reducers: {
    setTermSumAssuredOptions: (state, { payload }) => {
      state.filterOptions.term_sum_assured = payload;
    },
    setMaxCoverAmount: (state, { payload }) => {
      state.filterOptions.max_cover_amount = payload;
    },
    setTermPayFrequencyOptions: (state, { payload }) => {
      state.filterOptions.term_pay_frequency = payload;
    },
    setTermCoverUptoOptions: (state, { payload }) => {
      state.filterOptions.term_coverupto = payload;
    },
    setTermPayoutOptions: (state, { payload }) => {
      state.filterOptions.term_payout_option = payload;
    },
    setTermPlanType: (state, { payload }) => {
      state.filters.term_plan_type = payload;
    },
    setTermPlanTypeOptions: (state, { payload }) => {
      state.filterOptions.term_plan_type = payload;
    },
    setTermPayTillOptions: (state, { payload }) => {
      state.filterOptions.term_pay_till = payload;
    },
    setTermCoverTypeOptions: (state, { payload }) => {
      state.filterOptions.term_covertype = payload;
    },
    setNomineeGets: (state, { payload }) => {
      state.nomineeGets = payload;
    },
    setMonthlyIncomeOptions: (state, { payload }) => {
      state.filterOptions.monthly_option.term = payload.term;
      state.filterOptions.monthly_option.increasing = payload.increasing;
    },
    setLumpsumMonthlyOptions: (state, { payload }) => {
      state.filterOptions.lumpsum_monthly_option.term = payload.term;
      state.filterOptions.lumpsum_monthly_option.increasing =
        payload.increasing;
      state.filterOptions.lumpsum_monthly_option.percent = payload.percent;
    },
    setIncreasingIncome: (state, { payload }) => {
      state.increasingIncome = payload;
    },
    setPayFrequencyOptions: (state, { payload }) => {
      state.payFrequencyOptions = payload;
    },
    setPayFrequency: (state, { payload }) => {
      state.payFrequency = payload;
      state.filters.term_pay_frequency = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
    },

    setDefaultFilters: (state, { payload }) => {
      state.defaultFilters = payload;
    },
    initFilter: (state, { payload }) => {
      state.isFilterUpdating = true;
    },
    clearFilterLoading: state => {
      state.isFilterUpdating = false;
    },
    setSelectedInsurer: (state, { payload }) => {
      state.filters.selected_insurers = payload;
    },
  },
});
export const {
  setNomineeGets,
  setPayFrequency,
  setIncreasingIncome,
  setPayFrequencyOptions,
  setFilters,
  setDefaultFilters,
  setTermCoverUptoOptions,
  setTermPayFrequencyOptions,
  setTermPayTillOptions,
  setTermSumAssuredOptions,
  setMaxCoverAmount,
  setTermPlanType,
  setTermCoverTypeOptions,
  setTermPlanTypeOptions,
  setSelectedInsurer,
  setTermPayoutOptions,
  setMonthlyIncomeOptions,
  setLumpsumMonthlyOptions,
  clearFilterLoading,
  initFilter,
} = filter.actions;
const generateRange = (min, max, per) => {
  let range = [];
  for (let i = parseInt(min); i <= parseInt(max); i += parseInt(per)) {
    range.push(i);
  }
  return range;
};
export const updateFilter = (filters, defaultFilters, nominee_gets_table) => {
  return dispatch => {
    try {
      if (nominee_gets_table) {
        const { MI, LMI } = nominee_gets_table;
        let MI_term = [];
        let MI_increasing = ["No"];
        let LMI_term = [];
        let LMI_per = [];
        let LMI_increasing = ["No"];
        MI.forEach(item => {
          if (!MI_term.includes(item.income_period))
            MI_term.push(item.income_period);

          if (item.increasing_per !== "NA" && !MI_increasing.includes("Yes"))
            MI_increasing.unshift("Yes");
        });
        dispatch(
          setMonthlyIncomeOptions({ term: MI_term, increasing: MI_increasing }),
        );
        LMI.forEach(item => {
          if (!LMI_term.includes(item.income_period))
            LMI_term.push(item.income_period);

          if (item.increasing_per !== "NA" && !LMI_increasing.includes("Yes"))
            LMI_increasing.unshift("Yes");
          const { min_lumpsum_per, max_lumpsum_per, per_interval } = item;
          LMI_per.push(
            ...generateRange(min_lumpsum_per, max_lumpsum_per, per_interval),
          );
        });
        LMI_per = LMI_per.filter(function (item, pos, self) {
          return self.indexOf(item) === pos;
        });
        dispatch(
          setLumpsumMonthlyOptions({
            term: LMI_term,
            percent: LMI_per.sort((a, b) => a - b),
            increasing: LMI_increasing,
          }),
        );
      }
      if (defaultFilters instanceof Array) {
        defaultFilters.forEach(item => {
          if (item.code === "term_sum_assured") {
            dispatch(
              setTermSumAssuredOptions(
                getTermSumAssured(
                  item.minimum_sum_assured,
                  item.maximum_sum_assured,
                ),
              ),
            );
          }

          dispatch(
            setTermCoverUptoOptions(
              getTermCoverUpto(filters.term_insured_age + 5, 100),
            ),
          );
          if (item.code === "term_covertype") {
            dispatch(setTermCoverTypeOptions(item.options));
          }

          if (item.code === "term_pay_frequency") {
            dispatch(setTermPayFrequencyOptions(item.options));
          }
          if (item.code === "term_limited_pay_term") {
            dispatch(setTermPayTillOptions(item.options));
          }
          if (item.code === "term_plan_type") {
            dispatch(setTermPlanTypeOptions(item.options));
          }
          if (item.code === "term_payout_option") {
            dispatch(setTermPayoutOptions(item.options));
          }
        });
      }
      dispatch(setFilters(filters));
    } catch (err) {
      console.error(err);
    }
  };
};
export const changeFilter = (changedFilters, noRefetch) => {
  return async dispatch => {
    dispatch(clearCompareQuotes());
    try {
      dispatch(initFilter());
      const { data } = await patchEnquiries({
        input: { ...changedFilters },
      });
      dispatch(riderApi.util.invalidateTags(["Enquiry", "Compare"]));
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        age_band_name,
        term_annual_income,
        ...filters
      } = data?.data?.input;
      dispatch(
        updateFilter(
          filters,
          data?.termdefaultfilters,
          data?.nominee_gets_table,
        ),
      );
      dispatch(setData({ age_band_name }));
      if (!noRefetch) {
        dispatch(replaceQuotes({}));
        dispatch(clearQuotes());
        dispatch(replaceRidersCollection({}));
        dispatch(clearAddedRiders());
        dispatch(
          riderApi.util.invalidateTags(["Quote", "Rider", "DifferentQuote"]),
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
};
export default filter.reducer;

export const selectFilters = state => state.filters;
export const selectTermFilters = state => state.filters.filters;
export const selectDefaultFilters = state => state.filters.defaultFilters;
export const selectFilterOptions = state => state.filters.filterOptions;
export const selectSelectedInsurersFilter = state =>
  state.filters.filters.selected_insurers;
