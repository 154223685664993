import { useInputPageHook } from "./hooks";
import {
  InputPageContainerStyled,
  InputPageWrapperStyled,
} from "./styles/inputPage.styled";
import InputPageForm from "./forms";
import { InputPageMessage } from "./components";
import Footer from "../../components/Footer";

const InputPage = () => {
  const { inputPageBackgroundImage = "" } = useInputPageHook();
  return (
    <>
      <InputPageContainerStyled bg={inputPageBackgroundImage}>
        <InputPageWrapperStyled>
          <InputPageMessage />
          <InputPageForm />
        </InputPageWrapperStyled>
      </InputPageContainerStyled>
      <Footer />
    </>
  );
};

export default InputPage;
