import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";

export const HeaderContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderLinksWrapperStyled = styled.div`
  margin-right: 5px;
  ${mq.sm} {
    margin-right: 0;
  }
  & ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    ${mq.sm} {
      padding: 0;
    }
    & li {
      font-size: 12px;
      color: #555;
      border-radius: none !important;
      padding: 0px 5px;
      &:last-child {
        cursor: pointer;
      }
    }
    & li:not(:last-child) {
      border-right: 2px solid #ccc;
    }
    ${mq.sm} {
      & li:last-child {
        display: inline;
      }
    }
  }
`;
export const HeaderButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & button {
    min-width: 136px;
    font-weight: bold;
    border-radius: 6px;
    font-size: 14px;
  }
`;
export const avatarStyles = {
  height: "30px",
  width: "30px",
  background: "var(--primary-color)",
  fontSize: "1rem",
  cursor: "pointer",
};
