import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdMailOutline, MdOutlinePhone } from "react-icons/md";
import { SiWhatsapp } from "react-icons/si";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "styled-components/macro";
import * as yup from "yup";
import { useNumberInput } from "../../../../utils";
import {
  allowOnlyNumbers,
  restrictSpace,
} from "../../../ProposalPage/inputUtils/onInput";
import { ShareViaForm } from "../../../product-page/components/ShareQuotePopup";
import { useShareQuoteMutation } from "../../../riders/services/rider";
import TextInput from "../../EligibilityCard/components/TextInput/TextInput";
import { patchEnquiries } from "../../Filters/serviceApi";
import { selectAddedRiders } from "../../Quotes/quotes.slice";
const ShareShortlistedQuotePopup = () => {
  const [imageUrl, setImageUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [showMore, setShowMore] = useState(false);
  const { data: userData } = useSelector(state => state.inputForm);
  const { quotesList } = useSelector(state => state?.newCompare);

  const productData = quotesList.map(quote => {
    const {
      term_insurance_id,
      product,
      companyAlias,
      termPayFrequency,
      termCoverupto,
      termPayTill,
      termSumAssured,
      premium_amount,
    } = quote;
    return {
      product_id: product?.code,
      company_alias: companyAlias?.code,
      term_insurance_id,
      term_pay_frequency: termPayFrequency?.code,
      term_coverupto: termCoverupto?.code,
      term_pay_till: termPayTill?.code,
      term_sum_assured: termSumAssured?.code,
      premium_amount,
    };
  });

  getQuotesPageImage().then(data => {
    if (data) {
      setImageUrl(data);
    }
  });
  const { tenant } = useSelector(state => state.frontendBoot?.frontendData);
  return (
    <div>
      {!showMore && (
        <div
          css={`
            animation-duration: 0.9s;
            animation-name: fadein;
            backgroud: red;

            @keyframes fadein {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          `}
        >
          <ShareShortlistedQuotesOnMailForm
            imageUrl={imageUrl}
            productData={productData}
          />
          {tenant?.name !== "TMIBASL" && (
            <ShareShortlistOnWhatsappForm mobile={userData.mobile} />
          )}

          <ShareShortListedQuotesViaText />
          {/* <MoreOptionsButton onClick={ViewMore}>
            {"View More Options"}
          </MoreOptionsButton> */}
        </div>
      )}
      {showMore && (
        <div
          css={`
            animation-duration: 0.9s;
            animation-name: fadein;

            @keyframes fadein {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          `}
        >
          <ShareShortlistedQuotesOnMailForm
            imageUrl={imageUrl}
            productData={productData}
          />
          <ShareShortlistOnWhatsappForm mobile={userData.mobile} />
          <ShareShortListedQuotesViaText />
          {/* <MoreOptionsButton onClick={HideQr}>{"Hide"}</MoreOptionsButton>
          <QR />
          <CopyToClipboardForm /> */}
        </div>
      )}
    </div>
  );
};

export default ShareShortlistedQuotePopup;

const schema = yup.object({
  email: yup.string().email().required("Please enter email"),
});

const mobileSchema = yup.object({
  mobile: yup
    .number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),
});

function ShareShortlistedQuotesOnMailForm({ imageUrl, productData }) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);
  const { shortListedQuote } = useSelector(state => state.quotes);
  const [email, setEmail] = useState(data.email);
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");

  // const { showDifference, selectedFeatures } = useSelector(
  //   state => state.compare,
  // );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { email: data.email || "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async formData => {
    isNewCompare
      ? shareQuotes({
          purpose: "quote",
          email: formData.email,
          via: "email",
          product_data: productData,
          stage: "QUOTE",
          image_to_send: imageUrl,
        }).then(res => {
          if (res.error) {
            toast.error("Sharing Failed!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            toast.success("Email Shared Successfully!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        })
      : shareQuotes({
          purpose: "shortlist",
          email: formData.email,
          via: "email",
          product_ids: shortListedQuote,
          image_to_send: imageUrl,
          stage: "SHORTLISTED_QUOTES",
        }).then(res => {
          if (res.error) {
            toast.error("Sharing Failed!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            toast.success("Shortlisted Quotes Shared Successfully!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        });

    // await toast.success(
    //   isNewCompare
    //     ? "Quotes Shared Successfully!"
    //     : "Shortlisted Quotes Shared Successfully!",
    //   {
    //     position: "bottom-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   },
    // );
  };

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.email && errors.email.message}
        {...register("email")}
        value={email}
        onChange={evt => {
          register("email").onChange(evt);
          setEmail(evt.target.value);
          setValue("email", evt.target.value);
        }}
        label="Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdMailOutline className="fs-6" />
            </InputAdornment>
          ),
        }}
        onInput={restrictSpace}
      />
      {/* <ShareFormInput {...register("email")} /> */}
    </ShareViaForm>
  );
}
function ShareShortListedQuotesViaText() {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);
  const { shortListedQuote } = useSelector(state => state.quotes);
  const [mobile, setMobile] = useState(data.mobile);

  // const { showDifference, selectedFeatures } = useSelector(
  //   state => state.compare,
  // );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: data.mobile },
    resolver: yupResolver(mobileSchema),
  });

  const onSubmit = async formData => {
    shareQuotes({
      purpose: "shortlist",
      mobile: formData.mobile,
      via: "sms",
      product_ids: shortListedQuote,
      stage: "SHORTLISTED_QUOTES",
    });

    await toast.success("Shortlisted Quotes Shared Successfully!", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.mobile && errors.mobile.message}
        {...register("mobile")}
        value={mobile}
        onChange={evt => {
          register("mobile").onChange(evt);
          setMobile(evt.target.value);
          setValue("mobile", evt.target.value);
        }}
        onInput={allowOnlyNumbers}
        label="Mobile"
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdOutlinePhone className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput {...register("email")} /> */}
    </ShareViaForm>
  );
}
function ShareShortlistOnWhatsappForm({ mobile, imageUrl }) {
  const [phone, handleChange] = useNumberInput(mobile);
  const addedRiders = useSelector(selectAddedRiders);
  const { shortListedQuote } = useSelector(state => state.quotes);
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "shortlist",
        groupCode: "10003511",
        mode: ["WHATSAPP"],
        image_to_send: imageUrl,
        via: "whatsapp",
        product_ids: shortListedQuote,
        stage: "SHORTLISTED_QUOTES",
        whatsapp: phone,
      }).then(res => {
        if (res.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Email Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });

      // form.setAttribute("action", "https://api.whatsapp.com/send");
      // form.setAttribute("target", "_blank");
      // form.setAttribute("method", "GET");

      // const phoneInput = document.createElement("input");
      // phoneInput.setAttribute("value", "91" + phone);
      // phoneInput.setAttribute("name", "phone");

      // const textInput = document.createElement("input");
      // textInput.setAttribute("value", window.location);
      // textInput.setAttribute("name", "text");

      // form.appendChild(phoneInput);
      // form.appendChild(textInput);

      // document.body.appendChild(form);
      // form.submit();
      // document.body.removeChild(form);
    } else setError("Please enter a valid whatsapp no.");
  };
  // const handleSubmit = async evt => {
  //   evt.preventDefault();
  //   const { data } = await patchEnquiries({
  //     input: {
  //       selected_addons: addedRiders,
  //     },
  //   });
  //   if (phone.length === 10) {
  //     setError(false);
  //     const form = document.createElement("form");
  //     form.setAttribute("action", "https://api.whatsapp.com/send");
  //     form.setAttribute("target", "_blank");
  //     form.setAttribute("method", "GET");

  //     const phoneInput = document.createElement("input");
  //     phoneInput.setAttribute("value", "91" + phone);
  //     phoneInput.setAttribute("name", "phone");

  //     const textInput = document.createElement("input");
  //     textInput.setAttribute("value", window.location);
  //     textInput.setAttribute("name", "text");

  //     form.appendChild(phoneInput);
  //     form.appendChild(textInput);

  //     document.body.appendChild(form);
  //     form.submit();
  //     document.body.removeChild(form);
  //   } else setError("Please enter a valid whatsapp no.");
  // };

  return (
    <ShareViaForm
      // icon={<SiWhatsapp />}
      onSubmit={handleSubmit}
      // errorMessage={error}
      isLoading={isLoading}
    >
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput
        type="tel"
        maxLength={10}
        value={phone}
        onChange={handleChange}
        placeholder="Enter your Whatsapp Number"
      /> */}
    </ShareViaForm>
  );
}

async function getQuotesPageImage() {
  const root = document.getElementById("quotes_section");
  const canvas = await html2canvas(root);
  const image = canvas.toDataURL("image/png");

  return image.split(",")[1];
}
