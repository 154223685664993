import { TextField, styled } from "@mui/material";

const TextInput = ({
  label,
  name,
  value,
  error,
  capitalize,
  defaultValue,
  ...props
}) => {
  return (
    <>
      <CustomField
        label={label}
        variant={"outlined"}
        value={value || ""}
        fullWidth
        defaultValue={defaultValue || ""}
        capitalize={capitalize}
        error={Boolean(error)}
        helperText={error}
        {...props}
      />
    </>
  );
};

export default TextInput;
const CustomField = styled(TextField)({
  height: "50px !important",
  "& .MuiOutlinedInput-input": {
    padding: "0px 14px",
    backgroundColor: "rgb(255, 255, 255)",
    textTransform: prop => prop.capitalize && "capitalize",
    height: "50px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: "var(--primary-color)",
      borderColor: "#e4e4e4",
    },
    "&:hover fieldset": {
      // borderColor: "var(--primary-color)",
      borderColor: "#e4e4e4",
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
  },

  "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
    {
      transform: "translate(14px, -13px) scale(0.75)",
      color: "var(--primary-color)",
    },
  "& label.Mui-error": {
    color: "rgba(0, 0, 0, 0.6)",
  },

  "& .css-p0rm37": {
    transform: "translate(14px, 8px) scale(1)",
  },

  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    top: "-2px !important",
  },
  "& .MuiInputLabel-root": {
    top: "5px",
  },
});

// "& .MuiOutlinedInput-root": {
//     borderRadius: "4px",
//     height: "50px",
//   "&.Mui-focused fieldset": {
//     borderColor: "var(--primary-color)",
//   },

//   "&.Mui-focused.Mui-error fieldset": {
//     borderColor: "#d32f2f",
//   },
// },
