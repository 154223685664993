import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import DownloadSection from "../DownloadSection/DownloadSection";
import * as mq from "../../../../styles/mediaQueries";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import PlanDetailsMobileUI from "./PlanDetailsMobileUI";
import {
  DetailDescription,
  DetailSection,
  DetailsWrapper,
  DetailTitle,
  ValueSection,
  ValueTitle,
  ValueWrapper,
} from "./PlanDetails.style";
import { Container } from "@mui/material";

const PlanDetails = () => {
  const matches = useMediaQuery(`${mq.smForHook}`);
  const { planDetails } = useSelector(state => state.quotes.seeDetailsData);
  const [currentDetail, setCurrentDetail] = useState(0);
  return (
    <Container
      css={`
        padding-top: 10px;
        padding-bottom: 40px;
        ${mq.sm} {
          padding-top: 15px;
          padding-left: 0.8rem;
          margin: auto;
        }
      `}
    >
      {!matches ? (
        <Row>
          <Col md={4} lg={5}>
            <DetailsWrapper>
              {planDetails.map((item, idx) => (
                <DetailSection
                  key={item.feature_key}
                  active={idx === currentDetail}
                  onClick={() => {
                    setCurrentDetail(idx);
                  }}
                >
                  <DetailTitle>{item.feature_key}</DetailTitle>
                  <DetailDescription>
                    {item.feature_description ||
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in molestie lorem. Nunc sed dui tempor."}
                  </DetailDescription>
                </DetailSection>
              ))}
              <></>
            </DetailsWrapper>
          </Col>
          <Col md={8} lg={7}>
            <div
              style={{
                height: "18px",
                width: "auto",
                backgroundColor: " var(--tertiary-shade)",
              }}
            ></div>
            <ValueWrapper>
              {planDetails[currentDetail]?.feature_values.map(item => (
                <ValueSection>
                  <ValueTitle>
                    {item.feature_key_title}
                    {"  "}:{"  "}
                    {item.feature_key_value}
                  </ValueTitle>
                  <DetailDescription>
                    {item.feature_key_description}
                  </DetailDescription>
                </ValueSection>
              ))}
            </ValueWrapper>
            <div
              style={{
                height: "18px",
                width: "auto",
                backgroundColor: " var(--tertiary-shade)",
              }}
            ></div>
          </Col>
        </Row>
      ) : (
        <PlanDetailsMobileUI />
      )}

      <DownloadSection />
    </Container>
  );
};

export default PlanDetails;
