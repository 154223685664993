import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useGetSellerInfoQuery } from "../../../pages/riders/services/rider";
import * as mq from "../../../styles/mediaQueries";

export const useHeaderData = () => {
  const { data } = useSelector(state => state.inputForm);
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");
  const isMobile = useMediaQuery(`${mq.smForHook}`);
  const isMobileOrTablet = useMediaQuery(`${mq.mdForHook}`);
  const history = useHistory();

  const token = useUrlQuery("token");
  const { data: sellerInfo } = useGetSellerInfoQuery(
    { token: token },
    { skip: !token },
  );
  useEffect(() => {
    if (
      data?.payment_status === "success" &&
      ["/quote", "/riders", "/compare"].includes(location.pathname)
    ) {
      history.push({
        pathname: "/thankyou",
        search: window.location.search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { seller_id, seller_type, seller_name } =
    useSelector(
      state => state?.inputForm?.data?.params?.other_req_data?.data,
    ) || {};
  const sellerData = sellerInfo || { seller_id, seller_type, seller_name };

  return {
    location,
    data,
    isMobileOrTablet,
    isMobile,
    isNewCompare,
    sellerData,
  };
};
