import { createSlice } from "@reduxjs/toolkit";

const cart = createSlice({
  name: "cart",
  initialState: {
    cartResponse: {},
    riders: [],
    biLoading: false,
    redirectionLoading: false,
  },
  reducers: {
    saveCartResponse: (state, action) => {
      state.cartResponse = action.payload;
    },
    setRiders: (state, action) => {
      state.riders = action.payload;
    },
    initBiLoading: state => {
      state.biLoading = true;
    },
    clearBiLoading: state => {
      state.biLoading = false;
    },
    setRedirectionLoading: (state, action) => {
      state.redirectionLoading = action.payload;
    },
  },
});

export const {
  saveCartResponse,
  setRiders,
  initBiLoading,
  clearBiLoading,
  setRedirectionLoading,
} = cart.actions;

export default cart.reducer;

export const selectCartResponse = state => state.cart.cartResponse;
export const selectRiders = state => state.cart.riders;
