/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import useOutsideClickRef from "../../custom-hooks/useOutsideClickRef";
import { setData } from "../../pages/Landing/input.slice";
import { allowOnlyUniqueAlphabetsNoSpace } from "../../pages/ProposalPage/inputUtils/onInput";
import { modifyDetails } from "../../pages/input/validations";
import { Wrapper } from "../../pages/product-page/EligibilityCard/EligibilityCard.style";
import Select from "../../pages/product-page/EligibilityCard/components/Select/Select";
import TextInput from "../../pages/product-page/EligibilityCard/components/TextInput/TextInput";
import { changeFilter } from "../../pages/product-page/Filters/Filters.slice";
import * as mq from "../../styles/mediaQueries";
import { annualIncome, isDigits, toWords } from "../../utils";
import { getAge2 } from "./helper";
import DateComp from "../DatePicker/DatePicker";
import { FaTimes } from "react-icons/fa";

const EditDetailPanel = ({
  setShowEditDetailPanel,
  className,
  showEditDetailPanel,
}) => {
  const ref = useRef();
  useOutsideClickRef(ref, () => {
    setShowEditDetailPanel(false);
  });
  const { data } = useSelector(state => state.inputForm);
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(modifyDetails({ minAge: 18, maxAge: 65 })),
    defaultValues: {
      tobacco_habit: data.tobacco_habit,
    },
  });

  const {
    frontendData: {
      termeducation,
      termoccupation,
      termageband,
      termannualincome,
    },
  } = useSelector(state => state.frontendBoot);
  const { terminputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  const [age, setAge] = useState(
    moment().diff(moment(data.dob, "YYYY-MM-DD"), "years"),
  );

  useEffect(() => {
    reset({
      dob: data?.dob?.split("-").reverse().join("/"),
    });
  }, [reset, data]);

  const [ageBand, setAgeBand] = useState(
    termageband.filter(item => item.min <= age && item.max >= age),
  );
  useEffect(() => {
    setAgeBand(termageband.filter(item => item.min <= age && item.max >= age));
  }, [age]);
  useEffect(() => {
    setAge(moment().diff(moment(data.dob, "YYYY-MM-DD"), "years"));
  }, [data.dob]);

  const name = watch("name");
  const email = watch("email");
  const mobile = watch("mobile");
  const education = watch("education");
  const gender = watch("gender");
  const tobacco_habit = watch("tobacco_habit");
  const occupation = watch("occupation");
  const termAnnualIncome = watch("term_annual_income");
  const dateOfBirth = watch("dob");
  const riskProfile = watch("risk_profile");
  const lifeStage = watch("life_stage");
  const financialAndFamilyGoal = watch("financial_and_familyGoal");
  useEffect(() => {
    let min =
      termannualincome[occupation + "+" + education] && ageBand[0]
        ? termannualincome[occupation + "+" + education][ageBand[0].band_name]
        : 2.5;
    if (min * 100000 > termAnnualIncome) {
      setValue("term_annual_income", "");
    }
  }, [ageBand, education, occupation]);

  useEffect(() => {
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("mobile", data.mobile);
    setValue("gender", data.gender);
    setValue("tobacco_habit", data.tobacco_habit);
    setValue("education", data.education);
    setValue("occupation", data.occupation);
    setValue("term_annual_income", parseInt(data.term_annual_income));
    setValue("risk_profile", data?.suitability?.risk_profile);
    setValue("life_stage", data?.suitability?.life_stage);
    setValue(
      "financial_and_familyGoal",
      data?.suitability?.financial_and_familyGoal,
    );
  }, [data]);

  useEffect(() => {
    const element = document.querySelector(".app");
    if (showEditDetailPanel) {
      element.classList.add("fixed");
    } else {
      element.classList.remove("fixed");
    }
  }, [showEditDetailPanel]);

  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(
      setData({
        ...data,
        name: data.name.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()),
        dob: data.dob.split("/").reverse().join("-"),
        selected_product: {},
        suitability: {
          risk_profile: data.risk_profile,

          life_stage: data.life_stage,
          financial_and_familyGoal: data.financial_and_familyGoal,
        },
      }),
    );
    dispatch(
      changeFilter({
        ...data,
        name: data.name.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()),
        dob: data.dob.split("/").reverse().join("-"),
        selected_product: {},
        suitability: {
          risk_profile: data.risk_profile,

          life_stage: data.life_stage,
          financial_and_familyGoal: data.financial_and_familyGoal,
        },
      }),
    );
    setShowEditDetailPanel(false);
  };

  return (
    <BackWrapper className={`${className}`}>
      <EditDetailPanelWraper
        onSubmit={handleSubmit(onSubmit)}
        ref={ref}
        className={`position-fixed ${className}`}
      >
        {/* ======================== select Annual Income section ======================== */}
        <Heading>{isNewCompare ? "View Details" : "Edit Details"}</Heading>

        <CloseButtonWrapper
          onClick={() => {
            setValue("name", data.name);
            setValue("email", data.email);
            setValue("mobile", data.mobile);
            setValue("gender", data.gender);
            setValue("tobacco_habit", data.tobacco_habit);
            setValue("education", data.education);
            setValue("occupation", data.occupation);
            setValue("term_annual_income", parseInt(data.term_annual_income));
            setValue("risk_profile", data?.suitability?.risk_profile);
            setValue("life_stage", data?.suitability?.life_stage);
            setValue(
              "financial_and_familyGoal",
              data?.suitability?.financial_and_familyGoal,
            );
            setShowEditDetailPanel(false);
          }}
        >
          <FaTimes style={{ marginLeft: "4px" }} />
        </CloseButtonWrapper>
        <div
          css={`
            padding: 0 3rem;
            display: flex;
            flex-wrap: wrap;
            ${mq.sm} {
              padding: 0 0.5rem;
              margin-top: 80px;
            }
          `}
        >
          <Wrapper oneBy3>
            <TextInput
              label="Full Name*"
              capitalize={`true`}
              onInput={allowOnlyUniqueAlphabetsNoSpace}
              onChange={e => {
                setValue(
                  "name",
                  e.target.value.replace(/\b\w/g, char => char.toUpperCase()),
                );
              }}
              error={errors.name && errors?.name.message}
              value={name}
              inputProps={{
                minLength: 2,
                maxLength: 30,
                readOnly: isNewCompare,
              }}
            />
          </Wrapper>
          <Wrapper oneBy3>
            <TextInput
              label="Email ID*"
              size="large"
              onChange={e => {
                setValue("email", e.target.value);
                trigger("email");
              }}
              error={errors.email && errors.email.message}
              value={email}
              inputProps={{
                minLength: 2,
                maxLength: 50,
                inputMode: "email",
                readOnly: isNewCompare,
              }}
            />
          </Wrapper>
          <Wrapper oneBy3>
            <TextInput
              label="Mobile No.*"
              onChange={evt => {
                evt.target.value = evt.target.value.replace(/[^0-9]/g, "");
                setValue("mobile", evt.target.value);
                trigger("mobile");
              }}
              value={mobile}
              error={errors.mobile && errors.mobile.message}
              onInput={evt => {
                if (evt.target.value && !isDigits(evt.target.value)) return;
              }}
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                readOnly: isNewCompare,
              }}
            />
          </Wrapper>
          <Wrapper oneBy3>
            <DateComp
              {...register("dob")}
              label={"DOB (DD/MM/YYYY)"}
              age={[18, 70]}
              onChange={e => {
                setValue("dob", e.target.value);
                trigger("dob");
                setAge(getAge2(e.target.value));
              }}
              value={dateOfBirth}
              error={errors.dob && errors.dob.message}
              readOnly={isNewCompare}
            />
            {18 <= age && 65 >= age && !errors.dob
              ? age && <AgeWrapper>{age} years</AgeWrapper>
              : ""}
          </Wrapper>

          <Wrapper oneBy3>
            <Select
              label="Gender*"
              options={[
                { code: "M", display_name: "Male" },
                { code: "F", display_name: "Female" },
              ]}
              handleChange={val => {
                setValue("gender", val);
                trigger("gender");
              }}
              readOnly={isNewCompare}
              noSearch
              error={errors.gender && errors.gender.message}
              selectedValue={gender}
              autoComplete="new-password"
            />
          </Wrapper>
          <Wrapper oneBy3>
            <Select
              label="Tobacco Habit"
              options={[
                { code: "S", display_name: "Smoker" },
                { code: "NS", display_name: "Non Smoker" },
              ]}
              error={errors.tobacco_habit && errors.tobacco_habit.message}
              readOnly={isNewCompare}
              handleChange={val => {
                setValue("tobacco_habit", val);
                trigger("tobacco_habit");
              }}
              inputProps={{
                readOnly: isNewCompare,
              }}
              noSearch
              selectedValue={tobacco_habit}
              autoComplete="new-password"
            />
          </Wrapper>
          <Wrapper oneBy3>
            <Select
              annualIncome
              dropdownHeight={"35vh"}
              label="Annual Income*"
              readOnly={isNewCompare}
              options={annualIncome(
                termannualincome[occupation + "+" + education] && ageBand[0]
                  ? termannualincome[occupation + "+" + education][
                      ageBand[0].band_name
                    ]
                  : 2.5,
              ).map(item => ({
                code: item,
                display_name: toWords(item),
              }))}
              error={
                errors.term_annual_income && errors.term_annual_income.message
              }
              handleChange={val => {
                setValue("term_annual_income", val);
                trigger("term_annual_income");
              }}
              noSearch
              selectedValue={termAnnualIncome}
              autoComplete="new-password"
            />
          </Wrapper>
          <Wrapper oneBy3>
            <Select
              dropdownHeight={"25vh"}
              label="Occupation*"
              noSearch
              options={termoccupation}
              error={errors.occupation && errors.occupation.message}
              readOnly={isNewCompare}
              handleChange={val => {
                setValue("occupation", val);
                trigger("occupation");
              }}
              selectedValue={occupation}
              autoComplete="new-password"
            />
          </Wrapper>
          <Wrapper oneBy3>
            <Select
              dropdownHeight={"20vh"}
              label="Qualification"
              options={termeducation}
              error={errors.education && errors.education.message}
              readOnly={isNewCompare}
              handleChange={val => {
                setValue("education", val);

                trigger("education");
              }}
              noSearch
              selectedValue={education}
              autoComplete="new-password"
            />
          </Wrapper>
          {terminputpage?.termsuitabilityflagstatus === "Yes" && (
            <>
              <Wrapper oneBy3>
                <Select
                  name="risk_profile"
                  label="Risk Profile*"
                  readOnly={isNewCompare}
                  noSearch
                  options={[
                    {
                      code: "conservative",
                      display_name: "Conservative",
                    },
                    {
                      code: "risk-averse",
                      display_name: "Risk Averse",
                    },
                    {
                      code: "balance",
                      display_name: "Balance",
                    },
                    {
                      code: "growth",
                      display_name: "Growth",
                    },
                    {
                      code: "aggressive",
                      display_name: "Aggressive",
                    },
                  ]}
                  handleChange={e => {
                    setValue("risk_profile", e);
                    trigger("risk_profile");
                  }}
                  register={register}
                  selectedValue={riskProfile}
                />
              </Wrapper>
              <Wrapper oneBy3>
                <Select
                  name="life_stage"
                  label="Life Stage*"
                  readOnly={isNewCompare}
                  noSearch
                  options={[
                    {
                      code: "single",
                      display_name: "Single",
                    },
                    {
                      code: "married",
                      display_name: "Married",
                    },
                    {
                      code: "married-with-young-children",
                      display_name: "Married with Young Children",
                    },
                    {
                      code: "married-with-older-children",
                      display_name: "Married with Older Children",
                    },
                    {
                      code: "near-retirement",
                      display_name: "Near Retirement",
                    },
                    {
                      code: "retirement",
                      display_name: "Retirement",
                    },
                    {
                      code: "others",
                      display_name: "Others",
                    },
                  ]}
                  handleChange={e => {
                    setValue("life_stage", e);
                    trigger("life_stage");
                  }}
                  register={register}
                  selectedValue={lifeStage}
                />
              </Wrapper>
              <Wrapper oneBy3>
                <Select
                  name="financial_and_familyGoal"
                  label="Financial and Family Goal*"
                  readOnly={isNewCompare}
                  noSearch
                  options={[
                    {
                      code: "saving-and-investments",
                      display_name: "Saving and Investments",
                    },
                    {
                      code: "regular-income",
                      display_name: "Regular Income",
                    },
                    {
                      code: "retirement",
                      display_name: "Retirement",
                    },
                    {
                      code: "child",
                      display_name: "Child",
                    },
                    {
                      code: "protection",
                      display_name: "Protection",
                    },
                  ]}
                  handleChange={e => {
                    setValue("financial_and_familyGoal", e);
                    trigger("financial_and_familyGoal");
                  }}
                  register={register}
                  selectedValue={financialAndFamilyGoal}
                />
              </Wrapper>
            </>
          )}
        </div>
        <div
          css={`
            text-align: center;
            ${mq.sm} {
              background: #fff;
              border-top: solid 1px #d5ddea;
              width: inherit;
              position: fixed;
              border-radius: 0 0 3px 3px;
              top: 74.5vh;
              padding: 10px 0;
              z-index: 100;
            }
          `}
        >
          {!isNewCompare && (
            <SubmitButton className="btn btn-primary rounded-0">
              Confirm
            </SubmitButton>
          )}
        </div>
      </EditDetailPanelWraper>
    </BackWrapper>
  );
};

export default EditDetailPanel;

const SubmitButton = styled.button`
  text-align: center;
  margin: 3px 0 10px 0;
  ${mq.sm} {
    width: 30%;
    margin: 0;
    font-size: 1em;
    border-radius: none;
    padding: 10px !important;
  }
`;

const Heading = styled.div`
  text-align: center;
  border-bottom: solid 1px #d5ddea;
  background-color: #f1f3f6;
  font-family: Inter;
  font-weight: 700;
  padding: 20px 0;
  font-size: 20px;
  margin-bottom: 16px;

  ${mq.sm} {
    padding: 18px 60px;
    text-align: center;
    position: fixed;
    z-index: 100;

    border-radius: 3px 3px 0 0;

    width: inherit;
    font-size: 16px;
  }
`;
const BackWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
`;
const EditDetailPanelWraper = styled.form`
  width: 850px;
  top: 42.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 99;
  ${mq.sm} {
    width: 90%;
    height: 65vh;
    border-radius: 3px;
    top: 10vh;
    left: 5%;
    transform: none;
    // padding-bottom: 45px;
    overflow: auto;

    & ${Wrapper} {
      width: 100%;
      margin-bottom: 0.79em;
      ${mq.sm} {
        margin-bottom: 10px;
      }

      & label {
        font-size: 21px;
        ${mq.sm} {
          background-color: #fff;
          transform: translate(14px, -13px) scale(1) !important;
          font-size: 12px !important;
        }
      }

      & input,
      & select {
        padding-top: 37px;
        height: 50px !important;
        ${mq.sm} {
          padding-top: 0px;
          font-size: 14px !important;
          min-width: 30px;
        }
      }
      &,
      & select,
      & input {
        font-size: 20px;
      }
    }
  }
`;
const AgeWrapper = styled.div`
  position: absolute;
  padding: 4px 8px;
  right: 20px;
  bottom: 10px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
  color: var(--primary-color);

  ${mq.xs} {
    font-size: 12px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: -10px;
  top: -10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${mq.sm} {
    top: 13vh;
    position: fixed;
    right: 10vw;
    background: var(--tertiary-shade);
    box-shadow: none;
    font-size: 1.5em;
    border: none;
    z-index: 100;
  }
`;
