import { useInputPageHook } from "../../hooks";
import useEligibilityForm from "./useEligibilityForm";
import {
  InputFormButtonWrapperStyled,
  InputWrapperStyled,
  InputFormBackButtonStyled,
  InputFormContinueButtonStyled,
} from "../../styles/inputForms.styled";
import { InputFormsTitle, InputPageSelect } from "../../components";

const EligibilityForm = ({ setCurrent, current }) => {
  const {
    isEnquiry,
    termInputPage2 = "<h1>Tell us about your Professional Details</h1>",
  } = useInputPageHook();
  const {
    handleSubmit,
    onSubmit,
    errors,
    setValue,
    occupationOptions,
    occupation,
    educationOptions,
    education,
    termAnnualIncomeOptions,
    term_annual_income,
    viewQuotesHandler,
  } = useEligibilityForm(current, setCurrent);

  return (
    <InputFormsTitle
      title={termInputPage2}
      current={current}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputWrapperStyled>
        <InputPageSelect
          noSearch
          label="Occupation*"
          options={occupationOptions}
          error={errors.occupation && errors.occupation.message}
          handleChange={val => {
            setValue("occupation", val, "occupation");
          }}
          selectedValue={occupation}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageSelect
          label="Education*"
          options={educationOptions}
          error={errors.education && errors.education.message}
          handleChange={val => {
            setValue("education", val, "education");
          }}
          noSearch
          selectedValue={education}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageSelect
          noSearch
          label="Annual Income*"
          options={termAnnualIncomeOptions}
          error={errors.term_annual_income && errors.term_annual_income.message}
          handleChange={val => {
            setValue("term_annual_income", val, "term_annual_income");
          }}
          selectedValue={term_annual_income}
        />
      </InputWrapperStyled>
      <InputFormButtonWrapperStyled>
        <InputFormBackButtonStyled onClick={() => setCurrent(prev => prev - 1)}>
          Back
        </InputFormBackButtonStyled>
        {isEnquiry ? (
          <InputFormContinueButtonStyled onClick={viewQuotesHandler}>
            View Quotes
          </InputFormContinueButtonStyled>
        ) : (
          <InputFormContinueButtonStyled type="submit">
            Continue
          </InputFormContinueButtonStyled>
        )}
      </InputFormButtonWrapperStyled>
    </InputFormsTitle>
  );
};

export default EligibilityForm;
