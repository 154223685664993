import { Box, Stack, Typography, styled, useMediaQuery } from "@mui/material";
import magic from "../../../assets/images/magic-wand.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { TraceId } from "../../../components";
import { useHistory } from "react-router-dom";
import * as mq from "../../../styles/mediaQueries";
import { MdClear } from "react-icons/md";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";

function CompareHeader() {
  const history = useHistory();
  const matches = useMediaQuery(`${mq.smForHook}`);
  const enquiryId = useUrlQuery("enquiryId");
  return (
    <div
      className="container"
      // style={{ background: "rgba(227, 235, 241, 0.41)" }}
    >
      <BoxStyled>
        <Stack
          direction="row"
          spacing={0.5}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            history.replace(`/quote?enquiryId=${enquiryId}`);
          }}
        >
          <IoIosArrowRoundBack
            fontSize="1.5rem"
            style={{
              color: "#367CFF",
              background: "#F6F8FC",
              borderRadius: "50%",
              margin: "0",
            }}
          />
          <Typography variant="p">Go Back</Typography>
        </Stack>
      </BoxStyled>

      <HeaderBoxWrapper>
        {matches ? (
          <>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              width="100%"
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="row">
                  <img src={magic} alt="" height={30} width={30} />
                </Stack>
                <Stack direction={"column"}>
                  <Typography variant="body1" fontWeight={600}>
                    Create my plan
                  </Typography>
                  <TraceId label="CRN/Trace Id : " />
                </Stack>
              </Stack>
              <Stack>
                <MdClear
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </Stack>
            </Stack>
          </>
        ) : (
          <HeaderBox>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <img src={magic} alt="" height={30} width={30} />
              <Typography variant="h6" fontWeight={600}>
                Create Custom Comparison:
              </Typography>
            </Stack>
            <Stack>
              <TraceId label="CRN/Trace Id : " />
            </Stack>
          </HeaderBox>
        )}
      </HeaderBoxWrapper>
    </div>
  );
}

export default CompareHeader;

const BoxStyled = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  ${mq.sm} {
    margin-top: 0;
    display: none;
  }
`;

const HeaderBoxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mq.sm} {
    width: 100%;
    margin-top: 1rem;
  }
`;

const HeaderBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
