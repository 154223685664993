import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { updateEnquiries } from "../../Landing/input.slice";
import { riderApi } from "../../riders/services/rider";
import { createCartApi, getQuotes, getSeeDetails } from "./serviceApi";
const quote = createSlice({
  name: "quotes",
  initialState: {
    selectedQuote: {},
    sortBy: "Low To High",
    quotes: {},
    quotesLoading: {},
    differentQuotes: {},
    seeDetailsData: {
      lastFetched: "",
      data: false,
      additionalFeatures: [],
      claimProcess: [],
      aboutCompany: [],
      planDetails: [],
      otherDetails: {},
      documents: {},
    },
    showDifferentQuotes: false,
    isLoading: false,
    isDiabetic: false,
    ciVariant: undefined,
    addedRiders: {},
    ridersList: {},
    cartLoading: false,
    indiaFirstRiderVaildationError: false,
    selectedQuotesToShare: [],
    shortListedQuote: [],
    shortListedQuoteTemp: [],
    shortListedQuotesData: [],
    addedShortNames: { data: [], lastFetched: {} },
    shortNameToRemove: "",
  },
  reducers: {
    setSelectedQuote: (state, { payload }) => {
      state.selectedQuote = payload;
    },
    updateRiderListPremium: (state, { payload }) => {
      state?.ridersList[payload?.productId]?.forEach((item, index) => {
        if (item?.rider_id === payload?.rider_id) {
          state.ridersList[payload?.productId][index] = payload?.data;
        }
      });
      state?.addedRiders[payload?.productId]?.forEach((item, index) => {
        if (item?.rider_id === payload?.rider_id) {
          state.addedRiders[payload?.productId][index].total_premium =
            payload?.data?.net_premium;
        }
      });
    },
    setShowDifferentQuotes: (state, { payload }) => {
      state.showDifferentQuotes = payload;
    },
    setCiVariant: (state, { payload }) => {
      state.ciVariant = payload;
    },
    setSelectedQuotesToShare: (state, { payload }) => {
      state.selectedQuotesToShare = payload;
    },
    setShortListedQuote: (state, { payload }) => {
      state.shortListedQuote = [...state.shortListedQuote, ...payload];
    },
    removeShortListedQuote: (state, { payload }) => {
      state.shortListedQuote = state.shortListedQuote.filter(item => {
        return item !== payload;
      });
    },
    setShortListedQuoteTemp: (state, { payload }) => {
      state.shortListedQuoteTemp = [...state.shortListedQuoteTemp, ...payload];
    },
    removeShortListedQuoteTemp: (state, { payload }) => {
      state.shortListedQuoteTemp = state.shortListedQuoteTemp.filter(item => {
        return item?.product.id !== payload;
      });
    },
    setShortListedQuotesData: (state, { payload }) => {
      state.shortListedQuotesData = [
        ...state.shortListedQuotesData,
        ...payload,
      ];
    },
    clearShortListedQuotesData: state => {
      state.shortListedQuotesData = [];
      state.shortListedQuote = [];
      return state;
    },

    initCartLoading: state => {
      state.cartLoading = true;
    },
    clearCartLoading: state => {
      state.cartLoading = false;
    },
    removeShortListedQuotesData: (state, { payload }) => {
      state.shortListedQuotesData = state.shortListedQuotesData.filter(item => {
        return item?.product.id !== payload;
      });
    },
    setDiabeticTrue: state => {
      state.isDiabetic = true;
    },
    setDiabeticFalse: state => {
      state.isDiabetic = false;
    },

    setIndiaFirstRiderVaildationError: (state, { payload }) => {
      state.indiaFirstRiderVaildationError = payload;
    },
    saveQuotes: (state, { payload }) => {
      state.quotes = { ...state.quotes, ...payload };
    },
    setQuotesLoading: (state, { payload }) => {
      state.quotesLoading = { ...state.quotesLoading, ...payload };
    },
    saveDifferentQuotes: (state, { payload }) => {
      state.differentQuotes = { ...state.differentQuotes, ...payload };
    },
    updateRiderShortName: (state, { payload }) => {
      state.addedShortNames.data = payload.data;
      state.addedShortNames.lastFetched = payload.company_alias;
    },
    removeShortName: (state, { payload }) => {
      state.shortNameToRemove = payload;
      state.addedShortNames.data = state.addedShortNames.data.filter(
        item => item !== payload,
      );
    },
    replaceQuotes: (state, { payload }) => {
      state.showDifferentQuotes = false;
      state.quotes = payload;
      state.differentQuotes = payload;
    },
    updateRidersCollection: (state, { payload }) => {
      state.ridersList = { ...state.ridersList, ...payload };
    },
    replaceRidersCollection: (state, { payload }) => {
      state.ridersList = payload;
    },
    clearQuotes: state => {
      state.quotes = [];
    },
    setLoadingQuotes: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSeeDetails: (state, { payload }) => {
      const {
        lastFetched,
        aboutCompany,
        additionalFeatures,
        data,
        otherDetails,
        claimProcess,
        planDetails,
        documents,
      } = payload;
      state.seeDetailsData.lastFetched = lastFetched;
      state.seeDetailsData.aboutCompany = aboutCompany;
      state.seeDetailsData.additionalFeatures = additionalFeatures;
      state.seeDetailsData.data = data;
      state.seeDetailsData.otherDetails = otherDetails;
      state.seeDetailsData.claimProcess = claimProcess;
      state.seeDetailsData.planDetails = planDetails;
      state.seeDetailsData.documents = documents;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },

    insertAddedRiders: (state, { payload }) => {
      state.addedRiders = {
        ...state.addedRiders,
        [payload.productId]: [
          ...(state.addedRiders[payload.productId]
            ? state.addedRiders[payload.productId]
            : []),
          payload.rider,
        ],
      };
    },
    setAddedRiders: (state, { payload }) => {
      state.addedRiders = payload;
    },
    clearAddedRiders: state => {
      state.addedRiders = {};
      state.addedShortNames.data = [];
      state.addedShortNames.lastFetched = {};
    },
    removeAddedRiders: (state, { payload }) => {
      state.addedRiders = {
        ...state.addedRiders,
        [payload.productId]: [
          ...(state.addedRiders[payload.productId]
            ? state.addedRiders[payload.productId].filter(
                item => item.id !== payload.id,
              )
            : []),
        ],
      };
    },
    sortQuotes: state => {
      // if (state.sortBy === "Low To High") {
      //   state.quotes = state.quotes.sort((a, b) => a.premium - b.premium);
      // }
      // if (state.sortBy === "High To Low") {
      //   state.quotes = state.quotes.sort((a, b) => b.premium - a.premium);
      // }
      // if (state.sortBy === "Claim Ratio") {
      //   state.quotes = state.quotes.sort(
      //     (a, b) => b.product.company.csr - a.product.company.csr,
      //   );
      // }
    },
  },
});

export const {
  clearAddedRiders,
  setCiVariant,
  setShortListedQuote,
  setSelectedQuotesToShare,
  removeShortListedQuote,
  setShortListedQuotesData,
  initCartLoading,
  clearCartLoading,
  removeShortListedQuotesData,
  clearShortListedQuotesData,
  setShortListedQuoteTemp,
  removeShortListedQuoteTemp,
  saveQuotes,
  setQuotesLoading,
  setSeeDetails,
  clearQuotes,
  setLoadingQuotes,
  replaceQuotes,
  setSelectedQuote,
  setSortBy,
  setAddedRiders,
  updateRidersCollection,
  replaceRidersCollection,
  sortQuotes,
  updateRiderListPremium,
  removeShortName,
  saveDifferentQuotes,
  setShowDifferentQuotes,
  updateRiderShortName,
  insertAddedRiders,
  removeAddedRiders,
  sortProductMetrics,
  setDiabeticFalse,
  setDiabeticTrue,
  setIndiaFirstRiderVaildationError,
} = quote.actions;

export const fetchSeeDetailsData = (productId, onSuccess) => {
  return async dispatch => {
    try {
      const { data } = await getSeeDetails(productId);
      const notIncludeInPlanDetails = [
        "Additional Features",
        "Claim Process",
        "Know Your Insurer",
      ];
      const additionalFeatures = data?.know_more?.[0]?.find(
        item => item.feature_key === "Additional Features",
      ).feature_values;
      const claimProcess = data?.know_more[0].find(
        item => item.feature_key === "Claim Process",
      ).feature_values;
      const aboutCompany = data?.know_more[0].find(
        item => item.feature_key === "Know Your Insurer",
      ).feature_values;
      const planDetails = data?.know_more[0].filter(
        item => !notIncludeInPlanDetails.includes(item.feature_key),
      );
      const otherDetails = data?.know_more[1].values;
      dispatch(
        setSeeDetails({
          lastFetched: productId,
          data: data.know_more,
          additionalFeatures,
          claimProcess,
          aboutCompany,
          planDetails,
          otherDetails,
          documents: data?.document,
        }),
      );
      onSuccess();
    } catch (err) {
      console.error(err);
    }
  };
};

export const createCart = (quoteData, next) => {
  return async (dispatch, getState) => {
    try {
      const allRiders = getState().quotes.addedRiders;
      // const compareAddedRiders = getState().compare.ridersAdded;
      const {
        total_premium,
        tax_amount,
        premium,
        term_insurance_id,
        product,
        isPayTill60,
        limitedPptArray,
        max_cover_amount,
        max_cover_upto,
        min_policy_term,
        max_policy_term,
        min_payment_term,
        max_payment_term,
        term_coverupto,
        term_sum_assured,
      } = quoteData;

      const newData = {
        total_premium,
        tax_amount,
        term_coverupto,
        term_pay_till: quoteData.term_pay_till,
        term_sum_assured,
        premium,
        service_tax: tax_amount,
        term_insurance_id,
        product_id: product?.id,
        riders: quoteData.riders || allRiders[product?.id] || [],
        limitedPptArray,
        isPayTill60,
        max_cover_amount,
        max_cover_upto,
        min_policy_term,
        max_policy_term,
        min_payment_term,
        max_payment_term,
        differentQuote:
          quoteData?.differentQuote && quoteData.differentQuote === true
            ? true
            : false,
      };
      dispatch(initCartLoading());
      await createCartApi(newData);

      dispatch(riderApi.util.invalidateTags(["Cart"]));

      dispatch(
        updateEnquiries({
          input: {
            term_pay_till: quoteData?.term_pay_till,
            term_coverupto: quoteData?.term_coverupto,
            term_sum_assured: quoteData?.term_sum_assured,
          },
        }),
      );

      // dispatch(
      //   riderApi.endpoints.getCart.initiate({
      //     forceRefetch: true,
      //     subscribe: false,
      //   }),
      // );

      next();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clearCartLoading());
    }
  };
};
export const fetchQuotes = () => {
  return async (dispatch, getState) => {
    try {
      const cancelTokens = {};
      const {
        frontendData: { termcompanies },
      } = getState().frontendBoot;
      dispatch(setLoadingQuotes(true));
      Object.keys(cancelTokens).forEach(cancelToken => {
        cancelTokens[cancelToken].cancel("Cancelled due to new request made");
      });
      // let count = 0;
      const fetchQuote = async ({ alias, cancelToken }) => {
        try {
          const response = await getQuotes(
            {
              company_alias: alias,
            },
            {
              cancelToken,
            },
          );
          dispatch(saveQuotes(response?.data?.data));
          dispatch(sortQuotes());
          delete cancelTokens[alias];
          if (Object.keys(cancelTokens).length === 0) {
            dispatch(setLoadingQuotes(false));
          }
        } catch (error) {
          alert(error);
          console.error(error);
        }
      };

      dispatch(clearQuotes());

      Object.keys(termcompanies).forEach(alias => {
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokens[alias] = cancelTokenSource;
        fetchQuote({
          alias,
          cancelToken: cancelTokenSource.token,
        });
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export default quote.reducer;

export const selectSelectedProduct = state => state.quotes.selectedQuote;
export const selectQuotes = state => state.quotes.quotes;
export const selectDifferentQuotes = state => state.quotes.differentQuotes;
export const selectSortBy = state => state.quotes.sortBy;
export const selectAddedRiders = state => state.quotes.addedRiders;
export const selectAddedShortNames = state => state.quotes.addedShortNames;
export const selectSelectedQuotesToShare = state =>
  state.quotes.selectedQuotesToShare;
export const selectShortListedQuote = state => state.quotes.shortListedQuote;
export const selectShortListedQuoteTemp = state =>
  state.quotes.shortListedQuoteTemp;
