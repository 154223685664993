import "styled-components/macro";
import { Modal } from "react-bootstrap";
// import * as mq from "../../../styles/mediaQueries";
// import CancelCircularIcon from "../../../assets/svg-icons/CancelCircular";
const CardModal = ({
  show,
  handleClose,
  bodyContent,
  heading,
  footerContent,
  maxHeight,
  handleReplace,
  onClose,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="d-flex position-relative nominee__header">
        <Modal.Title className="p-lg">{heading}</Modal.Title>

        <div
          className="nominee__close"
          onClick={() => {
            handleClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={12}
            fill="currentColor"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
          </svg>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{
          fontSize: "14px",
        }}
        css={`
          max-height: ${maxHeight};
          overflow: ${maxHeight && "auto"};
        `}
      >
        {bodyContent}
      </Modal.Body>
    </Modal>
  );
};

export default CardModal;
