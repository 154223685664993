import whatsappIcon from "../../../assets/images/whatsapp-icon.svg";
import useInputPageHook from "../hooks/useInputPageHook";
import {
  WhatsappCheckboxStyled,
  WhatsappContainerStyled,
} from "../styles/inputForms.styled";

const Whatsapp = () => {
  const { checked, setChecked } = useInputPageHook();
  return (
    <>
      <WhatsappContainerStyled>
        <WhatsappCheckboxStyled
          size="small"
          defaultChecked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
          disableRipple
        />
        <h6>
          Get Updates on <img src={whatsappIcon} alt="Whatsapp Icon" /> Whatsapp
        </h6>
      </WhatsappContainerStyled>
    </>
  );
};

export default Whatsapp;
