import styled from "styled-components";
import * as md from "../../../styles/mediaQueries";
export const HeaderContainerStyled = styled.div`
  box-shadow: 0 3px 16px 0 rgba(197, 207, 224, 0.5);
`;

export const HeaderWrapperStyled = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${md.sm} {
    height: 45px;
    padding: 5px;
  }
`;
