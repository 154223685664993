import { HeaderData, HeaderLogo } from "./components";
import {
  HeaderContainerStyled,
  HeaderWrapperStyled,
} from "./styles/header.styled";

const Header = () => {
  return (
    <HeaderContainerStyled>
      <HeaderWrapperStyled className="container">
        <HeaderLogo />
        <HeaderData />
      </HeaderWrapperStyled>
    </HeaderContainerStyled>
  );
};

export default Header;
