export const getGender = gender => {
  switch (gender) {
    case "F":
      return "Female";
    case "M":
      return "Male";
    default:
      return "Male";
  }
};
