import { useGetCompareProductsQuery } from "../../riders/services/rider";
import styled from "styled-components/macro";
import { DownloadCompareButton } from "./DownloadCompareButton";
import { ProductCard, SelectProductCard } from "../ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { selectShowDifference, setShowDifference } from "../compare.slice";
import { Checkbox } from "@mui/material";
import { FullScreenLoader } from "../../../components";
import * as mq from "../../../styles/mediaQueries";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import { StyledBackButton } from "../header";

function ProductCards({ ...props }) {
  const matches = useMediaQuery(`${mq.smForHook}`);
  const { data, isLoading } = useGetCompareProductsQuery();
  if (isLoading) return <FullScreenLoader />;

  const { products } = data?.data || {};
  const renderDownload = products?.length > 0;
  return (
    <>
      <StyledProductCards {...props}>
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            margin: auto;
            ${mq.sm} {
              align-items: flex-start;
              margin-top: 10px;
              &:first-child {
                width: 100%;
              }
            }
          `}
        >
          {!matches ? (
            <StyledHeading>Product Comparison</StyledHeading>
          ) : (
            <Head />
          )}

          <ShowDifferenceCheckbox />
          {!matches && renderDownload && <DownloadCompareButton />}
        </div>

        {matches
          ? [0, 1].map(_i =>
              products[_i] ? (
                <ProductCard
                  product={products[_i]}
                  key={products[_i]?.product_id}
                />
              ) : (
                <SelectProductCard />
              ),
            )
          : [0, 1, 2].map(_i =>
              products[_i] ? (
                <ProductCard
                  product={products[_i]}
                  key={products[_i]?.product_id}
                />
              ) : (
                <SelectProductCard />
              ),
            )}
      </StyledProductCards>
    </>
  );
}

export { ProductCards };

function Head() {
  return (
    <>
      <HeadWrapper>
        <span css={``}>
          <StyledBackButton
            toPage="/quote"
            css={`
              ${mq.sm} {
                position: relative;
                top: 1px;
                left: 0.5vw;
                width: 25px;
                height: 20px;
                margin: 0 5px 0 10px;
              }
            `}
          />
        </span>
        <StyledHeading>Product Comparison</StyledHeading>
      </HeadWrapper>
    </>
  );
}

function ShowDifferenceCheckbox() {
  const dispatch = useDispatch();
  const showDifference = useSelector(selectShowDifference);

  const { data } = useGetCompareProductsQuery();
  const { products } = data.data;
  const renderShowDifferenceCheckbox = products.length > 1;
  const handleChange = () => {
    if (showDifference) {
      dispatch(setShowDifference(false));
      return;
    }
    dispatch(setShowDifference(true));
  };

  return (
    <div
      css={`
        font-family: "Inter";
        font-weight: 700;
        margin: 12px 0;
        display: flex;
        align-items: center;
        ${mq.sm} {
          font-family: "Inter";
          font-weight: 500;
          background: #eaeef2;
          width: 100%;
          padding: 3px 0;
          box-shadow: inset 0 0 10px #dce1e6;
        }
      `}
    >
      <div>
        <Checkbox
          size="small"
          title="Add to Compare"
          style={{ padding: "2px", margin: "0 5px 0 10px" }}
          disabled={!renderShowDifferenceCheckbox}
          onClick={event => handleChange(event)}
          checked={showDifference}
          id="compare-show-difference"
        />
      </div>
      <label htmlFor="compare-show-difference">Show Difference </label>
    </div>
  );
}

const HeadWrapper = styled.div`
  display: flex;
  width: 100% !important;
  flex: 1;
`;

const StyledHeading = styled.div`
  font-size: 20px;
  color: var(--secondary-text);
  font-family: "Inter";
  font-weight: 700;
  ${mq.sm} {
    font-size: 15px;
    padding-left: 4px;
  }
`;

const StyledProductCards = styled.main`
  display: flex;
  /* border-bottom: 2px solid #e9decd; */
  position: sticky;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 0 1.6em;
  top: 38px;
  z-index: 10;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px 0px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;

  ${mq.sm} {
    position: fixed;
    width: 100%;
    justify-content: center;
    top: 0;
    left: 0;
    padding: 0;
  }
  & > div {
    width: 25%;
    ${mq.sm} {
      width: 44%;
    }
  }
`;
