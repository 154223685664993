import { createSlice } from "@reduxjs/toolkit";
// import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { updateFilter } from "../product-page/Filters/Filters.slice";
import { patchEnquiries } from "../product-page/Filters/serviceApi";
import { setAddedRiders } from "../product-page/Quotes/quotes.slice";
import {
  contactDetails,
  enquiries,
  getEnquiries,
  getOtp,
  sendOtp,
} from "./serviceApi";

const input = createSlice({
  name: "input",
  initialState: {
    response: {},
    data: { whatsapp_flag: "Yes" },
    termDefaultFilters: [],
    loading: false,
    isEnquiryLoading: false,
    otpData: [],
    otpResponse: [],
  },
  reducers: {
    setResponse: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    },
    setData: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
        suitability: {
          ...(state?.data?.suitability || {}),
          ...(payload?.suitability || {}),
        },
      };
    },

    setTermDefaultFilters: (state, { payload }) => {
      state.termDefaultFilters = payload;
    },
    setOtpData: (state, { payload }) => {
      state.otpData = payload;
    },
    setOtpResponse: (state, { payload }) => {
      state.otpResponse = payload;
    },
    initLoader: state => {
      state.loading = true;
    },

    clearEnquiryLoading: state => {
      state.isEnquiryLoading = false;
    },
    initEnquiryLoading: state => {
      state.isEnquiryLoading = true;
    },
  },
});
export const {
  setData,
  setResponse,
  setTermDefaultFilters,
  initEnquiryLoading,
  clearEnquiryLoading,
  setOtpData,
  setOtpResponse,
} = input.actions;

export const saveData = onSuccess => {
  return async (dispatch, getState) => {
    try {
      const req = getState().inputForm.data;
      const {
        termsettings: { is_mobile_email_required },
      } = getState().frontendBoot.frontendData;
      dispatch(initEnquiryLoading());
      const { data } = await enquiries({
        ...req,
        section: "term",
        is_mobile_email_required,
      });

      dispatch(setResponse(data?.data));
      dispatch(clearEnquiryLoading());
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        age_band_name,
        term_annual_income,
        ...filters
      } = data?.data?.input;
      dispatch(
        updateFilter(
          filters,
          data?.termdefaultfilters,
          data?.nominee_gets_table,
        ),
      );
      dispatch(setData({ age_band_name }));
      onSuccess(data?.data?.enquiry_id);
    } catch (error) {
      dispatch(clearEnquiryLoading());
      console.error(error);
    }
  };
};
export const saveOtpData = ({ mobile, email, section }) => {
  return async dispatch => {
    try {
      const response = await getOtp({
        mobile,
        email,
        section,
      });
      dispatch(setOtpData(response?.data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const sendOtpData = ({ otp }) => {
  return async (dispatch, getState) => {
    try {
      const req = getState().inputForm.otpData;
      const uuid = req?.uuid;
      const response = await sendOtp({
        uuid,
        otp,
      });
      dispatch(setOtpResponse(response?.data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const getData = () => {
  return async dispatch => {
    try {
      const { data } = await getEnquiries();
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        age_band_name,
        term_annual_income,
        selected_addons,
        shortlistedIds,
        params,
        suitability,
        ...filters
      } = data?.data?.input;
      const newData = {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        age_band_name,
        term_annual_income,
        name: data?.data.name,
        email: data?.data.email,
        mobile: data?.data.mobile,
        params,
        payment_status: data?.data?.proposals[0]?.payment_status,
        links: data?.data?.links,
        shortlistedIds: [
          ...new Set(
            (shortlistedIds ? [...shortlistedIds] : [])?.map(item => item),
          ),
        ],
        suitability,
      };

      dispatch(setData(newData));
      dispatch(setTermDefaultFilters(data?.termdefaultfilters));
      dispatch(
        updateFilter(
          filters,
          data?.termdefaultfilters,
          data?.nominee_gets_table,
        ),
      );
      dispatch(setAddedRiders(selected_addons || {}));
    } catch (err) {
      console.error(err);
    }
  };
};

export const shareContactDetails = data => {
  return async dispatch => {
    try {
      dispatch(contactDetails(data));
    } catch (err) {
      //(err);
    }
  };
};

export const updateEnquiriesOnInput = (next = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const req = getState().inputForm.data;
      const {
        termsettings: { is_mobile_email_required },
      } = getState().frontendBoot.frontendData;
      dispatch(initEnquiryLoading());
      const { data } = await patchEnquiries({
        input: {
          ...req,
          section: "term",
          is_mobile_email_required,
        },
      });

      dispatch(setResponse(data?.data));
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        age_band_name,
        term_annual_income,
        ...filters
      } = data?.data?.input;
      dispatch(
        updateFilter(
          filters,
          data?.termdefaultfilters,
          data?.nominee_gets_table,
        ),
      );
      dispatch(setData({ age_band_name }));
      next();
      //onSuccess(data?.data?.enquiry_id);
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateEnquiries = (req, next = () => {}) => {
  return async dispatch => {
    try {
      dispatch(initEnquiryLoading());
      const { data } = await patchEnquiries(req);

      const newData = {
        name: data?.data.name,
        email: data?.data.email,
        mobile: data?.data.mobile,
        selected_product: data?.data.input?.selected_product,
      };
      dispatch(setData(newData));
      dispatch(setTermDefaultFilters(data?.termdefaultfilters));
      next();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clearEnquiryLoading());
    }
  };
};
export default input.reducer;
