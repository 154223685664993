import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { getAnnualIncome } from "../../../../components/navbar/helper";
import { useQuoteBuyNow } from "../../../../custom-hooks/useQuoteBuyNow";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import { amount } from "../../../../utils";
import EligibilityCard from "../../../../pages/product-page/EligibilityCard/EligibilityCard";
import { createCart } from "../../../../pages/product-page/Quotes/quotes.slice";
import "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";
import {
  riderApi,
  useGetRedirectionMutation,
} from "../../../../pages/riders/services/rider";
import { redirectToIc } from "../../../../pages/riders/helpers";
import { toast } from "react-toastify";

function Footer() {
  const [getRedirection] = useGetRedirectionMutation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters } = useSelector(state => state.filters);
  const matches = useMediaQuery(`${mq.smForHook}`);
  const { selectedQuote, addedRiders } = useSelector(state => state.quotes);
  const [product_name, plan_name, plan_sub_name] =
    selectedQuote?.product?.name?.split("-");
  let payment_frequency = "";
  filters.term_pay_frequency === 0
    ? (payment_frequency = "Yearly")
    : filters.term_pay_frequency === 1
    ? (payment_frequency = "Half Yearly")
    : filters.term_pay_frequency === 2
    ? (payment_frequency = "Quarterly")
    : filters.term_pay_frequency === 3 && (payment_frequency = "Monthly");
  const { otherDetails } = useSelector(state => state.quotes.seeDetailsData);
  const {
    frontendData: { termcompanies },
  } = useSelector(state => state.frontendBoot);
  const { redirectFrom } = useSelector(state => state.newCompare);
  const [showBuyNow, setShowBuyNow] = useState(false);

  const totalRidersPremium =
    addedRiders[selectedQuote?.product?.id] !== undefined
      ? addedRiders[selectedQuote?.product?.id].reduce(
          (sum, riderAdded) => sum + parseInt(riderAdded.total_premium),
          0,
        )
      : 0;

  const totalPremiumPayable =
    totalRidersPremium + parseInt(selectedQuote.total_premium);

  const { handleBuyNowClick } = useQuoteBuyNow({
    quoteData: selectedQuote,
    onBuyNowClick: () => setShowBuyNow(true),
  });

  const enquiryId = useUrlQuery("enquiryId");

  const handleAfterSubmit = () => {
    dispatch(
      createCart(
        {
          ...selectedQuote,
          addedRiders: addedRiders[selectedQuote?.product?.id],
        },
        () => {
          if (selectedQuote.is_rider_available !== "N") {
            history.push(`/riders?enquiryId=${enquiryId}`);
            selectedQuote?.differentQuote === true &&
              dispatch(riderApi.util.invalidateTags(["Quote"]));
          } else {
            if (selectedQuote.is_user_journey !== "N")
              getRedirection({
                company_alias: selectedQuote?.company_alias,
                product_id: selectedQuote?.product?.id,
                term_insurance_id: selectedQuote?.term_insurance_id,
              }).then(res => {
                if (res.data) {
                  const { data } = res.data;
                  if (
                    data[0] &&
                    ["R", "PF"].includes(data[0].journey_type) &&
                    data[0].redirection_url
                  ) {
                    redirectToIc(res.data.data[0]);
                    return;
                  } else {
                    dispatch(riderApi.util.invalidateTags(["Enquiry"]));
                    history.push(`/proposal?enquiryId=${enquiryId}`);
                  }
                } else {
                  toast.error(
                    res?.error?.data?.message
                      ? res?.error?.data?.message
                      : "Something went wrong",
                    {
                      hideProgressBar: true,
                      toastId: "failed1",
                      autoClose: 3000,
                      position: "bottom-center",
                      theme: "colored",
                    },
                  );
                }
              });
          }
        },
      ),
    );
  };

  return (
    <>
      {!matches ? (
        <KnowYourPlanFooterWrap>
          <img
            src={termcompanies[selectedQuote.company_alias]?.logo}
            alt="Company Logo"
          />
          <div
            css={`
              margin: 5px 6px;
              min-width: 10%;
              & p {
                margin-bottom: 6px;
              }
            `}
          >
            <p className="plan-name">{product_name}</p>

            <p className="plan-type">
              {plan_sub_name ? `${plan_name} - ${plan_sub_name}` : plan_name}
            </p>
          </div>

          <div className="all-figures">
            <section>
              <span className="figure-type">Life Cover</span>
              <br />
              <span className="figure-value">
                {redirectFrom === "NewCompare"
                  ? getAnnualIncome(selectedQuote?.term_sum_assured)
                  : getAnnualIncome(otherDetails.term_sum_assured)}
              </span>
            </section>
            <div className="values-separator"></div>
            <section>
              <span className="figure-type">Cover till age</span>
              <br />
              <span className="figure-value">
                {redirectFrom === "NewCompare"
                  ? selectedQuote?.term_coverupto
                  : otherDetails.term_coverupto}{" "}
                yrs
              </span>
            </section>
            <div className="values-separator"></div>
            <section>
              <span className="figure-type">Pay till age</span>
              <br />
              <span className="figure-value">
                {selectedQuote?.term_pay_till === 1
                  ? "Single Pay"
                  : selectedQuote?.term_pay_till + " yrs"}
              </span>
            </section>
            <div className="values-separator"></div>
            <section>
              <span className="figure-type">Claim Ratio</span>
              <br />
              <span className="figure-value">{otherDetails.csr} %</span>
            </section>
          </div>
          <div className="premium-amount-section">
            <span className="total-premium">Premium Amount</span>
            <br />
            <span className="premium-value">
              {amount(totalPremiumPayable)}{" "}
              {selectedQuote?.term_pay_till === 1
                ? "Pay Once"
                : redirectFrom === "NewCompare"
                ? selectedQuote?.frequency
                : payment_frequency}
            </span>
          </div>
          <button className="premium-buy-button" onClick={handleBuyNowClick}>
            <span className="proceed-to-buy-text">Proceed to Buy</span>
          </button>
        </KnowYourPlanFooterWrap>
      ) : (
        <KnowYourPlanMobileFooterWrap>
          <div
            css={`
              margin-left: 2em;
              margin-top: 0.5em;
            `}
          >
            <span
              css={`
                color: #7d7e80;
                font-weight: 600;
                font-size: 1rem;
              `}
            >
              Premium Amount
            </span>
            <br />
            <span
              css={`
                color: #000;
                font-weight: 600;
                font-size: 0.8rem;
              `}
            >
              {amount(totalPremiumPayable)}{" "}
              {selectedQuote?.term_pay_till === 1
                ? "Pay Once"
                : payment_frequency}
            </span>
          </div>
          <button className="premium-buy-button" onClick={handleBuyNowClick}>
            Proceed to Buy
          </button>
        </KnowYourPlanMobileFooterWrap>
      )}
      <EligibilityCard
        show={showBuyNow}
        handleClose={() => setShowBuyNow(false)}
        quote={selectedQuote}
        afterSubmit={handleAfterSubmit}
        totalPremium={totalPremiumPayable}
      />
    </>
  );
}

export default Footer;

const KnowYourPlanFooterWrap = styled.div`
  box-shadow: rgba(111, 100, 111, 0.2) 29px 7px 29px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  padding: 5px 20px 12px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 73px;

  img {
    width: 100px;
    height: 35px;
    margin: 8px 15px 15px 0;
  }
  /* .plan-info {
    display: ;
  } */

  .plan-name {
    height: 20px;
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    // font-weight: 600;
    text-align: left;
    color: #000;
  }
  .plan-type {
    height: 20px;
    font-family: Inter;
    font-weight: 500;
    // font-weight: 600;
    font-size: 16px;
    color: #000;
    ${mq.lg} {
      font-size: 12px;
    }
  }

  .all-figures {
    display: flex;
    justify-content: space-around;
    width: 550px;
    height: 55px;
    margin: 4px 10px 0 5px;
    padding: 1px 10px;
    border-radius: 11px;
    border: solid 1px #bac3cf;
    background-color: rgba(255, 255, 255, 0.71);

    .figure-type {
      font-family: "Inter";
      font-weight: 500;
      font-size: 11px;
      // font-weight: 400;
      text-align: left;
      color: #424242;
    }
  }
  .figure-value {
    font-family: "Inter";
    font-weight: 500;
    font-size: 15px;
    // font-weight: 600;
    text-align: left;
    color: #000;
  }
  .values-separator {
    width: none;
    height: 80%;
    border: 0.5px solid #bac3cf;
    margin: 4px 5px;
  }
  .premium-buy-button {
    width: 180px;
    color: #fff;
    height: 50px;
    margin: 8px 0 5px 1px;
    border-radius: 6px;
    background-color: var(--primary-color);
    border: none;
    box-shadow: none;
    :active {
      border: 1px solid blue;
    }
    .proceed-to-buy-text {
      width: 180px;
      height: 24px;
      padding: 5px;
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      margin: auto;
      color: #fff;
    }
  }
  .premium-amount-section {
    height: 50px;
    margin: 8px 0 5px 0;
    white-space: no-wrap;
    padding-left: 10px;
    border-radius: 11px;
    background-image: linear-gradient(
      to right,
      var(--primary-color-light),
      #fff
    );

    .total-premium {
      white-space: nowrap;
      width: 99px;
      height: 19px;
      margin: 0 7px 3px 0;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #808080;
    }
    .premium-value {
      width: 106px;
      margin: 3px 0 0;
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      color: #000;
    }
  }
`;

const KnowYourPlanMobileFooterWrap = styled.div`
  display: flex;
  width: 100%;
  height: 5em;
  z-index: 100;
  justify-content: space-between;
  box-shadow: rgba(111, 100, 111, 0.2) 29px 7px 29px 0px;
  position: fixed;
  bottom: 0;
  background: #fff;

  .premium-buy-button {
    font-family: Inter;
    font-weight: 500;
    padding-top: 5px;
    font-size: 15px;
    color: #fff;
    width: 50%;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    box-shadow: none;
    :active {
      border: 1px solid blue;
    }
  }
`;
