import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";
import DownArrow from "../../../assets/images/downarrow.png";
export const SelectedProductWrapper = styled.div`
  /* display: flex;
  justify-content: center;
  justify-self: flex-start;
  align-items: center;
  padding: 4px; */
`;
export const Logo = styled.div``;
export const ProductName = styled.div`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 700;
`;
export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.fullWidth ? "100%" : "50%")};
  width: ${props => props.oneBy3 && "33.333%"};
  padding: 0 10px;
  height: 50px;
  margin-bottom: 24px;
  ${mq.sm} {
    width: ${props => props.oneBy3 && "100%"};
    height: 50px !important;
    display: block;
    padding: ${props => props.editDetails && "0"};
    margin-bottom: 24px !important;
  }
`;

export const ErrorMsgWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.fullWidth ? "100%" : "50%")};
  width: ${props => props.oneBy3 && "33.333%"};
  padding: 0 10px;

  margin-bottom: 24px;
  ${mq.sm} {
    width: ${props => props.oneBy3 && "100%"};

    display: block;
    padding: ${props => props.editDetails && "0"};
    margin-bottom: 24px !important;
  }
`;

export const ErrorMsg = styled.div`
  position: ${props =>
    props.rel ? "relative" : props.checkbox ? "absolute" : ""};
  color: #d8000c;
  font-size: 10px;
  padding-bottom: ${props => (props.checkbox ? "0px" : props && "10px")};
  left: ${props => props.checkbox && "18px"};
  bottom: ${props => props.checkbox && "0px"};
`;
export const Select = styled.select`
  background-image: url(${DownArrow});
  background-position: 97% 66%;
  background-repeat: no-repeat;
  background-size: 0.79em 0.79em;
  color: #666;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 19px 10px 7px;
  font-size: 14px;
  appearance: none;
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  /* For IE10 */
  &::-ms-expand {
    display: none;
  }
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e9ecef !important;
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    border: 1px solid #233d63;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  option {
    &:hover {
      background: red;
    }
  }
`;

export const TextInput = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 19px 10px 7px;
  font-size: 14px;
  &:focus {
    color: #495057;
    background-color: #e9ecef;
    border-color: #e9ecef !important;
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    border: 1px solid #233d63;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  color: #666;
`;
export const Label = styled.label`
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 12px;
  color: var(--primary-color);
  ${mq.sm} {
    top: -8px;
    left: 12px;
  }
`;

export const NavHeaderWrapper = styled.div`
  /* height: 4.9em; */
  width: 100%;
  background-color: var(--tertiary-shade);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  margin-bottom: 1em;
  height: 120px;
  ${mq.sm} {
    height: 145px;
  }

  & ul {
    padding: 10px;
    border-radius: 50px;
    width: 450px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 30%);
    box-shadow: 0 0 0.6em #00000033;
  }

  & .nav-item {
    padding: 0.5rem 1rem;
    width: 50%;
    cursor: pointer;
    background: white;
    color: black;
    border-radius: 50px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding-top: 13px;
  }
  & .nav-item.active {
    background: #f1f8ff !important;
    color: var(--primary-color) !important;
  }
`;

export const ContentDisplaySection = styled.div`
  padding-bottom: 3em;
  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  & .btn-primary {
    font-size: 1rem !important;
  }
`;

export const Details = styled.form`
  width: 100%;
  padding: 20px 20px 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mq.sm} {
    padding: 15px 0 0;
  }
`;
