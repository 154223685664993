/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import "styled-components/macro";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
  useGetProposalDataQuery,
} from "../../../../riders/services/rider";
import moment from "moment";
import {
  ErrorMsg,
  FileInputWrapper,
  UploaderBody,
  UploadWrap,
} from "../../../ProposalPage.style";
import Select from "../../../components/Select";
import {
  addressProofOption,
  ageProofOption,
  idProofOptions,
  incomeProofOptions,
} from "../../../options/pnb/Contactdetailsoption";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload-page/docUtils";
import ButtonLoader from "../../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import Title from "../../../components/Title";
import { uploadDoc } from "../../../../DocumentUpload-page/serviceApi";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import { useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";

function PnbDocumentUpload({ setDisable, disable }) {
  const { isDocumentUploadLoading } = useSelector(state => state.proposal);
  const { data: cartData } = useGetCartQuery();
  const { data: proposalData } = useGetProposalDataQuery();
  const appData = useGetApplicationNumberQuery();
  const [appNo, setAppNo] = useState(
    appData?.data?.data?.proposal_reference_id,
  );
  const [occupationData, setOccupationData] = useState(
    proposalData?.data["Contact Details"],
  );

  const [documents, setDocuments] = useState({});
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [uploaded, setUploaded] = useState("");
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    piphoto: `${appNo}_Photograph_${currentDate}_1.pdf`,
    pidobproof: `${appNo}_AgeProof_${currentDate}_2.pdf`,
    piaddressproof: `${appNo}_AddressProof_${currentDate}_3.pdf`,
    piidproof: `${appNo}_IDProof_${currentDate}_4.pdf`,
    piincome_proof: `${appNo}_IncomeProof_${currentDate}_5.pdf`,
  });

  useEffect(() => {
    setAppNo(appData?.data?.data?.proposal_reference_id);
  }, [appData]);
  useEffect(() => {
    setOccupationData(proposalData?.data["Contact Details"]);
  }, [proposalData]);
  useEffect(() => {
    setDocName({
      piphoto: `${appNo}_Photograph_${currentDate}_1.pdf`,
      pidobproof: `${appNo}_AgeProof_${currentDate}_2.pdf`,
      piaddressproof: `${appNo}_AddressProof_${currentDate}_3.pdf`,
      piidproof: `${appNo}_IDProof_${currentDate}_4.pdf`,
      piincome_proof: `${appNo}_IncomeProof_${currentDate}_5.pdf`,
      other_doc: `${appNo}_OtherProof_${currentDate}_6.pdf`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appNo]);

  const dispatch = useDispatch();

  const onSubmit = async (e, item) => {
    const newData = await convert(documents[item].upload_file);
    try {
      dispatch(initDocumentUploadLoading());
      // eslint-disable-next-line no-unused-vars
      const { data } = await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        application_no: appNo,
        upload_file_name: documents[item]?.upload_file_name,
        upload_file_id: documents[item]?.upload_file_id,
        upload_file: newData.split(",")[1],
      });
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      dispatch(clearDocumentUploadLoading());

      return (document.getElementById(item).innerHTML = "Uploaded");
    } catch (err) {
      toast.error(`${item} Upload Failed!`, {
        toastId: `${item}_error`,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      dispatch(clearDocumentUploadLoading());
    }
  };

  // Blob
  function createPreview(fileInput) {
    let blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });

    return URL.createObjectURL(blob);
  }
  function removeDocument(document) {
    setDocuments(prev => {
      let temp = { ...prev };
      temp[document].upload_file = null;
      temp[document].upload_file_id = null;
      temp[document].upload_file_name = null;
      return temp;
    });

    setPreviews(prev => {
      let temp = { ...prev };
      temp[document] = {};
      return temp;
    });
    setDisable(prev => ({ ...prev, [document]: true }));
  }

  return (
    <div className="doc-form__wrapper my-4">
      {/* ===================================Photo============================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Photograph"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Title label={"Passport size photograph"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.piphoto} */}
              <input
                type="file"
                name="myfile"
                id={docName.piphoto}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Photograph"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      piphoto: `${appNo}_Photograph_${currentDate}_1.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["Photograph"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Photograph"]: {
                        ...(documents["Photograph"]
                          ? documents["Photograph"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 1,
                        upload_file_name: docName.piphoto,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Photograph"]: {
                        ...(previews["Photograph"]
                          ? previews["Photograph"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Photograph"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.piphoto}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Photograph"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Photograph");
                setUploaded("Photograph");
              }}
            >
              {uploaded === "Photograph" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Photograph">Upload</p>
              )}
            </button>
          )}

          {previews["Photograph"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Photograph"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Photograph"]?.upload_file && (
            <FaTrashAlt
              className={"trash-icon"}
              onClick={() => removeDocument("Photograph")}
            />
          )}
        </div>
        {errors["Photograph"] && (
          <ErrorMsg rel>{errors["Photograph"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Age Proof ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Age Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={ageProofOption}
              label={"Age Proof*"}
              selectedValue={occupationData?.age_proof}
              readOnly={true}
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <input
              type="file"
              name="myfile"
              id={docName.pidobproof}
              onClick={e => {
                e.target.value = null;
              }}
              onChange={e => {
                if (
                  !checkSize(e, 5) ||
                  !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                ) {
                  setErrors({
                    ...errors,
                    ["Age Proof"]: "File type or size not allowed",
                  });
                } else {
                  setDocName(prev => ({
                    ...prev,
                    pidobproof: `${appNo}_IDProof_${currentDate}_2.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`,
                  }));
                  setErrors({
                    ...errors,
                    ["Age Proof"]: "",
                  });
                  setDocuments({
                    ...documents,
                    ["Age Proof"]: {
                      ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
                      upload_file: e.target.files[0],
                      upload_file_id: 2,
                      upload_file_name: docName.pidobproof,
                    },
                  });
                  setPreviews({
                    ...previews,
                    ["Age Proof"]: {
                      ...(previews["Age Proof"] ? previews["Age Proof"] : {}),
                      blob_file: createPreview(e),
                    },
                  });
                }
              }}
            />
            {/* <span className="w-100 file_name_container">
              {docName.pidobproof}
            </span> */}
          </FileInputWrapper>

          {!documents["Age Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.pidobproof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Age Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["Age Proof"]?.upload_file?.name === undefined ||
                  isDocumentUploadLoading
                    ? null
                    : onSubmit(e, "Age Proof"),
                  setUploaded("Age Proof")
                );
              }}
            >
              {uploaded === "Age Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Age Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Age Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Age Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Age Proof"]?.upload_file && (
            <FaTrashAlt
              className={"trash-icon"}
              onClick={() => removeDocument("Age Proof")}
            />
          )}
        </div>
        {errors["Age Proof"] && <ErrorMsg>{errors["Age Proof"]}</ErrorMsg>}
      </UploaderBody>

      {/* ================================= Address Proof ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Address Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={addressProofOption}
              label={"Address Proof*"}
              selectedValue={occupationData?.address_proof}
              readOnly={true}
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <input
              type="file"
              name="myfile"
              id={docName.piaddressproof}
              onClick={e => {
                e.target.value = null;
              }}
              onChange={e => {
                if (
                  !checkSize(e, 5) ||
                  !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                ) {
                  setErrors({
                    ...errors,
                    ["Address Proof"]: "File type or size not allowed",
                  });
                } else {
                  setDocName(prev => ({
                    ...prev,
                    piaddressproof: `${appNo}_IDProof_${currentDate}_3.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`,
                  }));
                  setErrors({
                    ...errors,
                    ["Address Proof"]: "",
                  });
                  setDocuments({
                    ...documents,
                    ["Address Proof"]: {
                      ...(documents["Address Proof"]
                        ? documents["Address Proof"]
                        : {}),
                      upload_file: e.target.files[0],
                      upload_file_id: 3,
                    },
                  });
                  setPreviews({
                    ...previews,
                    ["Address Proof"]: {
                      ...(previews["Address Proof"]
                        ? previews["Address Proof"]
                        : {}),
                      blob_file: createPreview(e),
                    },
                  });
                }
              }}
            />
            {/* <span className="w-100 file_name_container">
              {docName.piaddressproof}
            </span> */}
          </FileInputWrapper>

          {!documents["Address Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.piaddressproof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Address Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["Address Proof"]?.upload_file?.name === undefined ||
                  isDocumentUploadLoading
                    ? null
                    : onSubmit(e, "Address Proof"),
                  setUploaded("Address Proof")
                );
              }}
            >
              {uploaded === "Address Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Address Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Address Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Address Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Address Proof"]?.upload_file && (
            <FaTrashAlt
              className={"trash-icon"}
              onClick={() => removeDocument("Address Proof")}
            />
          )}
        </div>
        {errors["Address Proof"] && (
          <ErrorMsg>{errors["Address Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Id Proof ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["ID Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={idProofOptions}
              label={"ID Proof*"}
              selectedValue={"NSAAAD"}
              readOnly={true}
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <input
              type="file"
              name="myfile"
              id={docName.piidproof}
              onClick={e => {
                e.target.value = null;
              }}
              onChange={e => {
                if (
                  !checkSize(e, 5) ||
                  !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                ) {
                  setErrors({
                    ...errors,
                    ["ID Proof"]: "File type or size not allowed",
                  });
                } else {
                  setDocName(prev => ({
                    ...prev,
                    piidproof: `${appNo}_IDProof_${currentDate}_4.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`,
                  }));
                  setErrors({
                    ...errors,
                    ["ID Proof"]: "",
                  });
                  setDocuments({
                    ...documents,
                    ["ID Proof"]: {
                      ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
                      upload_file: e.target.files[0],
                      upload_file_id: 4,
                      upload_file_name: docName.piidproof,
                    },
                  });
                  setPreviews({
                    ...previews,
                    ["ID Proof"]: {
                      ...(previews["ID Proof"] ? previews["ID Proof"] : {}),
                      blob_file: createPreview(e),
                    },
                  });
                }
              }}
            />
            {/* <span className="w-100 file_name_container">
              {docName.piidproof}
            </span> */}
          </FileInputWrapper>

          {!documents["ID Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.piidproof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["ID Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["ID Proof"]?.upload_file?.name === undefined ||
                  isDocumentUploadLoading
                    ? null
                    : onSubmit(e, "ID Proof"),
                  setUploaded("ID Proof")
                );
              }}
            >
              {uploaded === "ID Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="ID Proof">Upload</p>
              )}
            </button>
          )}

          {previews["ID Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["ID Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["ID Proof"]?.upload_file && (
            <FaTrashAlt
              className={"trash-icon"}
              onClick={() => removeDocument("ID Proof")}
            />
          )}
        </div>
        {errors["ID Proof"] && <ErrorMsg>{errors["ID Proof"]}</ErrorMsg>}
      </UploaderBody>

      {/* ================================= Income Proof ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Income Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={incomeProofOptions}
              label={"Income Proof*"}
              selectedValue={"BSC"}
              readOnly={true}
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <input
              type="file"
              name="myfile"
              id={docName.piincome_proof}
              onClick={e => {
                e.target.value = null;
              }}
              onChange={e => {
                if (
                  !checkSize(e, 5) ||
                  !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                ) {
                  setErrors({
                    ...errors,
                    ["Income Proof"]: "File type or size not allowed",
                  });
                } else {
                  setDocName(prev => ({
                    ...prev,
                    piincome_proof: `${appNo}_IDProof_${currentDate}_5.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`,
                  }));
                  setErrors({
                    ...errors,
                    ["Income Proof"]: "",
                  });
                  setDocuments({
                    ...documents,
                    ["Income Proof"]: {
                      ...(documents["Income Proof"]
                        ? documents["Income Proof"]
                        : {}),
                      upload_file: e.target.files[0],
                      upload_file_id: 5,
                      upload_file_name: docName.piincome_proof,
                    },
                  });
                  setPreviews({
                    ...previews,
                    ["Income Proof"]: {
                      ...(previews["Income Proof"]
                        ? previews["Income Proof"]
                        : {}),
                      blob_file: createPreview(e),
                    },
                  });
                }
              }}
            />
            {/* <span className="w-100 file_name_container">
              {docName.piincome_proof}
            </span> */}
          </FileInputWrapper>

          {!documents["Income Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.piincome_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Income Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["Income Proof"]?.upload_file?.name === undefined ||
                  isDocumentUploadLoading
                    ? null
                    : onSubmit(e, "Income Proof"),
                  setUploaded("Income Proof")
                );
              }}
            >
              {uploaded === "Income Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Income Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Income Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Income Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Income Proof"]?.upload_file && (
            <FaTrashAlt
              className={"trash-icon"}
              onClick={() => removeDocument("Income Proof")}
            />
          )}
        </div>
        {errors["Income Proof"] && (
          <ErrorMsg>{errors["Income Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Other Documents ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Other Document"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            {" "}
            <Title label={"Any Other Documents"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.other_doc} */}
              <input
                type="file"
                name="myfile"
                id={docName.other_doc}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Other Document"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      other_doc: `${appNo}_OtherDocument_${currentDate}_6.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["Other Document"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Other Document"]: {
                        ...(documents["Other Document"]
                          ? documents["Other Document"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 6,
                        upload_file_name: docName.other_doc,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Other Document"]: {
                        ...(previews["Other Document"]
                          ? previews["Other Document"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Other Document"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.other_doc}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Other Document"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Other Document");
                setUploaded("Other Document");
              }}
            >
              {uploaded === "Other Document" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Other Document">Upload</p>
              )}
            </button>
          )}

          {previews["Other Document"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Other Document"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Other Document"]?.upload_file && (
            <FaTrashAlt
              className={"trash-icon"}
              onClick={() => removeDocument("Other Document")}
            />
          )}
        </div>
        {errors["Other Document"] && (
          <ErrorMsg rel>{errors["Other Document"]}</ErrorMsg>
        )}
      </UploaderBody>
    </div>
  );
}

export default PnbDocumentUpload;
