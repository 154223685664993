import styled from "styled-components";
import * as md from "../../../styles/mediaQueries";

export const HeaderLogoWrapperStyled = styled.div`
  height: 45px;
  padding: 10px 0;
  img {
    height: 100%;
  }
  ${md.sm} {
    /* padding: 0; */
    height: 40px;
  }
`;
