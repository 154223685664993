import React from "react";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import { HeaderContainer, HeaderLabel, HeaderWrapper } from "./Header.style";
import * as mq from "../../../../styles/mediaQueries";
import MobileUIHeader from "./MobileUIHeader";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
const header_sections = [
  "Plan Details",
  "Addon Coverages",
  "Additional Features",
  "Claim Process",
  "About Company",
];
const Header = ({ currentSection, setCurrentSection }) => {
  // const [product_name, plan_name] = selectedQuote?.product?.name?.split("-");
  React.useEffect(() => {
    document
      ?.getElementById(`${currentSection}`)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [currentSection]);
  const matches = useMediaQuery(`${mq.smForHook}`);
  return (
    <>
      {matches && <MobileUIHeader />}
      <HeaderContainer>
        <HeaderWrapper id="contain">
          {!matches ? (
            <></>
          ) : (
            <>
              <BiChevronsLeft
                size={28}
                onClick={e => {
                  currentSection > 0 &&
                    document.getElementById("contain").scrollBy({
                      top: 0,
                      left: -100,
                      behavior: "smooth",
                    });
                }}
                style={{
                  display: currentSection === 0 ? "none" : "",
                  position: "fixed",
                  top: "8.4rem",
                  left: "0",
                  right: "90%",
                  color: "var(--primary-color-dark)",
                  backgroundColor: "var(--tertiary-shade)",
                  zIndex: "100",
                }}
              />
            </>
          )}
          {header_sections.map((item, idx) => (
            <>
              <HeaderLabel
                id={idx}
                key={item}
                active={idx === currentSection}
                onClick={() => {
                  setCurrentSection(idx);
                }}
              >
                {item}
              </HeaderLabel>
            </>
          ))}
          {matches ? (
            <BiChevronsRight
              size={28}
              onClick={e => {
                currentSection < 4 &&
                  document.getElementById("contain").scrollBy({
                    top: 0,
                    left: 100,
                    behavior: "smooth",
                  });
              }}
              style={{
                display: currentSection === 4 ? "none" : "",
                position: "fixed",
                top: "8.4rem",
                right: "0",
                color: "var(--primary-color-dark)",
                backgroundColor: "var(--tertiary-shade)",
                zIndex: "100",
              }}
            />
          ) : (
            <></>
          )}
        </HeaderWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
