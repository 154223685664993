import React from "react";
import styled from "styled-components/macro";

import parse from "html-react-parser";
import { useSelector } from "react-redux";

import { BackButton } from "../../../components";
// import TalkToUsBtn from "../../../components/navbar/TalkToUsBtn-component";

const TermLifeInsurance = () => {
  const { termquotepage } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  return (
    <div className="life-insurance">
      <Wrap className="container">
        <StyledBackButton toPage="/quote" />

        {termquotepage?.termdefinitiondata ? (
          parse(termquotepage.termdefinitiondata)
        ) : (
          <div
            className="container"
            css={`
              margin-top: 20%;
              text-align: center;
              font-size: 30px;
              font-weight: 600;
            `}
          >
            No Data
          </div>
        )}
      </Wrap>
    </div>
  );
};

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  top: 4em;
  left: 2em;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  font-size: 1.37rem;
  background: #fff;
`;

const Wrap = styled.div`
  padding: 20px 40px;
  margin-bottom: 10px;
  font-family: "Inter" !important;
  font-weight: 400;
  h2 {
    font-size: 24px;
    margin-top: 50px;
    font-weight: 700;
    color: rgb(74, 89, 113);
  }
  p {
    width: 80%;
    font-size: 15px;
  }
  table,
  td,
  th {
    border: 2px solid rgb(190, 190, 190);
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    margin-top: 40px;
  }
  td {
    padding: 10px;
    font-size: 14px;
  }
  th {
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export default TermLifeInsurance;
