import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHeaderDetails } from "../hooks";
import { getAge, getAnnualIncome, getGender, getTobaccoHabit } from "../utils";

const HeaderDetails = ({ showEditDetailPanel, setShowEditDetailPanel }) => {
  const location = useLocation();
  const {
    gender,
    dob,
    tobacco_habit,
    term_annual_income,
    occupation,
    education,
    isMobileOrTablet,
  } = useHeaderDetails();
  const isNewCompare = location.pathname.includes("new-compare");

  return (
    <>
      <ul
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          margin: "auto",
          textTransform: "capitalize",
          fontFamily: "Inter",
          fontWeight: "500",
          whiteSpace: "nowrap",
        }}
      >
        <li>{getGender(gender)}</li>
        <li>{getAge(dob)}</li>
        {!isMobileOrTablet && (
          <>
            <li>{getTobaccoHabit(tobacco_habit)}</li>
            <li>{occupation?.split("_").join(" ")}</li>
            <li>{education?.split("_").join(" ")}</li>
            <li>₹ {getAnnualIncome(term_annual_income)}</li>
          </>
        )}

        {location.pathname !== "/shortlisted-quote" && (
          <li
            style={{
              color: "var(--primary-color)",
              fontFamily: "Inter",
              fontWeight: "500",
            }}
            onClick={() => {
              setShowEditDetailPanel(!showEditDetailPanel);
            }}
          >
            {isNewCompare ? "View" : "Edit "}
          </li>
        )}
      </ul>
    </>
  );
};

export default HeaderDetails;
