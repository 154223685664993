import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { boxShadow } from "../../../../styles";
import * as colors from "../../../../styles/colors";
import { sm, xs } from "../../../../styles/mediaQueries";
import MonthlyPopup from "../../components/MonthlyPopup";
import {
  changeFilter,
  selectFilterOptions,
  selectTermFilters,
} from "../../Filters/Filters.slice";
import { OptionButton, StyledLiWrapper } from "./FilterModal";
import styled from "styled-components/macro";
import LumpsumMonthlyPopup from "../../components/LumpsumMonthlyPopup";
import CustomLifeCoverModal from "../../../../components/modal/CustomLifeCoverModal";
import { getValue } from "../../Filters/helper";
import {
  WhatsAppShareButton,
  ShareButton,
  CopyToClipboardButton,
} from "../../../compare/header";

const rootFontSize = "min(calc(16 * 100vw / 360), 16px)";

function getDisplayName(code, values) {
  const filter = values.find(option => option.code === code);
  if (!filter) return "";
  return filter.display_name;
}

function FiltersMobile({ isQuotesPage, ...props }) {
  const { term_plan_type, term_coverupto, term_payout_option, term_pay_till } =
    useSelector(selectTermFilters);
  const filterOptions = useSelector(selectFilterOptions);

  const displayPlanType = getDisplayName(
    term_plan_type,
    filterOptions.term_plan_type,
  );

  const displayNomineePayout = getDisplayName(
    term_payout_option,
    filterOptions.term_payout_option,
  );

  // const displayLifeCover = getDisplayName(
  //   term_sum_assured,
  //   filterOptions.term_sum_assured,
  // );

  const { filters, defaultFilters } = useSelector(state => state.filters);
  const [showCustom, setShowCustom] = useState(false);
  const [nomineeGetsPopup, setNomineeGetsPopup] = useState(null);

  return (
    <div
      id="filters__mobile--fixed-wrapper"
      css={`
        display: none;
        font-size: ${rootFontSize};
        padding-top: 0.37em;
        box-shadow: ${boxShadow};
        ${xs} {
          display: block;
        }
        ${sm} {
          display: block;
          width: 95%;
          margin-left: 10px;
        }
      `}
    >
      <div
        css={`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1em;
          padding-bottom: 0.37em;
          // & > div {
          //   flex: 1;
          // }
          // ${sm} {
          //   width: 100%;
          // }
          ${xs} {
            width: 94%;
          }
        `}
      >
        <Filter
          label="Life cover"
          value={getValue(filters.term_sum_assured)}
          isScrollable
          showCustom={showCustom}
          isDifferent={
            filters.term_sum_assured !== defaultFilters?.term_sum_assured
          }
        >
          <Life showCustom={showCustom} setShowCustom={setShowCustom} />
        </Filter>
        <Filter
          label="Cover till age"
          value={term_coverupto + " Years"}
          isScrollable
          isDifferent={
            filters.term_coverupto !== defaultFilters?.term_coverupto
          }
        >
          <CoverUpto />
        </Filter>
        <Filter
          label="Pay till age"
          value={term_pay_till === 1 ? "Single Pay" : term_pay_till + " Years"}
          isScrollable
          isDifferent={filters.term_pay_till !== defaultFilters?.term_pay_till}
        >
          <PayTillAge />
        </Filter>
      </div>
      {isQuotesPage && (
        <div
          css={`
            background-color: ${colors.gray1};
            padding: 0.37em 1em;
            display: flex;
            align-items: center;
            & > div {
              flex: 1;
            }
          `}
        >
          <Filter label="Plan type:" value={displayPlanType} type={"row"}>
            <LifeCoverMobile />
          </Filter>
          <Filter
            label="Nominee payout:"
            value={displayNomineePayout}
            type={"row"}
            hideBottomSheet={nomineeGetsPopup}
          >
            <PayoutPopup
              nomineeGetsPopup={nomineeGetsPopup}
              setNomineeGetsPopup={setNomineeGetsPopup}
            />
          </Filter>
        </div>
      )}
    </div>
  );
}

const rowFilterStyle = `
    flex-direction: row;
    align-items: center;
    &:not(:last-child) {
        border-right: 1px solid ${colors.font1};
        padding-right: 0.37em;
        margin-right: 0.37em;
    }
    & div{
        flex: 1;
        font-size: 0.66em;
    }
    & button {
        font-size: 0.66em;
        width: max-content;

        & span {
            color: ${colors.primary};
        }
    }
`;

export function Filter({
  label,
  value,
  type = "column",
  children,
  isScrollable,
  showCustom,
  hideBottomSheet,
  isDifferent,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const toggleBottomSheet = () => setOpen(!open);

  return (
    <div
      css={`
        display: flex !important;
        flex-direction: row !important;
        margin-left: ${type === "column" && "0"};
        ${type === "row" ? rowFilterStyle : ""};
        @media (max-width: 476px) {
          display: flex !important;
          flex-direction: column !important;
        }
      `}
      {...props}
    >
      <div
        css={`
          font-size: 0.73em;
          color: ${colors.font1};
          white-space: nowrap;
          margin: 0 7px;
        `}
      >
        {label}
      </div>
      <button
        onClick={toggleBottomSheet}
        css={`
          font-size: 0.76em;
          width: 100%;
          white-space: nowrap;

          align-items: center;
          "& button": {
            align-items: center;
          },
     
        `}
      >
        <Value isDifferent={isDifferent}>{value}</Value>
        <BiChevronDown
          css={`
            font-size: 1rem;
          `}
        />
      </button>
      <FilterBottomSheet
        title={label}
        show={open}
        isScrollable={isScrollable}
        hideBottomSheet={hideBottomSheet}
        showCustom={showCustom}
        onHide={toggleBottomSheet}
      >
        {React.Children.map(children, child =>
          React.cloneElement(child, { onChange: toggleBottomSheet }),
        )}
      </FilterBottomSheet>
    </div>
  );
}
const Value = styled.span`
  color: ${props => (props.isDifferent ? "var(--primary-color)" : "#111")};
`;

function LifeCoverMobile({ onChange }) {
  const { term_plan_type } = useSelector(selectFilterOptions);
  const { term_plan_type: term_plan } = useSelector(
    state => state.filters.filters,
  );
  const dispatch = useDispatch();

  const handleClick = value => {
    dispatch(changeFilter({ term_plan_type: value }));
    onChange && onChange();
  };

  return (
    <div>
      {term_plan_type.map(({ code, display_name }) => (
        <StyledLiWrapper
          key={code}
          name={display_name}
          value={code}
          id_for={code}
          checked={code === term_plan ? true : false}
          onClick={evt => handleClick(code)}
        />
      ))}
    </div>
  );
}

export function CoverUpto({ onChange }) {
  const { term_coverupto } = useSelector(selectFilterOptions);
  const { term_coverupto: cover_upto } = useSelector(selectTermFilters);
  const [active, setActive] = useState(cover_upto);
  const dispatch = useDispatch();

  const handleClick = value => {
    dispatch(changeFilter({ term_coverupto: value }));
    setActive(value);
    onChange && onChange();
  };
  useEffect(() => {
    if (active !== null) {
      document.getElementById(`${active}`)?.scrollIntoView();
    }
  }, [active]);
  return (
    <div>
      {term_coverupto.map(({ code, display_name }) => (
        <OptionButton
          key={code}
          id={code}
          name={display_name}
          value={code}
          id_for={code}
          total={2}
          isSelected={code === cover_upto}
          checked={false}
          onClick={evt => handleClick(code)}
        >
          {display_name}
        </OptionButton>
      ))}
    </div>
  );
}

export function PayTillAge({ onChange }) {
  const { term_pay_till } = useSelector(selectFilterOptions);
  const { term_pay_till: term_pay } = useSelector(selectTermFilters);
  const [active, setActive] = useState(term_pay);
  const dispatch = useDispatch();

  const handleClick = value => {
    dispatch(changeFilter({ term_pay_till: value }));
    setActive(value);
    onChange && onChange();
  };
  useEffect(() => {
    if (active !== null) {
      document.getElementById(`${active}`)?.scrollIntoView();
    }
  }, [active]);
  return (
    <div
      css={`
        padding-bottom: 20px;
        overflow: auto;
      `}
    >
      {term_pay_till.map(({ code, display_name }) => (
        <OptionButton
          key={code}
          id={code}
          name={display_name}
          value={code}
          total={2}
          isSelected={code === term_pay}
          id_for={code}
          onClick={evt => handleClick(code)}
        >
          {display_name}
        </OptionButton>
      ))}

      <FooterButton
        onClick={() => {
          dispatch(changeFilter({ term_pay_till: 1 }));
          onChange && onChange();
        }}
      >
        Pay Once
      </FooterButton>
    </div>
  );
}

function PayoutPopup({ onChange, nomineeGetsPopup, setNomineeGetsPopup }) {
  return (
    <>
      <NomineePayout setNomineeGetsPopup={setNomineeGetsPopup} />
      <MonthlyPopup
        show={nomineeGetsPopup === "MI"}
        setShow={setNomineeGetsPopup}
        onChange={onChange}
      />
      <LumpsumMonthlyPopup
        show={nomineeGetsPopup === "LMI"}
        setShow={setNomineeGetsPopup}
        onChange={onChange}
      />
    </>
  );
}

function NomineePayout({ onChange, setNomineeGetsPopup }) {
  const { term_payout_option } = useSelector(selectFilterOptions);
  const { term_payout_option: nomineeGets } = useSelector(selectTermFilters);
  const dispatch = useDispatch();

  const handleClick = value => {
    if (value === "L") {
      dispatch(changeFilter({ term_payout_option: "L" }));
    } else {
      setNomineeGetsPopup(value);
    }
    if (term_payout_option !== "L" && value === "L") {
      onChange && onChange();
    }
  };

  return (
    <div>
      {term_payout_option.map(({ code, display_name }) => (
        <StyledLiWrapper
          key={code}
          name={display_name}
          value={code}
          id_for={code}
          checked={code === nomineeGets ? true : false}
          onClick={() => handleClick(code)}
        />
      ))}
    </div>
  );
}

function Life({ onChange, setShowCustom, showCustom }) {
  return (
    <>
      {!showCustom && (
        <>
          <LifeCover onChange={onChange} />
          <FooterButton
            onClick={() => {
              setShowCustom(true);
            }}
          >
            Custom Input
          </FooterButton>
        </>
      )}
      <CustomLifeCoverModal
        showCustom={showCustom}
        setShowCustom={setShowCustom}
        onChange={onChange}
      />
    </>
  );
}

export function LifeCover({ onChange }) {
  const { term_sum_assured } = useSelector(selectFilterOptions);
  const { term_sum_assured: sum_assured } = useSelector(selectTermFilters);

  const [active, setActive] = useState(sum_assured);
  const dispatch = useDispatch();

  const handleClick = value => {
    dispatch(changeFilter({ term_sum_assured: value }), true);
    setActive(value);
    onChange && onChange();
  };

  useEffect(() => {
    if (active !== null) {
      document.getElementById(`${active}`)?.scrollIntoView();
    }
  }, [active]);
  return (
    <div
      css={`
        padding-bottom: 20px;
      `}
    >
      {term_sum_assured.map(({ code, display_name }) => (
        <OptionButton
          key={code}
          id={code}
          name={display_name}
          value={code}
          id_for={code}
          onClick={() => handleClick(code)}
          total={2}
          isSelected={code === sum_assured}
        >
          {display_name}
        </OptionButton>
      ))}
    </div>
  );
}

function FilterBottomSheet({
  title,
  children,
  isScrollable,
  showCustom,
  onHide,
  hideBottomSheet,
  ...props
}) {
  const handleClick = evt => onHide && onHide(evt);
  return (
    <ModalBottom
      onHide={handleClick}
      isScrollable={isScrollable}
      showCustom={showCustom}
      hideBottomSheet={hideBottomSheet}
      {...props}
    >
      <div
        css={`
          font-family: Inter;
          font-weight: 400;
          background-color: ${colors.gray1};
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1em;
        `}
      >
        <h1
          css={`
            font-size: 1.25em;
            margin: 0;
            font-family: "Inter";
            font-weight: 700;
          `}
        >
          {title}
        </h1>
        <button
          css={`
            font-size: 1.7em;
            margin-right: 5px;
          `}
          onClick={handleClick}
        >
          <IoCloseSharp />
        </button>
      </div>
      <div
        css={`
          padding: 1em;
          margin-bottom: 10px;
          overflow: auto;
        `}
      >
        {children}
      </div>
    </ModalBottom>
  );
}

function ModalBottom({
  children,
  isScrollable,
  showCustom,
  hideBottomSheet,
  show = false,
  ...props
}) {
  return (
    <Modal
      show={show}
      scrollable={isScrollable}
      animation={false}
      css={`
        & .modal-dialog {
          position: fixed;
          bottom: 0;
          left: 0;
          margin: 0;
          width: 100%;
          & .modal-content {
            border-radius: 1em 1em 0 0;
            position: fixed;
            max-height: 50%;
            overflow: auto;
            bottom: ${showCustom || hideBottomSheet ? "100%" : "0"};
          }
        }
      `}
      {...props}
    >
      {children}
    </Modal>
  );
}

export function ShareMobile({ show, ...props }) {
  return (
    <div
      css={`
        display: none;
        ${sm} {
          display: ${show ? "block" : "none"};
          position: fixed;
          bottom: 50px;
          left: 0;
          width: 100%;
          background: #fff;
          height: 50px;
          font-size: ${rootFontSize};
          padding-top: 0.37em;
          box-shadow: ${boxShadow};
        }
      `}
    >
      <div
        css={`
          display: flex;
          padding: 0 3em;
          padding-bottom: 0.37em;
          justify-content: space-between;
        `}
      >
        <div>
          <WhatsAppShareButton />
        </div>
        <div>
          <ShareButton />
        </div>
        <div>
          <CopyToClipboardButton />
        </div>
      </div>
    </div>
  );
}

export default FiltersMobile;

const FooterButton = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  width: 100%;
  z-index: 100;
  height: 3.37em;
  font-family: "Inter";
  font-weight: 700;
  color: #fff;
`;
