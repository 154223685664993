import React, { useState } from "react";
import { FaRegBookmark } from "react-icons/fa";
import { useSelector } from "react-redux";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useHistory } from "react-router-dom";
import "styled-components/macro";
import { Modal } from "react-bootstrap";

const ShortListedPlansMobile = () => {
  const { shortListedQuotesData, shortListedQuote } = useSelector(
    state => state.quotes,
  );
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div
        css={`
          display: flex;
        `}
      >
        <div>
          {/* <span
            onClick={handleClose}
            css={`
              background-color: blue;
              color: white;
              position: absolute;
              transform: translateX(-50%);
              font-size: 15px;
              min-width: 300px;
              padding: 10px 15px;
              top: -200px;
              left: 180px;
            `}
          >
            You have not shortlisted any plan. Please shortlist a plan first.
          </span> */}
          <button
            // onClick={() => {
            //   history.push(`/shortlisted-quote?enquiryId=${enquiryId}`);
            // }}
            onClick={() => {
              if (shortListedQuote?.length) {
                history.push(`/shortlisted-quote?enquiryId=${enquiryId}`);
              } else {
                handleShow();
              }
            }}
            // disabled={shortListedQuotesData.length === 0}
          >
            <FaRegBookmark
              css={`
                height: 18px;
                width: 15px;
                color: GrayText;
                margin-left: 35px;
                margin-top: 10px;
              `}
            />
          </button>
        </div>
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            css={`
              backdrop-filter: blur(5px);
              padding: 40px;
            `}
          >
            <Modal.Header
              closeButton
              css={`
                height: 25px;
              `}
            >
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body
              css={`
                height: 120px;
              `}
            >
              <b>
                {" "}
                You have not shortlisted any plan. Please shortlist a plan
                first.
              </b>
            </Modal.Body>
          </Modal>
        </div>
        {shortListedQuotesData.length ? (
          <div
            css={`
              border-radius: 50%;
              background-color: var(--primary-color);
              color: white;
              text-align: center;
              height: 16px;
              width: 15px;
              // margin-right: 15px;
            `}
          >
            {shortListedQuotesData.length}
          </div>
        ) : (
          <></>
        )}
      </div>

      <div>
        <p
          css={`
            margin-bottom: 0px;
            margin-top: 2px;
            margin-left: 5px;
            font-size: 9px;
            color: GrayText;
          `}
        >
          Shortlisted Plans
        </p>
      </div>
    </div>
  );
};

export default ShortListedPlansMobile;
