import logo from "../../../assets/images/logo/logo.png";
import { useInputPageHook } from "../../../pages/InputPage/hooks";
import { HeaderLogoWrapperStyled } from "../styles/headerLogo.styled";

const HeaderLogo = props => {
  const { termSettings } = useInputPageHook();
  return (
    <HeaderLogoWrapperStyled {...props}>
      <a href="/">
        <img src={termSettings?.logo || logo} alt="Logo" />{" "}
      </a>
    </HeaderLogoWrapperStyled>
  );
};

export default HeaderLogo;
