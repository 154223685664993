import { useState } from "react";
import styled from "styled-components";
import { TraceId } from "../..";
import EditDetailPanel from "../../navbar/EditDetailPanel-component";
import ShareQuoteBtn from "../../navbar/ShareQuoteBtn";
import TalkToUsBtn from "../../navbar/TalkToUsBtn-component";
import { useHeaderData } from "../hooks";
import {
  HeaderContainerStyled,
  HeaderLinksWrapperStyled,
} from "../styles/headerData.styled";
import HeaderDetails from "./HeaderDetails";
import HeaderProfile from "./HeaderProfile";

const HeaderData = () => {
  const [showEditDetailPanel, setShowEditDetailPanel] = useState(false);
  const { location, isMobile } = useHeaderData();
  return (
    <HeaderContainerStyled>
      {(location.pathname === "/quote" ||
        location.pathname === "/new-compare" ||
        location.pathname === "/shortlisted-quote") && (
        <HeaderLinksWrapperStyled>
          <HeaderDetails
            showEditDetailPanel={showEditDetailPanel}
            setShowEditDetailPanel={setShowEditDetailPanel}
          />
        </HeaderLinksWrapperStyled>
      )}
      <NavBtnWrapper>
        {!isMobile &&
          (location.pathname === "/quote" ||
            location.pathname === "/new-compare" ||
            location.pathname === "/shortlisted-quote") && (
            <>
              <ShareQuoteBtn />
              <TalkToUsBtn />
            </>
          )}
        {(isMobile && location.pathname === "/") ||
          ((location.pathname === "/insurance-information" ||
            location.pathname === "/riders-information") && (
            <>
              <TalkToUsBtn />
            </>
          ))}
        {location.pathname === "/document_upload" ||
        location.pathname === "/riders" ||
        location.pathname === "/success" ||
        location.pathname === "/proposal" ? (
          <TraceId />
        ) : (
          <></>
        )}
      </NavBtnWrapper>
      <HeaderProfile />
      <EditDetailPanel
        showEditDetailPanel={showEditDetailPanel}
        setShowEditDetailPanel={setShowEditDetailPanel}
        className={showEditDetailPanel ? "d-block" : "d-none"}
      />
    </HeaderContainerStyled>
  );
};

export default HeaderData;

const NavBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  & button {
    font-size: 0.6rem;
    font-weight: bold;
    border-radius: 6px;
  }
`;
