export const itProofOptions = [
  { code: "pancard", display_name: "PAN" },
  { code: "form 60", display_name: "FORM 60" },
];

export const ageProofOptions = [
  // { code: "Adhr", display_name: "Masked Aadhaar Card Copy" },
  { code: "DEF", display_name: "Defence I Card" },
  { code: "DL", display_name: "Driving License(Current)" },
  { code: "EMPCRT", display_name: "Employers Certificate" },
  { code: "GOVTID", display_name: "Government Employee ID" },
  { code: "GSR", display_name: "Government Registered document or ID Proof" },
  { code: "MUN", display_name: "Municipal Record" },
  { code: "PAN", display_name: "PAN Card" },
  { code: "PSPT", display_name: "Passport(Current)" },
  { code: "SC", display_name: "School/College Record" },
];

export const addressProofOptions = [
  // { code: "Adhr", display_name: "Masked Aadhaar card copy" },
  { code: "Driving License", display_name: "Driving License(Current)" },
  { code: "Voters ID", display_name: " Voters ID Card" },
  { code: "Passport", display_name: "Passport(Current)" },
];

export const incomeProofOptions = [
  { code: "NIL1", display_name: "Agricultural Income Certificate" },
  { code: "CMP ACCT", display_name: "Audited Company Accounts" },
  { code: "PLBS", display_name: "Audited Firm Accounts" },
  {
    code: "NIL",
    display_name: "Bank Cash Flow Stmt,Pass Book (Last 6 Months)",
  },
  { code: "CACERT", display_name: "Chartered Accnt Certificate" },
  { code: "SALCER", display_name: "Employers Certificate" },
  { code: "FORM16", display_name: "Form 16" },
  { code: "ITRETN", display_name: "Income Tax Returns (Last 3 Years)" },
  { code: "NIL3", display_name: "Land Records" },
  { code: "NIL4", display_name: "None" },
  {
    code: "PAYSLP",
    display_name:
      "Salary Slip Signed by authorized signatory of Employer (Last 3 Months)",
  },
];

export const idProofOptions = [
  // { code: "Adhr", display_name: "Masked Aadhaar Card Copy" },
  { code: "Driving license", display_name: "Driving License(Current)" },
  { code: "Card Issued by NREGA", display_name: "Job Card Issued by NREGA" },
  { code: "Passport", display_name: "Passport(Current)" },
  { code: "Voters ID", display_name: "Voters ID Card" },
];

export const eiaDetailsOptions = [
  { code: "CAMSRep", display_name: "CAMS Repository Services Ltd" },
  {
    code: "CIRL",
    display_name: "Central Insurance Repository Limited (CDSL Group)",
  },
  { code: "KARVY", display_name: "Karvy Insurance Repository Limited" },
  { code: "NDML", display_name: "NSDL Database Management Limited" },
  { code: "Not Interested", display_name: "No Preference" },
];
