import { BiArrowBack } from "react-icons/bi";
import { FaRegCopy, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import fyntuneLogo from "../assets/images/logo/logo.png";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import useMediaQuery from "../custom-hooks/useMediaQuery";
import { useGetEnquiriesQuery } from "../pages/riders/services/rider";
import "styled-components/macro";
import * as mq from "../styles/mediaQueries";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useSelector } from "react-redux";

export function FullScreenLoader() {
  const { termsettings } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  return (
    <div
      css={`
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
      `}
    >
      {termsettings?.loader_image ? (
        <img src={termsettings?.loader_image} alt="loader" width="200" />
      ) : (
        <img src={fyntuneLogo} alt="loader" width="200" />
      )}
    </div>
  );
}

export function BackButton({
  onClick = () => {},
  css = ``,
  toPage = ``,
  ...props
}) {
  const enquiryId = useUrlQuery("enquiryId");
  // const matches = useMediaQuery(`${mq.smForHook}`);
  const history = useHistory();
  const location = useLocation();

  const handleClick = evt => {
    onClick && onClick(evt);
    history.replace(`${toPage}?enquiryId=${enquiryId}`);
  };

  return (
    <button
      {...props}
      css={`
        position: absolute;
        top: 6em;
        left: 6em;
        background: none;
        border: none;
        color: #333;
        ${css};
      `}
      onClick={handleClick}
    >
      <div
        css={`
          background-color: var(--tertiary-shade);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2em;
          width: 2em;
          border-radius: 50%;
          ${mq.sm} {
            font-weight: bold !important;
            width: ${location.pathname === "/compare" ? "60px" : "1.5em"};
            height: ${location.pathname === "/compare" ? "25px" : "1.5em"};
          }
        `}
      >
        <BiArrowBack size={location.pathname === "/compare" ? 18 : ""} />
      </div>
    </button>
  );
}

export function CloseButton({ css = ``, ...props }) {
  return (
    <button
      css={`
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(30%, -30%);
        z-index: 100;
        background-color: #fff;
        height: 1.6em;
        width: 1.6em;
        font-size: 1.2rem;
        border-radius: 50%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0.6em #00000033;
        ${css};
      `}
      {...props}
    >
      <FaTimes />
    </button>
  );
}

export function CloseBackButton({
  onClick = () => {},
  css = ``,
  toPage = ``,
  ...props
}) {
  const enquiryId = useUrlQuery("enquiryId");
  // const matches = useMediaQuery(`${mq.smForHook}`);
  const history = useHistory();

  const handleClick = evt => {
    onClick && onClick(evt);
    history.replace(`${toPage}?enquiryId=${enquiryId}`);
  };

  return (
    <button
      {...props}
      css={`
        position: absolute;
        top: 6em;
        left: 6em;
        background: none;
        border: none;
        color: #333;
        ${css};
      `}
      onClick={handleClick}
    >
      <div
        css={`
          background-color: var(--tertiary-shade);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2em;
          width: 2em;
          border-radius: 50%;
        `}
      >
        <FaTimes />
      </div>
    </button>
  );
}

export function LoadEnquiry({ children, loader, ...props }) {
  const { isLoading, isError } = useGetEnquiriesQuery();

  if (isLoading) return <div {...props}>{loader}</div> || <p>Loading...</p>;

  if (isError) return <p>Error</p>;

  return <div {...props}>{children}</div>;
}

export function TraceIdDisplay({ ...props }) {
  const { data } = useGetEnquiriesQuery();
  const {
    data: { trace_id },
  } = data;
  const [allow, setAllow] = useState(true);

  const matches = useMediaQuery(`${mq.mdForHook}`);
  const handleClick = () => {
    setAllow(false);
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(trace_id).then(() =>
        toast.success("Copied!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
        }),
      );
      setTimeout(() => {
        setAllow(true);
      }, 1400);
    }
  };

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        gap: 0.37em;
        ${mq.sm} {
          font-size: 11px !important;
          i {
            font-size: 11px !important;
            margin-left: 4px !important;
          }
        }
      `}
      {...props}
    >
      <div
        style={{
          width: "max-content",
          color: "var(--tertiary-text)",
          fontFamily: "Inter",
          fontWeight: "500",
          cursor: "auto",
        }}
      >
        {matches ? `CRN:` : "CRN / Trace Id:"}
      </div>
      <div
        style={{
          cursor: "auto",
        }}
      >
        <div style={{ color: "var(--primary-color)", display: "inline" }}>
          <span id="trace">{trace_id}</span>
          <FaRegCopy
            onClick={allow ? handleClick : null}
            title="Copy to Clipboard"
            style={{
              fontSize: "12px",
              color: "var(--tertiary-text)",
              display: "inline",
              marginLeft: "8px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function TraceId() {
  return (
    <LoadEnquiry
      loader={<div>Loading...</div>}
      css={`
        font-family: "Inter";
        font-weight: 500;
        width: 100%;
        margin-right: 10px;
      `}
    >
      <TraceIdDisplay />
    </LoadEnquiry>
  );
}
