import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import {
  setEducationOptions,
  setEligibilityGrid,
  setOccupationOptions,
} from "../../product-page/EligibilityCard/Eligibility.slice";
import {
  fetchSeeDetailsData,
  setSelectedQuote,
} from "../../product-page/Quotes/quotes.slice";
import { clearQuotes } from "../../product-page/components/comparePlanPopup.slice";
import { useGetCompareQuotePremiumQuery } from "../../riders/services/rider";
import {
  removeQuoteInList,
  setIsPremiumLoading,
  setRedirectFrom,
  setSelectedQuoteToBuy,
  setcomingFrom,
  updateQuoteInList,
} from "../slices/NewCompare.slice";
const useCompareTableQuote = (quote, i, setShowEligibilityPopup) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  const {
    companyAlias,
    product,
    termPayoutOption,
    termSumAssured,
    termCoverupto,
    termPayTill,
    termPayFrequency,
  } = quote;

  const { data, isLoading, error } = useGetCompareQuotePremiumQuery({
    company_alias: companyAlias?.code,
    product_id: +product?.code,
    term_payout_option: termPayoutOption,
    term_sum_assured: +termSumAssured?.code,
    term_coverupto: +termCoverupto?.code,
    term_pay_till: +termPayTill?.code,
    term_pay_frequency: +termPayFrequency?.code,
  });
  useEffect(() => {
    dispatch(setIsPremiumLoading(isLoading));
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
        className: "toast-message",
      });
    } else {
      data?.data?.[0] &&
        dispatch(
          updateQuoteInList({
            i,
            quote: {
              ...quote,
              totalPremium: data?.data?.[0]?.total_premium,
              termInsuranceId: data?.data?.[0]?.term_insurance_id,
            },
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, dispatch]);

  if (data?.data?.length === 0) {
    dispatch(removeQuoteInList(i));
    toast.error("This Combination is not available !!", {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: false,
      className: "toast-message",
    });
  }
  const location = useLocation();
  const gotoKnowYourPlan = () => {
    const currentUrl = location.pathname;
    dispatch(setcomingFrom(currentUrl));
    history.push(`/know-your-plan?enquiryId=${enquiryId}`);
  };

  const handleKnowYourPlanOnClick = () => {
    !isLoading &&
      data &&
      dispatch(
        setSelectedQuote({
          ...data?.data?.[0],
          frequency: termPayFrequency?.display_name,
        }),
      );
    !isLoading && data && dispatch(setRedirectFrom("NewCompare"));
    !isLoading &&
      data &&
      dispatch(
        fetchSeeDetailsData(data?.data?.[0]?.product.id, () => {
          gotoKnowYourPlan();
        }),
      );
  };

  const handlePremiumClick = () => {
    dispatch(setEducationOptions(data?.data?.[0]?.education_list));
    dispatch(setOccupationOptions(data?.data?.[0]?.occupation_list));
    dispatch(setSelectedQuote(data?.data?.[0]));
    dispatch(setSelectedQuoteToBuy(data?.data?.[0]));
    dispatch(setEligibilityGrid(data?.data?.[0]?.eligibilityGrid));
    dispatch(clearQuotes());
    setShowEligibilityPopup(true);
  };

  return {
    isLoading,
    data,
    companyAlias,
    product,
    enquiryId,
    handleKnowYourPlanOnClick,
    termSumAssured,
    termCoverupto,
    termPayTill,
    termPayFrequency,
    handlePremiumClick,
  };
};

export default useCompareTableQuote;
