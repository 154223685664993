/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "styled-components/macro";
import { useEffect, useState } from "react";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload-page/docUtils";
import { uploadDoc } from "../../../../DocumentUpload-page/serviceApi";
import Select from "../../../components/Select";
import {
  useGetCartQuery,
  useGetEnquiriesQuery,
  useGetProposalDataQuery,
} from "../../../../riders/services/rider";
import {
  addressProofOptions,
  ageProofOptions,
  idProofOption,
  incomeProofOption,
} from "../../../options/bajaj/occupationDetailsOptions";
import {
  ErrorMsg,
  FileInputWrapper,
  UploaderBody,
  UploadWrap,
} from "../../../ProposalPage.style";
import { HR } from "../../../components/Divider";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import ButtonLoader from "../../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";
const BajajDocumentUpload = ({
  setDisable,
  setSuccessBajajDocUpload,
  successBajajDocUpload,
}) => {
  const { isDocumentUploadLoading } = useSelector(state => state.proposal);
  const { data, isLoading: isEnquiryLoading } = useGetEnquiriesQuery();
  const { data: cartData } = useGetCartQuery();
  const { data: proposalData } = useGetProposalDataQuery();
  const occupationData = proposalData?.data["Insured Occupation Details"];
  const { doc_required, isLoading, proposal_reference_id } = useSelector(
    state => state.proposal,
  );
  const mainDoc = [
    "address_proof",
    "recent_photograph",
    "age_proof",
    "id_proof",
    "income_proof",
  ];
  const appNo = proposal_reference_id;
  const requiredDocuments =
    isLoading === false
      ? doc_required?.map(val => val?.document_type)
      : mainDoc;
  const [documents, setDocuments] = useState({});
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [income, setIncome] = useState();
  const [uploaded, setUploaded] = useState({
    address_proof: false,
    age_proof: false,
    id_proof: false,
    income_proof: false,
    recent_photograph: false,
  });
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    address_proof: `${appNo}_AddressProof_${currentDate}_1.pdf`,
    age_proof: `${appNo}_AgeProof_${currentDate}_2.pdf`,
    id_proof: `${appNo}_IdProof_${currentDate}_3.pdf`,
    income_proof: `${appNo}_IncomeProof_${currentDate}_4.pdf`,
    recent_photograph: `${appNo}_recentPhotograph_${currentDate}_5.pdf`,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEnquiryLoading && data) {
      setIncome(occupationData?.income_proof);
      setDocuments({
        ...documents,
        ["Address Proof"]: {
          ...(documents["Address Proof"] ? documents["Address Proof"] : {}),
          file_type: occupationData?.address_proof,
        },
        ["Age Proof"]: {
          ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
          file_type: occupationData?.age_proof,
        },
        ["ID Proof"]: {
          ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
          file_type: occupationData?.id_proof,
        },
        ["Income Proof"]: {
          ...(documents["Income Proof"] ? documents["Income Proof"] : {}),
          file_type: occupationData?.income_proof,
        },
      });
    }
  }, [isEnquiryLoading, occupationData]);

  useEffect(() => {
    if (
      documents["Age Proof"]?.upload_file === undefined ||
      documents["ID Proof"]?.upload_file === undefined ||
      documents["Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Age Proof"]: true,
        ["ID Proof"]: true,
        ["Address Proof"]: true,
        ["Income Proof"]: true,
      }));
    }
  }, []);

  // Create Preview for Uploaded Document ==============>
  const createPreview = fileInput => {
    const blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });

    return URL.createObjectURL(blob);
  };
  // Removed Uploaded Document ==============>
  const removeDocument = document => {
    setDocuments(prev => ({
      ...prev,
      [document]: {
        file_type: null,
        upload_file: null,
        upload_file_id: null,
        upload_file_name: null,
      },
    }));
    setPreviews(prev => ({ ...prev, [document]: {} }));
    setDisable(prev => ({ ...prev, [document]: true }));
  };
  // Upload Document ==============>
  const onSubmit = async (e, docObject, item, key) => {
    setUploaded(prev => ({
      ...prev,
      [key]: true,
    }));
    dispatch(initDocumentUploadLoading());
    setDocName(prev => ({
      ...prev,
      [key]: `${appNo}_${item?.replace(" ", "")}_${currentDate}_${
        docObject?.upload_file_id
      }.${docObject?.upload_file?.name?.split(".")?.reverse()[0]}`,
    }));
    setDocuments({
      ...documents,
      [item]: {
        ...(documents[item] ? documents[item] : {}),
        ...docObject,
      },
    });
    setPreviews({
      ...previews,
      [item]: {
        ...(previews[item] ? previews[item] : {}),
        blob_file: createPreview(e),
      },
    });
    const newData = await convert(docObject.upload_file);
    const docShortName = docObject?.file_type;
    try {
      await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        upload_file_name: docObject?.upload_file_name,
        upload_file_id: docObject?.upload_file_id,
        upload_file: newData.split(",")[1],
        shortname: docShortName ? docShortName : "recent_photograph",
        docType: "IP",
      });
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      setSuccessBajajDocUpload(successBajajDocUpload + 1);
    } catch (err) {
      setDocName(prev => ({
        ...prev,
        [key]: `${appNo}_${item.replace(" ", "")}_${currentDate}_${
          docObject?.upload_file_id
        }.pdf`,
      }));
      removeDocument(item);
      toast.error(`${item} upload failed`, {
        toastId: `${item}_error`,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    } finally {
      setUploaded(prev => ({
        ...prev,
        [key]: false,
      }));
      dispatch(clearDocumentUploadLoading());
    }
  };

  return (
    <div className="doc-form__wrapper my-4">
      {/* ================================= Age ============================ */}
      {requiredDocuments.includes("age_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Age Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={ageProofOptions}
                label={"Age Proof*"}
                selectedValue={occupationData?.age_proof}
                noSearch
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 1,
                      upload_file_name: docName.age_proof,
                      file_type: occupationData?.age_proof,
                    };
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "",
                    });
                    onSubmit(e, docObject, "Age Proof", `age_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.age_proof}
              </span> */}
            </FileInputWrapper>
            {!documents["Age Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.age_proof}>
                Choose a file
              </label>
            ) : uploaded?.age_proof && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Age Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["Age Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["Age Proof"]?.upload_file && (
                  <FaTrashAlt
                    className={"trash-icon"}
                    onClick={() => removeDocument("Age Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["Age Proof"] && <ErrorMsg>{errors["Age Proof"]}</ErrorMsg>}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "12px" }} />

      {/* ================================= ID ============================ */}
      {requiredDocuments.includes("id_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["ID Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={idProofOption}
                label={"ID Proof*"}
                selectedValue={occupationData?.id_proof}
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 2,
                      upload_file_name: docName.id_proof,
                      file_type: occupationData?.id_proof,
                    };
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "",
                    });
                    onSubmit(e, docObject, "ID Proof", `id_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.id_proof}
              </span> */}
            </FileInputWrapper>

            {!documents["ID Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.id_proof}>
                Choose a file
              </label>
            ) : uploaded?.id_proof && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["ID Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["ID Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["ID Proof"]?.upload_file && (
                  <FaTrashAlt
                    className={"trash-icon"}
                    onClick={() => removeDocument("ID Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["ID Proof"] && <ErrorMsg>{errors["ID Proof"]}</ErrorMsg>}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "10px" }} />

      {/* ================================= Address ============================ */}
      {requiredDocuments.includes("address_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Address Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={addressProofOptions}
                label={"Address Proof*"}
                selectedValue={occupationData?.address_proof}
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Address Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 3,
                      upload_file_name: docName.address_proof,
                      file_type: occupationData?.address_proof,
                    };
                    setErrors({
                      ...errors,
                      ["Address Proof"]: "",
                    });
                    onSubmit(e, docObject, "Address Proof", `address_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.address_proof}
              </span> */}
            </FileInputWrapper>

            {!documents["Address Proof"]?.upload_file ? (
              <label
                className="Choose_file_btn"
                htmlFor={docName.address_proof}
              >
                Choose a file
              </label>
            ) : uploaded?.address_proof && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Address Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["Address Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["Address Proof"]?.upload_file && (
                  <FaTrashAlt
                    className={"trash-icon"}
                    onClick={() => removeDocument("Address Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["Address Proof"] && (
            <ErrorMsg>{errors["Address Proof"]}</ErrorMsg>
          )}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "12px" }} />

      {/* ================================= Income ============================ */}
      {requiredDocuments.includes("income_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Income Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={incomeProofOption}
                label={"Income Proof*"}
                selectedValue={income}
                readOnly={true}
                handleChange={value =>
                  setDocuments({
                    ...documents,
                    ["Income Proof"]: {
                      ...(documents["Income Proof"]
                        ? documents["Income Proof"]
                        : {}),
                      file_type: value,
                    },
                  })
                }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 4,
                      upload_file_name: docName.income_proof,
                      file_type: occupationData?.income_proof,
                    };
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "",
                    });
                    onSubmit(e, docObject, "Income Proof", `income_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.income_proof}
              </span> */}
            </FileInputWrapper>

            {!documents["Income Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.income_proof}>
                Choose a file
              </label>
            ) : uploaded?.income_proof && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Income Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["Income Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["Income Proof"]?.upload_file && (
                  <FaTrashAlt
                    className={"trash-icon"}
                    onClick={() => removeDocument("Income Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["Income Proof"] && (
            <ErrorMsg>{errors["Income Proof"]}</ErrorMsg>
          )}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "12px" }} />
      {requiredDocuments.includes("recent_photograph") && (
        <>
          <h6>Recent Photograph</h6>

          <UploaderBody
            className="col-md-12 select_wrapper"
            active={documents["Recent Photograph"]?.upload_file ? true : false}
          >
            <div className="additional-body">
              <FileInputWrapper className="col-md-6 additional-doc-input">
                <input
                  type="file"
                  name="myfile"
                  id={docName.recent_photograph}
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    if (
                      !checkSize(e, 5) ||
                      !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                    ) {
                      setErrors({
                        ...errors,
                        ["Recent Photograph"]: "File type or size not allowed",
                      });
                    } else {
                      const docObject = {
                        upload_file: e.target.files[0],
                        upload_file_id: 5,
                        upload_file_name: docName.recent_photograph,
                      };
                      setErrors({
                        ...errors,
                        ["Recent Photograph"]: "",
                      });
                      onSubmit(
                        e,
                        docObject,
                        "Recent Photograph",
                        `recent_photograph`,
                      );
                    }
                  }}
                />
                {/* <span className="w-100 file_name_container">
                  {docName.recent_photograph}
                </span> */}
              </FileInputWrapper>

              {!documents["Recent Photograph"]?.upload_file ? (
                <label
                  className="Choose_file_btn"
                  htmlFor={docName.recent_photograph}
                >
                  Choose a file
                </label>
              ) : uploaded?.recent_photograph && isDocumentUploadLoading ? (
                <label className="Choose_file_btn">
                  <ButtonLoader />
                </label>
              ) : (
                <>
                  {previews["Recent Photograph"]?.blob_file && (
                    <a
                      className={`col-4 upload_btn btn `}
                      href={previews["Recent Photograph"]?.blob_file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview
                    </a>
                  )}
                  {documents["Recent Photograph"]?.upload_file && (
                    <FaTrashAlt
                      className={"trash-icon"}
                      onClick={() => removeDocument("Recent Photograph")}
                    />
                  )}
                </>
              )}
            </div>

            {errors["Recent Photograph"] && (
              <ErrorMsg rel>{errors["Recent Photograph"]}</ErrorMsg>
            )}
          </UploaderBody>
        </>
      )}
      {/* ================================= Additional ================================= */}

      {/* ================================= Additional Buttons ================================= */}

      <HR style={{ marginTop: "12px" }} />
      {/* {proposalData?.data["Bank Details"]?.renewal_deposit === "NACH" && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["NACH Form"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={[
                  { code: "NACH", display_name: "Soft Copy of NACH Form" },
                ]}
                label={"NACH Form"}
                selectedValue={"NACH"}
                noSearch
                readOnly={true}
                // handleChange={value =>
                //   setDocuments({
                //     ...documents,
                //     ["NACH Form"]: {
                //       ...(documents["NACH Form"] ? documents["NACH Form"] : {}),
                //       file_type: value,
                //     },
                //   })
                // }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.nach_form}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["NACH Form"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 5,
                      upload_file_name: docName.nach_form,
                      file_type: occupationData?.nach_form,
                    };
                    setErrors({
                      ...errors,
                      ["NACH Form"]: "",
                    });
                    onSubmit(e, docObject, "NACH Form", `nach_form`);
                  }
                }}
              />
              <span className="w-100 file_name_container">
                {docName.nach_form}
              </span>
            </FileInputWrapper>

            {!documents["NACH Form"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.nach_form}>
                Choose a file
              </label>
            ) : uploaded?.nach_form && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["NACH Form"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["NACH Form"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["NACH Form"]?.upload_file && (
                  <FaTrashAlt
                    className={"trash-icon"}
                    onClick={() => removeDocument("NACH Form")}
                  />
                )}
              </>
            )}
          </div>
          {errors["NACH Form"] && <ErrorMsg>{errors["NACH Form"]}</ErrorMsg>}
        </UploaderBody>
      )} */}
    </div>

    // ================================= FOOTER ============================
  );
};

export default BajajDocumentUpload;
