import styled from "styled-components";
import landing from "../../../assets/images/landing.png";
import * as mq from "../../../styles/mediaQueries";

export const InputPageContainerStyled = styled.div`
  background-image: url(${props => (props.bg ? props.bg : landing)});
  background-attachment: fixed;
  background-position: center center;
  max-height: fit-content;
  width: 100%;
  min-height: calc(100vh - 60px);
  background-size: cover;
  background-repeat: no-repeat;
`;
export const InputPageWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-top: 30px;
  width: 100%;
  ${mq.sm} {
    display: block;
    padding-top: 20px;
  }
  ${mq.md} {
    display: block;
    padding-top: 20px;
  }
`;
