/* eslint-disable no-useless-computed-key */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "styled-components/macro";
import ButtonLoader from "../../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload-page/docUtils";
import { uploadDoc } from "../../../../DocumentUpload-page/serviceApi";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
  useGetEnquiriesQuery,
  useGetProposalDataQuery,
} from "../../../../riders/services/rider";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import {
  ErrorMsg,
  FileInputWrapper,
  UploadWrap,
  UploaderBody,
} from "../../../ProposalPage.style";
import Select from "../../../components/Select";
import Title from "../../../components/Title";
import { addressProofOptions } from "../../../options/tata/addressOptions";
import {
  bankproofOptions,
  incomeProofOptions,
} from "../../../options/tata/eduAndOccupOptions";
import {
  age_proof,
  identityProofOptions,
} from "../../../options/tata/proposerOptions";
import { FaTrashAlt } from "react-icons/fa";

const TataDocumentUpload = ({ setDisable }) => {
  const dispatch = useDispatch();
  const { isDocumentUploadLoading } = useSelector(state => state.proposal);
  const { data: cartData } = useGetCartQuery();
  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data?.proposal_reference_id;
  const { data: proposalData, isLoading: isProposalDataLoading } =
    useGetProposalDataQuery();
  const { data: enquiryData } = useGetEnquiriesQuery();
  const [errors, setErrors] = useState({});
  const [business, setBusiness] = useState(false);
  const [documents, setDocuments] = useState({});
  const [previews, setPreviews] = useState({});
  const [uploaded, setUploaded] = useState({});
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    insured_photo: `${appNo}_InsuredPhoto_${currentDate}_1.pdf`,
    id_proof: `${appNo}_IdProof_${currentDate}_2.pdf`,
    age_proof: `${appNo}_AgeProof_${currentDate}_3.pdf`,
    address_proof: `${appNo}_ResidentialAddressProof_${currentDate}_4.pdf`,
    income_proof: `${appNo}_IncomeProof_${currentDate}_5.pdf`,
    business_cert: `${appNo}_BusinessCertificate_${currentDate}_6.pdf`,
    other_doc: `${appNo}_OtherProof_${currentDate}_7.pdf`,
    bank_proof: `${appNo}_BankProof_${currentDate}_8.pdf`,
  });

  useEffect(() => {
    if (
      enquiryData?.data?.input?.selected_product?.occupation ===
      "business_owner"
    ) {
      setBusiness(true);
    } else setBusiness(false);
  }, [enquiryData]);

  useEffect(() => {
    if (!isProposalDataLoading && proposalData) {
      setDocuments({
        ...documents,
        ["Identity Proof"]: {
          ...(documents["Identity Proof"] ? documents["Identity Proof"] : {}),
          file_type:
            proposalData?.data["Personal Details"]?.identity_proof_type,
          doc_type: "FORM",
          doc_id: proposalData?.data["Personal Details"]?.identity_proof_type,
        },
        ["Age Proof"]: {
          ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
          file_type: proposalData?.data["Personal Details"]?.age_proof_type,
          doc_type: "FORM",
          doc_id: proposalData?.data["Personal Details"]?.age_proof_type,
        },
        ["Residential Address Proof"]: {
          ...(documents["Residential Address Proof"]
            ? documents["Residential Address Proof"]
            : {}),
          file_type: proposalData?.data["Address Details"]?.address_proof,
          doc_type: "FORM",
          doc_id: proposalData?.data["Address Details"]?.address_proof,
        },
        ["Income Proof"]: {
          ...(documents["Income Proof"] ? documents["Income Proof"] : {}),
          file_type:
            proposalData?.data["Education and Occupation Details"]
              ?.income_proof,
          doc_type: "FORM",
          doc_id:
            proposalData?.data["Education and Occupation Details"]
              ?.income_proof,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalDataLoading, proposalData]);

  useEffect(() => {
    if (
      documents["Insured Photo"]?.upload_file === undefined ||
      documents["Residential Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined ||
      documents["Age Proof"]?.upload_file === undefined ||
      documents["Identity Proof"]?.upload_file === undefined ||
      documents["Bank Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Insured Photo"]: true,
        ["Age Proof"]: true,
        ["Residential Address Proof"]: true,
        ["Income Proof"]: true,
        ["Identity Proof"]: true,
        ["Bank Proof"]: true,
      }));
    }
    if (
      business &&
      documents["Business Registration Certificate"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Business Registration Certificate"]: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create Preview for Uploaded Document ==============>
  const createPreview = fileInput => {
    const blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });
    return URL.createObjectURL(blob);
  };
  // Removed Uploaded Document ==============>
  const removeDocument = document => {
    setDocuments(prev => ({
      ...prev,
      [document]: {
        ...documents?.[document],
        upload_file: null,
        upload_file_id: null,
        upload_file_name: null,
        doc_id: null,
        doc_type: null,
        doc_format: null,
      },
    }));
    setPreviews(prev => ({ ...prev, [document]: {} }));
    setDisable(prev => ({ ...prev, [document]: true }));
  };
  // Upload Document ==============>
  const onSubmit = async (e, docObject, item, key) => {
    setUploaded(prev => ({
      ...prev,
      [key]: true,
    }));
    dispatch(initDocumentUploadLoading());
    setDocName(prev => ({
      ...prev,
      [key]: `${appNo}_${item?.replace(" ", "")}_${currentDate}_${
        docObject?.upload_file_id
      }.${docObject?.upload_file?.name?.split(".")?.reverse()[0]}`,
    }));
    setDocuments({
      ...documents,
      [item]: {
        ...(documents[item] ? documents[item] : {}),
        ...docObject,
      },
    });
    setPreviews({
      ...previews,
      [item]: {
        ...(previews[item] ? previews[item] : {}),
        blob_file: createPreview(e),
      },
    });
    const newData = await convert(docObject.upload_file);
    try {
      await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        application_no: appNo,
        upload_file_name: docObject?.upload_file_name,
        upload_file_id: docObject?.upload_file_id,
        upload_file: newData.split(",")[1],
        doc_id: docObject?.doc_id,
        doc_type: docObject?.doc_type,
        doc_format: docObject?.doc_format,
      });
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    } catch (err) {
      setDocName(prev => ({
        ...prev,
        [key]: `${appNo}_${item.replace(" ", "")}_${currentDate}_${
          docObject?.upload_file_id
        }.pdf`,
      }));
      removeDocument(item);
      toast.error(`${item} Upload Failed!`, {
        toastId: `${item}_error`,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    } finally {
      setUploaded(prev => ({
        ...prev,
        [key]: false,
      }));
      dispatch(clearDocumentUploadLoading());
    }
  };

  return (
    <div className="doc-form__wrapper my-4">
      {/* ================================= Insured Photo ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Insured Photo"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Title label={"Insured Photo"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.insured_photo} */}
              <input
                type="file"
                name="myfile"
                id={docName.insured_photo}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Insured Photo"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 1,
                      upload_file_name: `${appNo}_InsuredPhoto_${currentDate}_1.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_id: "1022010142",
                      doc_type: "PHOTOGRAPH",
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                    };
                    setErrors({ ...errors, ["Insured Photo"]: "" });
                    onSubmit(e, docObject, "Insured Photo", `insured_photo`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Insured Photo"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.insured_photo}>
              Choose a file
            </label>
          ) : uploaded?.insured_photo && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Insured Photo"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Insured Photo"]?.blob_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Insured Photo"]?.upload_file && (
                <FaTrashAlt
                  className={"trash-icon"}
                  onClick={() => removeDocument("Insured Photo")}
                />
              )}
            </>
          )}
        </div>
        {errors["Insured Photo"] && (
          <ErrorMsg rel>{errors["Insured Photo"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= ID ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Identity Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={identityProofOptions}
              label={"Identity Proof"}
              selectedValue={documents["Identity Proof"]?.file_type}
              noSearch
              readOnly
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Identity Proof"]: {
              //       ...(documents["Identity Proof"]
              //         ? documents["Identity Proof"]
              //         : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.id_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Identity Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 2,
                      upload_file_name: `${appNo}_IdentityProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_type: "FORM",
                      doc_id: documents["Identity Proof"]?.file_type,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                    };
                    setErrors({ ...errors, ["Identity Proof"]: "" });
                    onSubmit(e, docObject, "Identity Proof", `id_proof`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>
          {!documents["Identity Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.id_proof}>
              Choose a file
            </label>
          ) : uploaded?.id_proof && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Identity Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Identity Proof"]?.blob_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Identity Proof"]?.upload_file && (
                <FaTrashAlt
                  className={"trash-icon"}
                  onClick={() => removeDocument("Identity Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Identity Proof"] && (
          <ErrorMsg rel>{errors["Identity Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Age ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Age Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={age_proof}
              label={"Age Proof*"}
              selectedValue={documents["Age Proof"]?.file_type}
              noSearch
              readOnly
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Age Proof"]: {
              //       ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.age_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 3,
                      upload_file_name: `${appNo}_AgeProof_${currentDate}_3.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_type: "FORM",
                      doc_id: documents["Age Proof"]?.file_type,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                    };
                    setErrors({ ...errors, ["Age Proof"]: "" });
                    onSubmit(e, docObject, "Age Proof", `age_proof`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Age Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.age_proof}>
              Choose a file
            </label>
          ) : uploaded?.age_proof && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Age Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Age Proof"]?.blob_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Age Proof"]?.upload_file && (
                <FaTrashAlt
                  className={"trash-icon"}
                  onClick={() => removeDocument("Age Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Age Proof"] && <ErrorMsg rel>{errors["Age Proof"]}</ErrorMsg>}
      </UploaderBody>

      {/* ================================= Address ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={
          documents["Residential Address Proof"]?.upload_file ? true : false
        }
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={addressProofOptions}
              label={"Residential Address Proof"}
              selectedValue={documents["Residential Address Proof"]?.file_type}
              noSearch
              readOnly
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Residential Address Proof"]: {
              //       ...(documents["Residential Address Proof"]
              //         ? documents["Residential Address Proof"]
              //         : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.address_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Residential Address Proof"]:
                        "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 4,
                      upload_file_name: `${appNo}_ResidentialAddressProof_${currentDate}_4.${
                        e.target.files[0]?.name.split(".")?.reverse()[0]
                      }`,
                      doc_type: "FORM",
                      doc_id: documents["Residential Address Proof"]?.file_type,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                    };
                    setErrors({ ...errors, ["Residential Address Proof"]: "" });
                    onSubmit(
                      e,
                      docObject,
                      "Residential Address Proof",
                      `address_proof`,
                    );
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Residential Address Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.address_proof}>
              Choose a file
            </label>
          ) : uploaded?.address_proof && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Residential Address Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Residential Address Proof"]?.blob_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Residential Address Proof"]?.upload_file && (
                <FaTrashAlt
                  className={"trash-icon"}
                  onClick={() => removeDocument("Residential Address Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Residential Address Proof"] && (
          <ErrorMsg rel>{errors["Residential Address Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Income ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Income Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={incomeProofOptions}
              label={"Income Proof*"}
              selectedValue={documents["Income Proof"]?.file_type}
              noSearch
              readOnly
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Income Proof"]: {
              //       ...(documents["Income Proof"]
              //         ? documents["Income Proof"]
              //         : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>

          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.income_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 5,
                      upload_file_name: `${appNo}_IncomeProof_${currentDate}_5.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_type: "FORM",
                      doc_id: documents["Income Proof"]?.file_type,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                    };
                    setErrors({ ...errors, ["Income Proof"]: "" });
                    onSubmit(e, docObject, "Income Proof", `income_proof`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Income Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.income_proof}>
              Choose a file
            </label>
          ) : uploaded?.income_proof && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Income Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Income Proof"]?.blob_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Income Proof"]?.upload_file && (
                <FaTrashAlt
                  className={"trash-icon"}
                  onClick={() => removeDocument("Income Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Income Proof"] && (
          <ErrorMsg rel>{errors["Income Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Business Registration Certificate ================================= */}
      {business ? (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={
            documents["Business Registration Certificate"]?.upload_file
              ? true
              : false
          }
        >
          <div className="upload__body">
            <UploadWrap>
              <Title label={"Business Registration Certificate"} />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <span className="w-100 file_name_container">
                {/* {docName.business_cert} */}
                <input
                  type="file"
                  name="myfile"
                  id={docName.business_cert}
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    if (
                      e.target.files[0] &&
                      (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                    ) {
                      setErrors({
                        ...errors,
                        ["Business Registration Certificate"]:
                          "File type or size not allowed",
                      });
                    } else {
                      const docObject = {
                        upload_file: e.target.files[0],
                        upload_file_id: 6,
                        upload_file_name: `${appNo}_BusinessRegistrationCertificate_${currentDate}_6.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                        doc_id: "1046010778",
                        doc_type: "FORM",
                        doc_format: e.target.files[0]?.name
                          ?.split(".")
                          ?.reverse()[0],
                      };
                      setErrors({
                        ...errors,
                        ["Business Registration Certificate"]: "",
                      });
                      onSubmit(
                        e,
                        docObject,
                        "Business Registration Certificate",
                        `business_cert`,
                      );
                    }
                  }}
                />
              </span>
            </FileInputWrapper>
            {!documents["Business Registration Certificate"]?.upload_file ? (
              <label
                className="Choose_file_btn"
                htmlFor={docName.business_cert}
              >
                Choose a file
              </label>
            ) : uploaded?.business_cert && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Business Registration Certificate"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={
                      previews["Business Registration Certificate"]?.blob_file
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["Business Registration Certificate"]
                  ?.upload_file && (
                  <FaTrashAlt
                    className={"trash-icon"}
                    onClick={() =>
                      removeDocument("Business Registration Certificate")
                    }
                  />
                )}
              </>
            )}
          </div>
          {errors["Business Registration Certificate"] && (
            <ErrorMsg rel>
              {errors["Business Registration Certificate"]}
            </ErrorMsg>
          )}
        </UploaderBody>
      ) : (
        <></>
      )}

      {/* ================================= Bank Proof ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Bank Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={bankproofOptions}
              label={"Bank Proof"}
              selectedValue={documents["Bank Proof"]?.file_type}
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Bank Proof"]: {
                    ...(documents["Bank Proof"] ? documents["Bank Proof"] : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          {documents["Bank Proof"]?.file_type && (
            <>
              <FileInputWrapper className="col-md-6">
                <span className="w-100 file_name_container">
                  {/* {docName.bank_proof} */}
                  <input
                    type="file"
                    name="myfile"
                    id={docName.bank_proof}
                    onClick={e => {
                      e.target.value = null;
                    }}
                    onChange={e => {
                      if (
                        e.target.files[0] &&
                        (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                      ) {
                        setErrors({
                          ...errors,
                          ["Bank Proof"]: "File type or size not allowed",
                        });
                      } else {
                        const docObject = {
                          upload_file: e.target.files[0],
                          upload_file_id: 8,
                          upload_file_name: `${appNo}_BankProof_${currentDate}_8.${
                            e.target.files[0]?.name?.split(".")?.reverse()[0]
                          }`,
                          doc_type: "FORM",
                          doc_id: documents["Bank Proof"]?.file_type,
                          doc_format: e.target.files[0]?.name
                            ?.split(".")
                            ?.reverse()[0],
                        };
                        setErrors({ ...errors, ["Bank Proof"]: "" });
                        onSubmit(e, docObject, "Bank Proof", `bank_proof`);
                      }
                    }}
                  />
                </span>
              </FileInputWrapper>
              {!documents["Bank Proof"]?.upload_file ? (
                <label
                  className="Choose_file_btn"
                  htmlFor={docName?.bank_proof}
                >
                  Choose a file
                </label>
              ) : uploaded?.bank_proof && isDocumentUploadLoading ? (
                <label className="Choose_file_btn">
                  <ButtonLoader />
                </label>
              ) : (
                <>
                  {previews["Bank Proof"]?.blob_file && (
                    <a
                      className={`col-4 upload_btn btn `}
                      href={previews["Bank Proof"]?.blob_file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview
                    </a>
                  )}
                  {documents["Bank Proof"]?.upload_file && (
                    <FaTrashAlt
                      className={"trash-icon"}
                      onClick={() => removeDocument("Bank Proof")}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
        {errors["Bank Proof"] && (
          <ErrorMsg rel>{errors["Bank Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Other Documents ================================= */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Other Document"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            {" "}
            <Title label={"Any Other Documents"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.other_doc} */}
              <input
                type="file"
                name="myfile"
                id={docName.other_doc}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Other Document"]: "File type or size not allowed",
                    });
                  } else {
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 7,
                      upload_file_name: `${appNo}_OtherDocument_${currentDate}_7.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_id: "1021010193",
                      doc_type: "FORM",
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                    };
                    setErrors({ ...errors, ["Other Document"]: "" });
                    onSubmit(e, docObject, "Other Document", `other_doc`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Other Document"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.other_doc}>
              Choose a file
            </label>
          ) : uploaded?.other_doc && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Other Document"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Other Document"]?.blob_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Other Document"]?.upload_file && (
                <FaTrashAlt
                  className={"trash-icon"}
                  onClick={() => removeDocument("Other Document")}
                />
              )}
            </>
          )}
        </div>
        {errors["Other Document"] && (
          <ErrorMsg rel>{errors["Other Document"]}</ErrorMsg>
        )}
      </UploaderBody>
    </div>
  );
};

export default TataDocumentUpload;
