/**
 * The function `getAnnualIncome` takes an annual income as input and returns a formatted string
 * representation of the income in either Crores or Lakhs.
 * @returns a formatted string representing the annual income. The format is either in Crores (Cr) or
 * Lakhs (Lac) depending on the length of the annual income.
 */
export const getAnnualIncome = annualIncome => {
  const length = String(annualIncome).length;
  if (length >= 8) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 7) + "e+2") + "e-2"
    )} Cr`;
  }
  if (length >= 6) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 5) + "e+2") + "e-2"
    )} Lac`;
  }
};
