import { Modal } from "react-bootstrap";
import "styled-components/macro";
import CancelCircularIcon from "../../assets/svg-icons/CancelCircular";

const CardModal = ({
  show,
  handleClose,
  bodyContent,
  heading,
  footerContent,
  maxHeight,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      css={`
        .modal-content {
          width: 92%;
          max-height: 600px;
          border-radius: 10px !important;
        }
      `}
    >
      <Modal.Header className="d-flex position-relative">
        <span
          style={{
            width: "5px",
            height: "30px",
            background: "#ffcb00",
            position: "absolute",
            left: "0",
            top: "20px",
            borderRadius: "0 50px 50px 0",
          }}
        ></span>
        <Modal.Title
          style={{
            fontSize: "20px",
            color: "#233D63",
          }}
          className="p-lg"
        >
          <div className="container">{heading}</div>
        </Modal.Title>

        <CancelCircularIcon width="30" onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body
        style={{
          fontSize: "14px",
        }}
        css={`
          max-height: ${maxHeight};
          overflow: ${maxHeight && "auto"};

          ::-webkit-scrollbar {
            display: block;
            width: 6px;
          }

          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #dbdad5;
            outline: none;
            display: block;
          }
          ::-webkit-scrollbar-thumb:hover {
            background-color: #babac0;
          }
        `}
      >
        {bodyContent}
      </Modal.Body>
      <Modal.Footer onClick={() => handleClose()}>{footerContent}</Modal.Footer>
    </Modal>
  );
};

export default CardModal;
