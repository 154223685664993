import CompareFilterDropdown from "./CompareFilterDropdown";
import { Box, Button, Grid } from "@mui/material";
import refresh from "../../../assets/images/refresh.png";
import {
  resetStates,
  setCoverTillAge,
  setFrequency,
  setInsuranceCompany,
  setPayTillAge,
  setProduct,
  setSumAssured,
} from "../slices/NewCompare.slice";

import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useNewCompare from "../hooks/useNewCompare";

const CompareFilters = () => {
  const {
    insuranceCompany,
    product,
    sumAssured,
    coverTillAge,
    payTillAge,
    frequency,
  } = useSelector(state => state?.newCompare);
  const {
    termCompaniesList,
    productsList,
    sumAssuredList,
    coverTillAgeList,
    payTillAgeList,
    frequencyList,
    isCompany,
    isProduct,
    isSumAssured,
    isCoverTillAge,
    isPayTillAge,
    isFrequency,
    isPremiumLoading,
    isProductLoading,
    isFilterLoading,
    handleAddToList,
    checkValues,
  } = useNewCompare();
  const dispatch = useDispatch();
  // Get data from localStorage
  const storedData = localStorage.getItem("allLoadedIC");

  // Parse the JSON string back to an object
  const allCompanyAlias = JSON.parse(storedData);
  const filteredArrayOfIcs = termCompaniesList.filter(company =>
    allCompanyAlias.includes(company.code),
  );

  return (
    <>
      <FilterBoxStyled className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12 ">
            <StyledGrid container>
              <GridChild item xs={2}>
                <p>Insurance Company</p>
                <CompareFilterDropdown
                  options={filteredArrayOfIcs}
                  isReset={isCompany}
                  previousSelectedValue={insuranceCompany?.code}
                  onChangeHandler={setInsuranceCompany}
                  showTooltip={true}
                />
              </GridChild>
              <GridChild item xs={2}>
                <p>Product Name</p>
                {isProductLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={productsList}
                      isReset={isProduct}
                      previousSelectedValue={product?.code}
                      onChangeHandler={setProduct}
                      showTooltip={true}
                    />
                  </>
                )}
              </GridChild>
              <GridChild item xs={1.6}>
                <p>Sum Assured</p>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={isProduct ? sumAssuredList : []}
                      isReset={isSumAssured}
                      isProduct={isProduct}
                      previousSelectedValue={sumAssured?.code}
                      onChangeHandler={setSumAssured}
                    />
                  </>
                )}
              </GridChild>
              <GridChild item xs={1.6}>
                <p>Cover till age</p>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={isProduct ? coverTillAgeList : []}
                      isReset={isCoverTillAge}
                      previousSelectedValue={coverTillAge?.code}
                      onChangeHandler={setCoverTillAge}
                    />
                  </>
                )}
              </GridChild>
              <GridChild item xs={1.6}>
                <p>Pay till age</p>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <CompareFilterDropdown
                    options={isProduct ? payTillAgeList : []}
                    isReset={isPayTillAge}
                    isProduct={isProduct}
                    previousSelectedValue={payTillAge?.code}
                    onChangeHandler={setPayTillAge}
                  />
                )}
              </GridChild>
              <GridChild item xs={1.6}>
                <p>Frequency</p>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <CompareFilterDropdown
                    options={isProduct ? frequencyList : []}
                    isReset={isFrequency}
                    isProduct={isProduct}
                    isDisable={payTillAge?.code === 1 ? true : false}
                    previousSelectedValue={frequency?.code}
                    onChangeHandler={setFrequency}
                  />
                )}
              </GridChild>

              <GridChildButtonWrap item xs={1.6}>
                <ButtonStyled
                  disabled={checkValues()}
                  onClick={handleAddToList}
                  variant="contained"
                  fullWidth
                >
                  {isPremiumLoading ? <ButtonLoader /> : "Add to list"}
                </ButtonStyled>
                <img
                  src={refresh}
                  alt="Refresh"
                  onClick={() => dispatch(resetStates())}
                />
              </GridChildButtonWrap>
            </StyledGrid>
          </div>
        </div>
      </FilterBoxStyled>
    </>
  );
};

export default CompareFilters;

const FilterBoxStyled = styled(Box)`
  margin-top: 20px;
  box-shadow: 6px 12px 20px #e7f0f7;
  padding: 10px 0;
  border-radius: 4px;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
`;

const GridChild = styled(Grid)`
  border-right: 2px solid #e8ebee;
  padding: 0 15px 0 15px;
  border: ${props => props.border};

  p {
    margin: 0px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
  }
`;

const GridChildButtonWrap = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 10px;
    height: 15px;
    width: 15px;
    padding: 2px;
    cursor: pointer;
  }
`;

export const ButtonStyled = styled(Button)`
  text-transform: none !important;
  width: 90px !important;
  font-size: 0.9rem !important;
  white-space: nowrap !important;
  background-color: ${props =>
    props.disabled ? "rgba(0,0,0,0.3)" : "var(--primary-color)"};
  color: #fff !important;
`;
