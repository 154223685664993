import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../Landing/input.slice";
import { useInputFormsHook } from "../../hooks";
import { preferredICFormSchema } from "./validation";
import { useHeaderData } from "../../../../components/Header/hooks/useHeaderData";
import { toast } from "react-toastify";
const usePreferredICForm = (current, setCurrent) => {
  const dispatch = useDispatch();
  const { setPreferredICFormData } = useInputFormsHook();
  const { sellerData } = useHeaderData();
  const { handleSubmit, setValue, watch } = useForm({
    mode: "onBlur",
    resolver: yupResolver(preferredICFormSchema),
  });
  const { tenant } = useSelector(state => state.frontendBoot?.frontendData);

  const url = tenant?.frontend_domains?.filter(
    item => item?.source === "login_url",
  )?.[0]?.base_url;
  const toaster = () => {
    toast.error(
      sellerData?.message
        ? sellerData?.message
        : "Token Expired, Login Again!!",
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        onClose: () => {
          window.open(url, "_self");
        },
      },
    );
  };

  const onSubmit = data => {
    if (sellerData?.status !== false) {
      dispatch(setData({ ...data }));
      setCurrent(current + 1);

      setPreferredICFormData(data);
    } else {
      toaster();
    }
  };
  const onSkip = () => {
    if (sellerData?.status !== false) {
      setValue("preferred_ic", null);
      setCurrent(prev => prev + 1);
    } else {
      toaster();
    }
  };
  return {
    onSubmit,
    handleSubmit,
    watch,
    onSkip,
    setValue,
  };
};

export default usePreferredICForm;
