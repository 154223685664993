import { useDispatch, useSelector } from "react-redux";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { annualIncome, toWords } from "../../../../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { eligibilityFormSchema } from "./validation";
import { setData } from "../../../Landing/input.slice";
import { useInputFormsHook, useInputPageHook } from "../../hooks";
import moment from "moment";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { patchEnquiries } from "../../../product-page/Filters/serviceApi";

const useEligibilityForm = (current, setCurrent) => {
  const {
    termSuitabilityFlagStatus,
    termAgeBand,
    termAnnualIncome,
    termOccupation,
    termEducation,
  } = useInputPageHook();

  const { getEligibilityFormData, setEligibilityFormData } =
    useInputFormsHook();

  const { data } = useSelector(state => state.inputForm);
  const patchData = useSelector(state => state.inputForm?.data);
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(eligibilityFormSchema),
    defaultValues: getEligibilityFormData(),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  const education = watch("education");
  const occupation = watch("occupation");
  const term_annual_income = watch("term_annual_income");
  let age = moment().diff(moment(data.dob, "YYYY-MM-DD"), "years") || 18;
  const [ageBand] = termAgeBand.filter(
    item => item.min <= age && item.max >= age,
  );

  useEffect(() => {
    let min = termAnnualIncome[occupation + "+" + education]
      ? termAnnualIncome[occupation + "+" + education][ageBand.band_name]
      : 2.5;
    if (min * 100000 > term_annual_income) {
      setValue("term_annual_income", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageBand, education, occupation]);

  const occupationOptions = termOccupation.map(item => ({
    code: item.code,
    display_name: item.display_name,
  }));

  const educationOptions = termEducation.map(item => ({
    code: item.code,
    display_name: item.display_name,
  }));

  const termAnnualIncomeOptions = annualIncome(
    termAnnualIncome[occupation + "+" + education]
      ? termAnnualIncome[occupation + "+" + education][ageBand.band_name]
      : 2.5,
  ).map(item => ({
    code: item,
    display_name: toWords(item),
  }));

  const viewQuotesHandler = async e => {
    e.preventDefault();
    await patchEnquiries({ input: { ...patchData } }).then(() => {
      history.push(`/quote?enquiryId=${enquiryId}`);
    });
  };

  const onSubmit = data => {
    dispatch(setData(data));
    setEligibilityFormData(data, term_annual_income);
    if (termSuitabilityFlagStatus === "Yes") setCurrent(current + 1);
    else setCurrent(current + 2);
  };

  return {
    handleSubmit,
    onSubmit,
    errors,
    setValue,
    occupationOptions,
    occupation,
    educationOptions,
    education,
    termAnnualIncomeOptions,
    term_annual_income,
    viewQuotesHandler,
  };
};

export default useEligibilityForm;
