import styled, { css } from "styled-components/macro";
import { BackButton } from "../../components";
import { FaShare, FaShareAlt } from "react-icons/fa";
import CancelCircularIcon from "../../assets/svg-icons/CancelCircular";
import ShareOnMailModal from "../../components/modal/ShareOnMail-component"; // StyledInput, // ModalPopup,
import { Modal } from "react-bootstrap";
// import { Collapse } from "react-bootstrap";
import React, { useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { MdMailOutline } from "react-icons/md";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import whatsAppImg from "../../assets/images/whatsapp.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNumberInput } from "../../utils";
import { toast } from "react-toastify";
import * as mq from "../../styles/mediaQueries";
import useOutsideClickRef from "../../custom-hooks/useOutsideClickRef";
import { OverlayTrigger } from "react-bootstrap";
import renderTooltip from "../../components/Tooltip/Tooltip";
import { mobileRegex } from "../ProposalPage/inputUtils/regex";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import { patchEnquiries } from "../product-page/Filters/serviceApi";
import { useSelector } from "react-redux";
import { selectAddedRiders } from "../product-page/Quotes/quotes.slice";

// import ButtonLoader from "../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import TextInput from "../product-page/EligibilityCard/components/TextInput/TextInput";
import { allowOnlyNumbers } from "../ProposalPage/inputUtils/onInput";
import ShareComparePopup from "./components/ShareComparePopup";

function CompareHeader() {
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = React.useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const ref = React.useRef();
  const matches = useMediaQuery(mq.smForHook);
  useOutsideClickRef(ref, () => {
    setShow(false);
  });
  return (
    <StyledHeaderWrap ref={ref}>
      {!matches && (
        <StyledBackButton
          toPage="/quote"
          css={`
            ${mq.sm} {
              top: 5px;
              left: 5vw;
              width: 25px;
              height: 25px;
              font-size: 20px;
            }
          `}
        />
      )}
      <div
        css={`
          position: fixed;
          top: 4.3em;
          right: 2em;
          ${mq.sm} {
            top: 1em;
            z-index: 100;
          }
        `}
      >
        <div
          css={`
            position: relative;  
            ${mq.sm}{
              display: none;
            }
            }
          `}
        >
          <OverlayTrigger
            trigger="hover"
            placement="top"
            delay={{ show: 0, hide: 300 }}
            overlay={renderTooltip({
              desc: "Share Compare Page",
            })}
          >
            <StyledShareButtonMain
              onClick={() => setShowPopup(true)}
              showPopup={showPopup}
            >
              <FaShareAlt />
            </StyledShareButtonMain>
          </OverlayTrigger>

          <ShareComparePopup
            showPopup={showPopup}
            handleClose={() => {
              setShowPopup(false);
            }}
          />

          {/* <Collapse in={show}>
            <div className="p-0 m-0" id="example-collapse-text">
              <ShareButtonsWrap>
                <WhatsAppShareButton />
                <ShareButton />
                <CopyToClipboardButton />
              </ShareButtonsWrap>
            </div>
          </Collapse> */}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </StyledHeaderWrap>
  );
}

// const ShareButtonsWrap = styled.div`
//   display: flex;
//   ${"" /* gap: 1em; */}
//   flex-direction: column;
//   transition: all 2s ease;
// `;

export function ShareButton() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 100 }}
        overlay={renderTooltip({
          desc: "Share via Mail",
        })}
      >
        <StyledShareButton onClick={togglePopup}>
          <MdMailOutline />
        </StyledShareButton>
      </OverlayTrigger>
      <ShareOnMailModal show={showPopup} handleClose={togglePopup} />
    </>
  );
}
export function CopyToClipboardButton() {
  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(window.location).then(() => {
      toast.success("Copied To Clipboard!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 0, hide: 100 }}
      overlay={renderTooltip({
        desc: "Copy to Clipboard",
      })}
    >
      <StyledShareButton onClick={copyUrlToClipboard}>
        <HiOutlineClipboardCheck />
      </StyledShareButton>
    </OverlayTrigger>
  );
}

export function WhatsAppShareButton() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 100 }}
        overlay={renderTooltip({
          desc: "Share via Whatsapp",
        })}
      >
        <StyledShareButton onClick={togglePopup}>
          <SiWhatsapp />
        </StyledShareButton>
      </OverlayTrigger>
      <WhatsAppShareModal show={showPopup} handleClose={togglePopup} />
    </>
  );
}

const schema = yup.object({
  mobile: yup
    .string()
    .required("Please enter mobile number")
    .matches(mobileRegex, "Enter a valid mobile number"),
});

function WhatsAppShareModal({ show, handleClose }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  // const [showError, setShowError] = useState(false);
  const addedRiders = useSelector(selectAddedRiders);
  const [mobile, setMobile] = useNumberInput("");

  const onSubmit = () => {
    // const form = document.createElement("form");
    // const phone = document.createElement("input");
    // phone.setAttribute("value", data.mobile);
    // form.appendChild(phone);
    // form.setAttribute("target", "_blank");
    // form.setAttribute("action", `https://api.whatsapp.com/send?phone=${mobile}`);
    // document.body.appendChild(form);
    // form.submit();
    // document.body.removeChild(form);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <CancelCircularIcon
        width="27"
        onClick={() => handleClose()}
        style={{
          position: "absolute",
          right: "-10px",
          top: "-10px",
        }}
      />
      <Modal.Header>
        <Modal.Title
          css={`
            font-size: 1.2rem;

            font-family: Inter;
            font-weight: 500;
            padding-right: 2.67em;

            ${mq.sm} {
              font-size: 0.9rem;
            }
            &::before {
              content: "";
              position: absolute;
              height: 30px;
              width: 10px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
          `}
        >
          Share Compared Plans
        </Modal.Title>
      </Modal.Header>

      <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body
          css={`
            padding: 1rem 5rem;
            ${mq.sm} {
              padding: 0 1rem 2rem;
            }
          `}
        >
          <img src={whatsAppImg} alt="MAIL_IMG" className="mx-auto d-block" />
          <TextInput
            label="Enter Your Number"
            {...register("mobile")}
            onInput={allowOnlyNumbers}
            inputProps={{ maxLength: 10, inputMode: "numeric" }}
            error={errors.mobile && errors.mobile.message}
            value={mobile}
            onChange={evt => {
              setMobile(evt);
              register("mobile").onChange(evt);
            }}
          />
          {/* <StyledInput
            type="tel"
            inputMode="numeric"
            maxLength={10}
            placeholder="Enter Your Number"
            className="w-75 box-shadow rounded "
            {...register("mobile")}
            value={mobile}
            onChange={evt => {
              setMobile(evt);
              register("mobile").onChange(evt);
            }}
          />
          {errors.mobile && (
            <p style={{ color: "red" }}>{errors.mobile.message}</p>
          )} */}
        </Modal.Body>
        <Modal.Footer
          css={`
            justify-content: center;
          `}
        >
          <a
            href={`https://api.whatsapp.com/send?phone=91${mobile}&text=${window.location.href}`}
            target="_blank"
            // type="submit"

            onClick={async () => {
              // eslint-disable-next-line no-unused-vars
              const { data } = await patchEnquiries({
                input: {
                  selected_addons: addedRiders,
                },
              });
            }}
            className="btn btn-primary "
            style={{
              fontSize: "12px",
              padding: "12px 36px",
              pointerEvents:
                errors?.mobile || mobile === undefined ? "none" : "auto",
            }}
            rel="noreferrer"
          >
            Share <FaShare className="icon-left" />
          </a>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export { CompareHeader };

const StyledHeaderWrap = styled.header`
  color: var(--secondary-text);
  font-family: "Inter";
  font-weight: 700;
  margin-top: 3.27em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconStyle = css`
  border-radius: 50%;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background: #fff;
  ${mq.sm} {
    font-size: 1em;
    font-weight: bold;
  }
`;

export const StyledBackButton = styled(BackButton)`
  position: fixed;
  top: 2.61em;
  left: 2em;
  ${IconStyle};
  ${mq.lg} {
    left: 1.6em;
  }
`;

const StyledShareButton = styled.button`
  border: none;
  box-shadow: 1px 1px 6px 1px #ebecf4;
  color: var(--tertiary-text);
  margin: 0 0 1rem 0;

  ${IconStyle};
`;
const StyledShareButtonMain = styled.button`
  border: none;
  box-shadow: 1px 1px 6px 1px #ebecf4;
  color: ${props =>
    props.showPopup ? "var(--primary-color)" : "var(--primary-text)"};
  margin: 0 0 1rem 0;
  &:hover {
    color: var(--primary-color);
  }
  ${IconStyle};
`;
