import { forwardRef } from "react";
import { CustomFieldStyled } from "../styles/inputForms.styled";

const InputPageTextInput = forwardRef(
  ({ label, value, error, capitalize, defaultValue, ...props }, ref) => {
    return (
      <>
        <CustomFieldStyled
          ref={ref}
          label={label}
          variant={"outlined"}
          value={value || ""}
          fullWidth
          defaultValue={defaultValue || ""}
          capitalize={capitalize}
          error={Boolean(error)}
          helperText={error}
          {...props}
          onInput={e => {
            props.onInput && props.onInput(e);
            if (props.inputProps?.maxLength) {
              if (e.target.value.length > props.inputProps?.maxLength) {
                e.target.value = e.target.value.substr(
                  0,
                  props.inputProps?.maxLength,
                );
              }
            }
          }}
        />
      </>
    );
  },
);

export default InputPageTextInput;
