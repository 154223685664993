import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";
// import { mobile, tablet } from "src/utils/mediaQueries";

// TATA Footer Styles
export const FooterMain = styled.div`
  width: 100%;
  color: #fff;
  font-size: 1rem;
  background-color: #1e2aa8;
`;

export const CopyrightImg = styled.img`
  width: 50%;
  ${mq.sm} {
    width: 70%;
  }
  ${mq.xs} {
    width: 100%;
  }
`;

export const FooterData = styled.div`
  text-align: center;
  font-size: 0.7rem;
  ${mq.sm} {
    font-size: 0.5rem;
  }
`;

// BAJAJ Footer Styles
export const HRStyled = styled.hr`
  margin: ${props => {
    return props.hrtype ? "100px 0 120px 0" : "20px 0 20px 0";
  }};
  ${mq.sm} {
    margin: ${props => {
      return props.hrtype ? "50px 0 60px 0" : "20px 0 20px 0";
    }};
  }
  & + div.copyright {
    text-align: center;
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.53);
    ${mq.sm} {
      padding-bottom: 3rem;
      font-size: 14px;
    }
  }
`;

export const FooterContainerStyled = styled.div`
  font-size: 14px;
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  ${mq.sm} {
    font-size: 11px;
  }
  p {
    margin: 10px 0;
  }
  hr {
    margin: 1rem 0 2rem 0;
  }
  h4 {
    font-size: 16px;
    color: var(--primary-color);
    font-size: 14px;
    ${mq.sm} {
      margin-top: 10px;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0.6rem;
  }
  li {
    margin: 10px 0 10px -10px;
  }
  a {
    text-decoration: none;
    color: grey;
  }
  a:hover {
    color: grey;
    cursor: pointer;
  }
  ${mq.md} {
    flex-direction: column;
  }
  @media (min-width: 1120px) and (max-width: 1250px) {
    width: 90%;
  }
  @media (min-width: 990px) and (max-width: 1120px) {
    width: 95%;
  }
  @media (min-width: 830px) and (max-width: 990px) {
    width: 98%;
  }
  @media (max-width: 830px) {
    width: 90%;
  }
  @media (min-width: 768px) {
    span.line__break {
      display: block;
    }
  }
`;

export const FooterTopStyled = styled.div`
  flex: 1 0 83%;
  display: flex;
  justify-content: space-between;
  /* a:hover {
    color: #ed1b24;
  } */
  & > div {
    margin-right: 30px;
    ${mq.sm} {
      margin-right: 0;
    }
  }
  ${mq.sm} {
    display: block;
  }
`;

export const FooterInformationStyled = styled.div`
  p:first-of-type {
    margin-top: 1rem;
  }
`;

export const SocialLinksStyled = styled.div`
  width: 15%;
  text-align: center;
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  ${mq.md} {
    width: 20%;
  }
  ${mq.sm} {
    text-align: left;
    width: 150px;
  }
`;

export const FooterBottomStyled = styled.div`
  flex: 1 0 100%;
  p {
    color: rgba(0, 0, 0, 0.53);
  }
  a {
    color: var(--primary-color) !important;
  }
`;
