import styled from "styled-components";
import * as mq from "../../../../styles/mediaQueries";
export const DownloadWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 12px;
  margin: 10px auto 70px;
  ${mq.md} {
  }
  ${mq.sm} {
    display: flex;
    align-items: center;
    width: 90%;
  }
  @media screen and (min-width: 820px) and (max-width: 884px) {
  }
  & h6 {
    font-weight: 700;
    font-family: "Inter";
    font-size: clamp(12px, 2vw, 20px);
    ${mq.sm} {
      font-size: 0.8rem;
      margin-bottom: 0;
      margin-left: 6px;
    }
  }
  & p {
    margin-bottom: 0;
    font-family: "Inter";
    font-weight: 400;
    font-size: 13px;
    ${mq.sm} {
      padding: 5px;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  ${mq.sm} {
    margin-top: 5px;
    gap: 0.1em;
  }
`;
export const DownloadButton = styled.a`
  text-align: center;
  border-radius: 8px;
  color: var(--secondary-text);
  background: var(--tertiary-shade);
  padding: 8px;
  font-size: 0.8rem !important;
  ${mq.sm} {
    width: 48%;
    font-size: 0.6rem !important;
    border-radius: 5px;
  }
  & i {
    color: var(--primary-color-dark);
    ${mq.sm} {
      margin-left: 5px;
    }
  }
  &,
  &:link,
  &:visited {
    text-decoration: none;
  }
  font-size: clamp(12px, 2vw, 16px);
`;
