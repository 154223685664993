import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import "styled-components/macro";
import api from "../../api/api";
import success from "../../assets/images/check.png";
import { FullScreenLoader } from "../../components";
import * as mq from "../../styles/mediaQueries";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
  useGetEnquiriesQuery,
} from "../riders/services/rider";
import {
  LabelText,
  ShowText,
  SubmitButton,
  SuccessImage,
} from "./ThankYouPage.style.js";
import Footer from "../../components/Footer/index.jsx";

const goTo = async (setLocation, setPaymentStatus) => {
  const { data } = await api.get("term/after-payment");
  setLocation(data.data.redirectTo);
  setPaymentStatus(data?.data?.payment_status);
  return data;
};
function ThankYouPage() {
  const {
    frontendData: { tenant },
  } = useSelector(state => state.frontendBoot);
  const { isLoading, data } = useGetCartQuery();
  const [location, setLocation] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const history = useHistory();
  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data?.proposal_reference_id;

  useEffect(() => {
    goTo(setLocation, setPaymentStatus);
  }, [data]);

  const { data: enquiryData } = useGetEnquiriesQuery();

  if (isLoading) return <FullScreenLoader />;

  const company_alias = data?.product?.company?.alias;

  return (
    <div>
      <div
        className="container"
        css={`
          margin-top: 35px;
        `}
      >
        {paymentStatus === "failed" ? (
          <Section className="col-md-12">
            <div className="container">
              <SuccessImage src={success} />
              <div className="col-md-12">
                <ShowText failed>Payment Failed!</ShowText>
              </div>
              {/* <div className="col-md-12">
                <LabelText>
                 
                  Your proposal has been submitted successfully and application
                  no. is {appNo}. <br />
                  {data?.product?.company?.name} will review your proposal and
                  we will get back to you shortly
                </LabelText>
              </div> */}
            </div>

            <SubmitButton
              onClick={() => {
                history.push(
                  `/quote?enquiryId=${enquiryData?.data?.enquiry_id}`,
                );
              }}
            >
              Go Back To Quotes
              {/* &nbsp;
              <span style={{ textTransform: "capitalize" }}>
                {location} page
              </span> */}
            </SubmitButton>
          </Section>
        ) : (
          <Section className="col-md-12">
            <div className="container">
              <SuccessImage src={success} />
              <div className="col-md-12">
                {company_alias === "tata_aia" ? (
                  <ShowText>
                    Thank you for choosing TATA AIA {data?.product?.name}
                  </ShowText>
                ) : (
                  <ShowText>Thank you for choosing {tenant.name}</ShowText>
                )}
              </div>
              <div className="col-md-12">
                {company_alias === "tata_aia" ? (
                  <LabelText>
                    Your payment for app no {appNo} , is successfully done.
                    <br />
                    {data?.product?.company?.name} will review your proposal and
                    we will get back to you shortly
                  </LabelText>
                ) : (
                  <LabelText>
                    Your proposal has been submitted successfully and
                    application no. is {appNo}. <br />
                    {data?.product?.company?.name} will review your proposal and
                    we will get back to you shortly
                  </LabelText>
                )}
              </div>
            </div>
            <SubmitButton
              onClick={() => {
                window.open(location, "_self");
              }}
            >
              Proceed To&nbsp;
              <span style={{ textTransform: "capitalize" }}>Proposal Page</span>
            </SubmitButton>
          </Section>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ThankYouPage;

const Section = styled.div`
  box-shadow: 0px -3px 20px rgb(134 156 213 / 25%);
  padding: 12px 60px;
  width: 57%;
  margin: 0 auto;
  margin-bottom: 24px;
  ${mq.sm} {
    width: 100%;
    padding: 6px 10px;
  }
`;
//
