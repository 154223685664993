import { Box } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import * as mq from "../../styles/mediaQueries";
import CompareEmptyCard from "./components/CompareEmptyCard";
import CompareFilters from "./components/CompareFilters";
import CompareFiltersMobile from "./components/CompareFiltersMobile";
import CompareHeader from "./components/CompareHeader";
import CompareTable from "./components/CompareTable";
import Footer from "../../components/Footer";

const NewCompare = () => {
  const isMobile = useMediaQuery(`${mq.smForHook}`);
  const [openFilter, setOpenFilter] = useState(false);
  const { quotesList } = useSelector(state => state?.newCompare);

  return (
    <>
      <div className="container">
        <CompareHeader />
        {isMobile ? (
          <CompareFiltersMobile
            isFirst={quotesList?.length ? true : false}
            show={openFilter}
            setShow={setOpenFilter}
          />
        ) : (
          <CompareFilters />
        )}

        {isMobile && (quotesList?.length ? true : false) && (
          <AddNewButtonWrapper onClick={() => setOpenFilter(true)}>
            Add New
          </AddNewButtonWrapper>
        )}
        {(quotesList?.length ? true : false) ? (
          <CompareTable isMobile={isMobile} />
        ) : isMobile ? (
          <CompareEmptyCard setOpenFilter={setOpenFilter} />
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </>
  );
};

export default NewCompare;

const AddNewButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0.75rem 0rem 0.75rem;
  background: #f2faff;
  border-radius: 7px;
  border: 1px dashed #367cff;
  color: #367cff;
  font-size: 0.75rem;
  cursor: pointer;
  height: 2rem;
`;
