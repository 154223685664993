import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import "styled-components/macro";
import styled from "styled-components/macro";
import { FullScreenLoader } from "../../components";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import { UploadPanel } from "../ProposalPage/ProposalPage.style";
import { useGetCartQuery } from "../riders/services/rider";
// import { BiArrowBack } from "react-icons/bi";
// import { BackButton } from "../../components";
import { useEffect } from "react";
import api from "../../api/api";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import * as mq from "../../styles/mediaQueries";
import BajajDocumentUpload from "../ProposalPage/companies/Bajaj/Document/BajajDocumentUpload";
import IciciDocumentUpload from "../ProposalPage/companies/Icici/Document/IciciDocumentUpload";
import PnbDocumentUpload from "../ProposalPage/companies/Pnbmetlife/document/PnbDocumentUpload";
import TataDocumentUpload from "../ProposalPage/companies/TataAia/Document/TataDocumentUpload";
import ProposalPageFooterMobile from "../ProposalPage/components/ProposalPageFooterMobile";
import {
  proposal_status,
  updateProposal,
} from "../proposel-page/ProposalPage.slice";
import PlanCard from "../riders/component/PlanCard/PlanCard";
import Footer from "../../components/Footer";

const FormByIC = ({
  company_alias,
  setDisable,
  disable,
  setSuccessBajajDocUpload,
  successBajajDocUpload,
}) => {
  const enquiryId = useUrlQuery("enquiryId");
  if (company_alias === "bajaj_life")
    return (
      <BajajDocumentUpload
        setDisable={setDisable}
        disable={disable}
        successBajajDocUpload={successBajajDocUpload}
        setSuccessBajajDocUpload={setSuccessBajajDocUpload}
      />
    );
  if (company_alias === "icici_pru")
    return <IciciDocumentUpload setDisable={setDisable} disable={disable} />;
  if (company_alias === "tata_aia")
    return <TataDocumentUpload setDisable={setDisable} disable={disable} />;

  if (company_alias === "pnb_metlife")
    return <PnbDocumentUpload setDisable={setDisable} disable={disable} />;

  if (company_alias) return <Redirect to={`/quotes?enquiryId=${enquiryId}`} />;
  else return <></>;
};

// ALL FORM IMPORT
const DocumentUploadPage = () => {
  const [disable, setDisable] = useState({});
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  const matches = useMediaQuery(`${mq.smForHook}`);
  const { isLoading: isCartLoading, data } = useGetCartQuery();
  const [successBajajDocUpload, setSuccessBajajDocUpload] = useState(0);
  // const disableFalse = Object.values(disable)
  // const filteredDisableFalse = disableFalse.filter(val => val != true)

  const company_alias = data?.data?.product?.company?.alias;
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (doc_length === successBajajDocUpload) {
  //     setDisable(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successBajajDocUpload]);

  useEffect(() => {
    dispatch(updateProposal(proposal_status.DOCUMENT_PENDING));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCartLoading) return <FullScreenLoader />;

  const validFileTypes =
    company_alias === "icici_pru"
      ? "JPG, JPEG, PDF, PNG, BMP, TIFF"
      : company_alias === "tata_aia"
      ? "JPG, PDF"
      : "JPEG, JPG, PDF, TIF, TIFF";

  return (
    <div>
      <Outer>
        {!matches && (
          <div className="col">
            <PlanCard hideBuy />
          </div>
        )}
        <div className="col">
          <UploadPanel>
            {/* ============================== HEADINGS =============================== */}
            <div className="col-md-12 panel_heading ">
              <p className="text-center">
                Almost done! Please upload documents.
              </p>
            </div>
            <div className="col-md-12 panel_heading_desc">
              <p>
                Please upload {validFileTypes} file types only size (upto 5MB)
              </p>
            </div>
            {/* ======================= accordions contain forms =================== */}

            <>
              <FormByIC
                company_alias={company_alias}
                setDisable={setDisable}
                disable={disable}
                successBajajDocUpload={successBajajDocUpload}
                setSuccessBajajDocUpload={setSuccessBajajDocUpload}
              />
              {/* ===================== agree confirmation section ================= */}
              <div className="text-center panel_fotter">
                <p>*All fields are required</p>
                <p>*File should not be password protected.</p>
                <div className="col-md-4 mx-auto">
                  <SubmitButton
                    disabled={Object.values(disable).includes(true)}
                    onClick={async () => {
                      dispatch(
                        updateProposal(proposal_status.DOCUMENT_SUBMITTED),
                      ).then(data => {
                        if (
                          !data?.data?.product?.company?.alias === "bajaj_life"
                        ) {
                          const link = document.createElement("a");
                          link.href =
                            data?.data?.proposal?.extras?.proposal_pdf;
                          link.download = "BenefitIllustration";
                          link.target = "_blank";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }
                      });
                      await api
                        .get("term/after-payment")
                        .then(({ data }) => {
                          if (data.data.medicalStatus) {
                            history.push(`/confirm?enquiryId=${enquiryId}`);
                          } else
                            history.push(`/success?enquiryId=${enquiryId}`);
                        })
                        .catch(err => console.error(err));
                    }}
                    className="btn "
                  >
                    SUBMIT
                  </SubmitButton>
                </div>
              </div>
            </>
          </UploadPanel>
        </div>
      </Outer>
      {matches && <ProposalPageFooterMobile />}
      <Footer />
    </div>
  );
};

// const BackButtonWrapper = styled(BackButton)`
//   ${"" /* position: fixed; */}
//   top: 4em;
//   left: 2em;
//   border-radius: 50%;
//   height: 2em;
//   width: 2em;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 1.37rem;
//   background: #fff;

//   ${mq.sm} {
//     top: 4em;
//     font-size: 1.2em;
//     font-weight: bold;
//     left: 0.5em;
//   }
// `;

const Outer = styled.div`
  width: 1200px;
  display: flex;
  margin: 20px auto;
  font-family: "Inter";
  font-weight: 400;
  ${mq.sm} {
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  ${mq.sm} {
    width: 50% !important;
    border-radius: 12px !important;
  }
`;

// const ConfirmationSection = styled.div`
//   ${mq.sm} {
//     padding: 0 10px;
//   }

//   & .submit_btn {
//     button {
//       font-family: "Poppins";
//       line-height: 50px;
//       padding: 0 40px;
//       font-size: 16px;
//       color: #ffffff;
//       text-transform: uppercase;
//       background: var(--primary-color);
//       border: none;

//       border-radius: 2px;
//       ${mq.sm} {
//         width: 100%;
//       }
//     }
//   }
//   & input[type="checkbox"] {
//     appearance: none;
//     -webkit-appearance: none;
//     height: 20px;
//     width: 20px;
//     border: 1px solid #797979;
//     background-color: white;
//     border-radius: 2px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     transition: all 0.4s ease-in-out;
//     ${mq.sm} {
//       height: 14px;
//       width: 25px !important;
//     }
//   }
//   & input[type="checkbox"]:checked {
//     height: 20px !important;
//     width: 20px !important;
//     transition: all 0.2s ease-in-out;
//     background: #797979;
//     ${mq.sm} {
//       height: 14px !important;
//       width: 25px !important;
//     }
//   }
//   & input[type="checkbox"]:after {
//     content: "\f00c";
//     font-family: "Font Awesome 5 Free";
//     /* border: 1px solid #797979; */
//     font-weight: 600;
//     color: white !important;
//     font-size: 10px;
//   }
//   & label {
//     color: #838383;
//     cursor: pointer;
//     font-family: "Poppins";
//     font-size: 12px;
//     padding-left: 30px;
//     ${mq.sm} {
//       font-size: 10px;
//       padding-left: 20px;
//     }
//   }
// `;
// function BackButton() {
//   const enquiryId = useUrlQuery("enquiryId");

//   const history = useHistory();

//   const gotoRidersPage = () =>
//     history.replace(`/riders?enquiryId=${enquiryId}`);

//   return (
//     <button
//       css={`
//         position: absolute;
//         top: 6em;
//         left: 6em;
//         background: none;
//         border: none;
//         color: #333;
//       `}
//       onClick={gotoRidersPage}
//     >
//       <div
//         css={`
//           background-color: var(--tertiary-shade);
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           height: 2em;
//           width: 2em;
//           border-radius: 50%;
//         `}
//       >
//         <BiArrowBack />
//       </div>
//     </button>
//   );
// }

export default DocumentUploadPage;
