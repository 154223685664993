import * as yup from "yup";

export const eligibilityFormSchema = yup.object({
  occupation: yup.string().nullable().required("Occupation is required."),
  education: yup.string().nullable().required("Education is required."),
  term_annual_income: yup
    .string()
    .nullable()
    .required("Annual Income is required"),
});
