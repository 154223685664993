// import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import "styled-components/macro";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { FormWrapper } from "../ProposalPage/ProposalPage.style";
import InputWrapper from "../ProposalPage/components/InputWrapper";
import Select from "../ProposalPage/components/Select";
import TextInput from "../ProposalPage/components/TextInput";
import useProposalForm from "../ProposalPage/useProposalForm";
import { getLocations } from "../product-page/EligibilityCard/serviceApi";
import {
  proposal_status,
  updateProposal,
} from "../proposel-page/ProposalPage.slice";
import {
  useGetApplicationNumberQuery,
  useGetEnquiriesQuery,
} from "../riders/services/rider";
import {
  dateOptions,
  medical,
  sample,
  timeOptions,
  timeOptionsTU,
} from "./helpers";
import { getMedicalScheduling } from "./serviceApi";

function Confirm() {
  const { formData, setValue, setFormData, errors } = useProposalForm({
    validator: medical,
    name: "Medical Scheduling",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateProposal(proposal_status.MEDICAL_PENDING));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const enquiryId = useUrlQuery("enquiryId");
  const history = useHistory();
  // const { data: medicalData } = getMedicalScheduling({ type: "medicalCenter" });
  // const { isTU, centreList } = medicalData[0]?.medical_response;
  const isTU = false;
  const centerList = sample.data[0].medical_response.centreList;
  useEffect(() => {
    getMedicalScheduling({ type: "medicalCenter" });
  }, []);
  const { isLoading } = useGetEnquiriesQuery();

  useEffect(() => {
    if (formData?.pincode && !isLoading) {
      getLocations({
        pincode: formData?.pincode,
        company_alias: "icici_pru",
      })
        .then(({ data }) => {
          setFormData(prev => ({
            ...prev,
            state_code: data.state.code,
            state: data.state.display_name,
            city: data.city.display_name,
            city_code: data.city.code,
          }));
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.pincode, isLoading]);

  useEffect(() => {
    if (formData?.centre_details && !isLoading) {
      setFormData(prev => ({
        ...prev,
        centre_id: formData?.centre_details,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.centre_details, isLoading]);

  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data1?.proposal_reference_id;
  const onSubmit = async e => {
    try {
      await getMedicalScheduling({
        type: "medicalSchedule",
        application_no: appNo,
        dateOfAppointment: formData?.preferred_date,
        timeOfAppointment: formData?.preferred_time,
        centreDetails: formData?.centre_details,
        visitType: formData?.visit_type,
        line1: formData?.address_line_1,
        line2: formData?.address_line_2,
        line3: formData?.address_line_3,
        landmark: formData?.landmark,
        city: formData?.city,
        state: formData?.state,
        pinCode: formData?.pincode,
        centreId: formData?.centre_id,
      });
      dispatch(updateProposal(proposal_status.MEDICAL_SUBMITTED));
      history.push(`/success?enquiryId=${enquiryId}`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div
        className="container "
        css={`
          margin-top: 25px;
        `}
      >
        <Outer>
          <div className="col-md-12">
            <Text>Proposal form - Medical</Text>
          </div>
          <div className="col-md-12">
            <p
              css={`
                text-align: center;
                font-family: "Poppinssemibold" !important;
                font-weight: normal;
                color: #57667e;
                font-size: 12px;
              `}
            >
              Please confirm your visit type & preferred date, time for Medical
              test
            </p>
            <p
              css={`
                text-align: center;
                font-family: "Poppinssemibold" !important;
                font-weight: normal;
                color: #ec0303;
                font-size: 12px;
              `}
            >
              All fields are required.
            </p>
          </div>
          <FormWrapper>
            <InputWrapper
              input={
                <Select
                  options={dateOptions()}
                  label={"Preferred Date"}
                  selectedValue={formData?.preferred_date}
                  noSearch
                  handleChange={val => {
                    setValue("preferred_date", val);
                  }}
                />
              }
            />
            <InputWrapper
              input={
                <Select
                  options={isTU ? timeOptionsTU : timeOptions()}
                  label={"Preferred Time Slot"}
                  selectedValue={formData?.preferred_time}
                  noSearch
                  handleChange={val => {
                    setValue("preferred_time", val);
                  }}
                />
              }
            />
            <InputWrapper
              input={
                <Select
                  options={[
                    { code: "center", display_name: "Center Visit" },
                    { code: "home", display_name: "Home Visit" },
                  ]}
                  label={"Visit Type"}
                  selectedValue={formData?.visit_type}
                  noSearch
                  handleChange={val => {
                    setValue("visit_type", val);
                  }}
                  error={errors?.visit_type}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label={"Address Line 1*"}
                  value={formData?.address_line_1}
                  onChange={e => {
                    setValue("address_line_1", e.target.value);
                  }}
                  inputProps={{ placeholder: "Address Line 1" }}
                  error={errors?.address_line_1}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label={"Address Line 2"}
                  value={formData?.address_line_2}
                  onChange={e => {
                    setValue("address_line_2", e.target.value);
                  }}
                  inputProps={{ placeholder: "Address Line 2" }}
                  error={errors?.address_line_2}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label={"Address Line 3"}
                  value={formData?.address_line_3}
                  onChange={e => {
                    setValue("address_line_3", e.target.value);
                  }}
                  inputProps={{ placeholder: "Address Line 3" }}
                  error={errors?.address_line_3}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label={"Pincode*"}
                  value={formData?.pincode}
                  onChange={e => {
                    setValue("pincode", e.target.value);
                  }}
                  inputProps={{ placeholder: "Pincode" }}
                  error={errors?.pincode}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label="State*"
                  value={formData?.state}
                  onChange={e => {
                    setValue("state", e.target.value);
                  }}
                  readOnly={true}
                  inputProps={{ placeholder: "State", readOnly: true }}
                  error={errors?.state}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label={"City*"}
                  value={formData?.city}
                  onChange={e => {
                    setValue("city", e.target.value);
                  }}
                  inputProps={{ placeholder: "City", readOnly: true }}
                  error={errors?.city}
                />
              }
            />
            <InputWrapper
              input={
                <TextInput
                  label={"Area / Landmark"}
                  value={formData?.landmark}
                  onChange={e => {
                    setValue("landmark", e.target.value);
                  }}
                  inputProps={{ placeholder: "Area / Landmark" }}
                  error={errors?.landmark}
                />
              }
            />
            {formData?.visit_type === "center" ? (
              <InputWrapper
                input={
                  <Select
                    options={centerList.map(item => ({
                      code: item.CNTRMASTER_SRNO,
                      display_name: item.CNTRMASTER_DIAGNOSTIC_CENTER,
                    }))}
                    label={"Center Details"}
                    selectedValue={formData?.centre_details}
                    handleChange={e => {
                      setValue("centre_details", e);
                    }}
                    error={errors?.centre_details}
                  />
                }
              />
            ) : (
              <></>
            )}
          </FormWrapper>
          <div
            className="col-md-12"
            css={`
              display: flex;
              justify-content: center;
              margin-top: 20px;
            `}
          >
            <div className="col-md-4">
              <Button type="button" onClick={onSubmit}>
                Confirm
              </Button>
            </div>
          </div>
        </Outer>
      </div>
    </div>
  );
}

export default Confirm;
const Outer = styled.div`
  box-shadow: 0px -3px 20px rgb(134 156 213 / 25%);
  padding: 60px 50px 60px 50px;
  margin-bottom: 24px;
`;

const Text = styled.p`
  margin: -38px 5px 35px -5%;
  color: rgb(43 71 139);
  font-size: 28px;
  line-height: 28px;
  font-weight: 900;
  background-image: linear-gradient(
    to right,
    rgb(226 240 255),
    rgb(255, 255, 255)
  );
  padding: 10px;
  width: 68%;
`;

// const Inner = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const Select = styled.select`
//   display: block;
//   width: 360px;
//   padding: 0.375rem 0.75rem;
//   font-size: 12px;
//   line-height: 2.6;
//   color: #000;
//   background: url(${down}) no-repeat 94% 53%;
//   background-size: 11px 10px;
//   border: 1px solid lightgrey;
//   border-radius: 5px;
//   transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
//   font-weight: 600;
//   appearance: none;
//   height: 45.2px;
//   margin: 10px;
//   &:focus {
//     outline: none;
//     border: 1px solid gray;
//   }
// `;

const Button = styled.button`
  font-family: "Poppins";
  line-height: 50px;
  padding: 0 50px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  background: var(--primary-color);
  width: 90%;
  margin: auto;
  border-radius: 2px;
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
`;
