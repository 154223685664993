import * as yup from "yup";
import { mobileRegex } from "../../../ProposalPage/inputUtils/regex";

export const communicationFormSchema = yup.object({
  name: yup
    .string()
    .min(2, "Minimum 2 characters required.")
    .max(30, "Maximum 30 characters allowed.")
    .required("Name is required.")
    .test("name", "Please enter Full Name.", val => {
      if (!val) return false;
      if (val)
        return val.split(" ").length >= 2 && val.split(" ")[1].length > 0;
    }),
  email: yup
    .string()
    .email("Please enter a valid Email.")
    .required("Email is required.")
    .max(50, "Maximum 50 characters allowed"),
  mobile: yup
    .string()
    .matches(mobileRegex, "Please enter a valid Phone No.")
    .required("Phone is required."),
});
