import React from "react";
import { CopyrightImg, FooterData, FooterMain } from "./index.style";
import tmibasl_footer from "../../assets/images/tmibasl_footer.png";

const TataFooter = () => {
  return (
    <FooterMain>
      <div className="container">
        <div className="text-center">
          <CopyrightImg alt="TMIBASL" src={tmibasl_footer} width={"90%"} />
          <FooterData className="col-12 py-3">
            Composite Broker License No. 375 | Validity 13/05/2023 to 12/05/2026
            | CIN: U50300MH1997PLC149349 IBAI Membership No. 35375
            <br />
            Corp Office: 1st Floor AFL House, Lok Bharti complex, Marol Maroshi
            Road, Andheri (East), Mumbai - 400 059. Maharashtra. India.
            <br />
            Registered Office: Nanavati Mahalaya, 3rd floor, Tamarind Lane, Homi
            Mody Street, Fort, Mumbai - 400 001. Maharashtra. India. <br />A
            sister Company of TATA AIA Life Insurance Company Limited and TATA
            AIG General Insurance Company Limited &nbsp;|&nbsp;
            <a
              href="https://lifekaplan.com/terms-conditions"
              rel="noreferrer"
              target="_blank"
              className="text-white"
            >
              Terms &amp; Conditions
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://lifekaplan.com/code-of-conduct"
              rel="noreferrer"
              target="_blank"
              className="text-white"
            >
              Broker Code of Conduct
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://lifekaplan.com/privacy-policy"
              rel="noreferrer"
              target="_blank"
              className="text-white"
            >
              Privacy Policy
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://www.irdai.gov.in/"
              rel="noreferrer"
              target="_blank"
              className="text-white"
            >
              IRDAI
            </a>
            &nbsp;|&nbsp;
            <a
              href="http://ibai.org/"
              rel="noreferrer"
              target="_blank"
              className="text-white"
            >
              IBAI
            </a>
          </FooterData>
        </div>
      </div>
    </FooterMain>
  );
};

export default TataFooter;
