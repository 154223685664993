import { Modal } from "react-bootstrap";
import { useState } from "react";
import CancelCircularIcon from "../../assets/svg-icons/CancelCircular";
import mailImg from "../../assets/images/mail.png";
import styled from "styled-components/macro";
import { useShareQuoteMutation } from "../../pages/riders/services/rider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as mq from "../../styles/mediaQueries";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { patchEnquiries } from "../../pages/product-page/Filters/serviceApi";
import TextInput from "../../pages/product-page/EligibilityCard/components/TextInput/TextInput";
import { InputWrapper } from "../../pages/input/component/Fieldset1.style";
import ButtonLoader from "../CardSkeletonLoader/ButtonLoader/ButtonLoader";
import { FaShare } from "react-icons/fa";

const schema = yup.object({
  email: yup
    .string()
    .email("Please Enter a valid Email")
    .required("Please enter email"),
});

const ShareOnMailModal = ({ show, handleClose }) => {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { addedRiders } = useSelector(state => state.quotes);
  const [email, setEmail] = useState();
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const onSubmit = async formData => {
    await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    shareQuotes({
      purpose: "compare",
      email: email,
      via: "email",
      selected_riders: addedRiders,
      show_difference: showDifference,
      selected_features: selectedFeatures,
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Compare Shared Successfully!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setEmail("");
      }
    });
  };
  const handleModalClose = () => {
    handleClose();
    setEmail("");
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <CancelCircularIcon
        width="27"
        onClick={() => handleModalClose()}
        style={{
          position: "absolute",
          right: "-10px",
          top: "-10px",
        }}
      />
      <Modal.Header>
        <Modal.Title
          css={`
            font-size: 1.2rem;
            font-family: Inter;
            font-weight: 500;
            padding-right: 2.67em;
            ${mq.sm} {
              font-size: 0.9rem;
            }
            &::before {
              content: "";
              position: absolute;
              height: 30px;
              width: 10px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
          `}
        >
          Share Compared Plans
        </Modal.Title>
      </Modal.Header>

      <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body
          css={`
            padding: 1rem 5rem;
            ${mq.sm} {
              padding: 1rem;
            }
          `}
        >
          <img src={mailImg} alt="MAIL_IMG" className="mx-auto d-block" />

          <InputWrapper>
            <TextInput
              label="Please Enter Email"
              {...register("email")}
              onChange={e => {
                register("email").onChange(e);
                setEmail(e.target.value);
              }}
              error={errors.email && errors.email.message}
              value={email}
              inputProps={{ minLength: 2, maxLength: 50, inputMode: "email" }}
            />
          </InputWrapper>
        </Modal.Body>
        <Modal.Footer
          css={`
            justify-content: center;
          `}
        >
          <button
            className="btn btn-primary "
            style={{
              fontSize: "12px",
              padding: "12px 36px",
            }}
          >
            {isLoading ? (
              <ButtonLoader />
            ) : (
              <>
                Share{"  "}&nbsp;
                <FaShare className="icon-left" />
              </>
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ShareOnMailModal;

export const StyledInput = styled.input`
  margin: 7px auto;
  outline: none;
  border: none;
  padding: 22px 18px;
  font-size: 12px;
  color: #57667e;
`;

export function ModalPopup({
  title = "",
  show,
  handleClose = () => {},
  children,
  ...props
}) {
  return (
    <Modal show={show} onHide={handleClose} {...props} centered>
      <h1
        css={`
          font-size: 1.2rem;
          padding: 1em;
          font-family: Inter;
          font-weight: 500;
          padding-right: 2.67em;
        `}
      >
        {title}
      </h1>
      <CancelCircularIcon
        width="27"
        onClick={() => handleClose()}
        style={{
          position: "absolute",
          right: "20px",
          top: "15px",
        }}
      />

      <Modal.Body
        className="p-3 position-relative  d-flex flex-column align-items-center justify-content-center"
        style={{
          fontSize: "14px",
        }}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
}
