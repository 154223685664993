export const getTobaccoHabit = tobacco_habit => {
  switch (tobacco_habit) {
    case "S":
      return "Smoker";
    case "NS":
      return "Non-Smoker";
    default:
      return "Non-Smoker";
  }
};
