import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdMailOutline, MdOutlinePhone } from "react-icons/md";
import { SiWhatsapp } from "react-icons/si";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "styled-components/macro";
import * as yup from "yup";
import CancelCircular from "../../../assets/svg-icons/CancelCircular";
import TextInput from "../../../pages/product-page/EligibilityCard/components/TextInput/TextInput";
import * as mq from "../../../styles/mediaQueries";
import { capitalize, useNumberInput } from "../../../utils";
import {
  allowOnlyNumbers,
  restrictSpace,
} from "../../ProposalPage/inputUtils/onInput";
import { patchEnquiries } from "../../product-page/Filters/serviceApi";
import { selectAddedRiders } from "../../product-page/Quotes/quotes.slice";
import { ShareViaForm } from "../../product-page/components/ShareQuotePopup";
import {
  useGetCompareProductsQuery,
  useShareQuoteMutation,
} from "../../riders/services/rider";
// import { form3 } from "../../input/validations";
const ShareComparePopup = ({ showPopup, handleClose }) => {
  const [imageUrl, setImageUrl] = useState("");
  // const [showMore, setShowMore] = useState(false);
  // const toggleShow = () => setShowMore(!showMore);
  const { data: userData } = useSelector(state => state.inputForm);
  const { data: compareQuotes } = useGetCompareProductsQuery();
  const products = compareQuotes?.data?.products.map(i => i);
  const addedRiders = useSelector(selectAddedRiders);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const { tenant } = useSelector(state => state.frontendBoot?.frontendData);
  const proposerName = capitalize(userData?.name?.split(" ")[0]);
  getComparePageImage().then(data => {
    if (data) {
      setImageUrl(data);
    }
  });
  return (
    <Modal
      centered
      show={showPopup}
      onHide={handleClose}
      css={`
        & .modal-dialog {
          max-width: 650px;
        }
      `}
    >
      <Modal.Header>
        <Modal.Title
          css={`
            font-size: 1.2rem;

            font-family: Inter;
            font-weight: 500;
            padding-right: 2.67em;
            ${mq.sm} {
              font-size: 0.8rem;
            }

            &::before {
              content: "";
              position: absolute;
              height: 30px;
              width: 10px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
          `}
        >
          Hi {proposerName}, Please choose the way you wish to share your Life
          Insurance Quote Comparison.
        </Modal.Title>
        <CancelCircular
          width="27"
          onClick={handleClose}
          css={`
            position: absolute;
            right: 20px;
            top: 15px;

            ${mq.sm} {
              right: 1px;
              top: 2px;
            }
          `}
        />
      </Modal.Header>
      <Modal.Body>
        <ShareCompareOnMailForm
          products={products}
          addedRiders={addedRiders}
          showDifference={showDifference}
          selectedFeatures={selectedFeatures}
          imageUrl={imageUrl}
        />
        {tenant?.name !== "TMIBASL" && (
          <ShareOnWhatsappForm
            mobile={userData.mobile}
            products={products}
            addedRiders={addedRiders}
            showDifference={showDifference}
            selectedFeatures={selectedFeatures}
            imageUrl={imageUrl}
          />
        )}

        <ShareCompareViaText
          products={products}
          addedRiders={addedRiders}
          showDifference={showDifference}
          selectedFeatures={selectedFeatures}
          imageUrl={imageUrl}
        />
        {/* <MoreOptionsButton onClick={toggleShow}>
          {" "}
          {!showMore ? "View More Options" : "Hide"}
        </MoreOptionsButton>

        {showMore && (
          <>
            <QR />
            <CopyToClipboardForm />
          </>
        )} */}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ShareComparePopup;

// function WhatsappForm() {

// }

const schema = yup.object({
  email: yup.string().email().required("Please enter email"),
});
const mobileSchema = yup.object({
  mobile: yup
    .number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),
});
async function getComparePageImage() {
  const root = document.getElementById("compare-page-main");
  const canvas = await html2canvas(root);
  const image = canvas.toDataURL("image/png");
  return image.split(",")[1];
}
function ShareCompareOnMailForm({
  products,
  addedRiders,
  showDifference,
  selectedFeatures,
  imageUrl,
}) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { email: data.email || "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async formData => {
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    shareQuotes({
      purpose: "compare",
      email: formData.email,
      via: "email",
      selected_riders: addedRiders,
      show_difference: showDifference,
      selected_features: selectedFeatures,
      product_ids: products,
      image_to_send: imageUrl,
      sms: "",
      mode: ["EMAIL"],
      stage: "COMPARE",
      whatsapp: "",
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Compare Shared Successfully!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [email, setEmail] = useState(data.email);

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.email && errors.email.message}
        // errorMessage={errors.email && "Please enter a valid email"}
        {...register("email")}
        value={email}
        onChange={evt => {
          register("email").onChange(evt);
          setEmail(evt.target.value);
          setValue("email", evt.target.value);
        }}
        label="Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdMailOutline className="fs-6" />
            </InputAdornment>
          ),
        }}
        onInput={restrictSpace}
      />
      {/* <ShareFormInput {...register("email")} /> */}
    </ShareViaForm>
  );
}
function ShareOnWhatsappForm({
  mobile,
  addedRiders,
  showDifference,
  products,
  selectedFeatures,
  imageUrl,
}) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const [phone, handleChange] = useNumberInput(mobile);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "compare",
        groupCode: "10003511",
        mode: ["WHATSAPP"],
        selected_riders: addedRiders,
        show_difference: showDifference,
        image_to_send: imageUrl,
        product_ids: products,
        selected_features: selectedFeatures,
        via: "whatsapp",
        stage: "COMPARE",
        whatsapp: phone,
      }).then(data => {
        if (data.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Compare Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
    } else setError("Please enter a valid whatsapp no.");
  };

  return (
    <ShareViaForm
      // icon={<SiWhatsapp />}
      onSubmit={handleSubmit}
      // errorMessage={error}
      isLoading={isLoading}
    >
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput
        type="tel"
        maxLength={10}
        value={phone}
        onChange={handleChange}
        placeholder="Enter your Whatsapp Number"
      /> */}
    </ShareViaForm>
  );
}
function ShareCompareViaText({
  products,
  addedRiders,
  showDifference,
  selectedFeatures,
  imageUrl,
}) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);
  // const addedRiders = useSelector(selectAddedRiders);
  // const { showDifference, selectedFeatures } = useSelector(
  //   state => state.compare,
  // );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: data.mobile },
    resolver: yupResolver(mobileSchema),
  });

  const onSubmit = async formData => {
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    shareQuotes({
      purpose: "compare",
      mobile: formData.mobile,
      sms: formData.mobile,
      via: "sms",
      selected_riders: addedRiders,
      show_difference: showDifference,
      image_to_send: imageUrl,
      product_ids: products,
      selected_features: selectedFeatures,
      mode: ["SMS"],
      stage: "COMPARE",
      whatsapp: "",
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Compare Shared Successfully!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [mobile, setMobile] = useState(data.mobile);

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.mobile && errors.mobile.message}
        // errorMessage={errors.email && "Please enter a valid email"}
        {...register("mobile")}
        value={mobile}
        onInput={allowOnlyNumbers}
        onChange={evt => {
          register("mobile").onChange(evt);
          setMobile(evt.target.value);
          setValue("mobile", evt.target.value);
        }}
        label="Mobile"
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdOutlinePhone className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput {...register("email")} /> */}
    </ShareViaForm>
  );
}
