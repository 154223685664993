import { useSelector } from "react-redux";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import * as mq from "../../../styles/mediaQueries";

export const useHeaderDetails = () => {
  const isMobileOrTablet = useMediaQuery(`${mq.mdForHook}`);
  const { data } = useSelector(state => state.inputForm);
  const {
    gender,
    dob,
    tobacco_habit,
    term_annual_income,
    occupation,
    education,
  } = data || {};
  return {
    gender,
    dob,
    tobacco_habit,
    term_annual_income,
    occupation,
    education,
    isMobileOrTablet,
  };
};
