import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

const CancelCircular = ({ width, height = width, style, onClick }) => {
  return (
    <CancelCircleWrapper style={style} onClick={onClick}>
      <FaTimes />
    </CancelCircleWrapper>
  );
};

export default CancelCircular;

const CancelCircleWrapper = styled.div`
  border-radius: 50%;
  position: absolute;
  width: 30px;
  height: 30px;
  right: -10px;
  top: -10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
