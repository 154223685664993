import styled from "styled-components";
import React, { useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "styled-components/macro";
// import wrong from "../../../assets/images/wrong.png";
import useOutsideClickRef from "../../../custom-hooks/useOutsideClickRef";
import {
  Modal,
  // Tooltip,
  // OverlayTrigger
} from "react-bootstrap";
import * as mq from "../../../styles/mediaQueries";
import { useEffect } from "react";
function CriticalPopUp({ show, handleClose, rider }) {
  const ref = useRef();
  useOutsideClickRef(ref, () => {
    handleClose();
  });
  const riderData = rider.term_rider_data !== 0 ? rider.term_rider_data : {};
  const key = Object.keys(riderData).length !== 0 ? Object.keys(riderData) : [];
  const [header] =
    key.length > 0 ? riderData[1].map(item => item.header_name) : [""];
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setCurrent(0);
  }, [handleClose]);

  return (
    <Modal
      css={`
        & .modal-content {
          border-radius: 15px;
        }
      `}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header
        css={`
          flex-wrap: wrap;
          gap: 20px;
        `}
      >
        <CloseButton
          onClick={() => {
            handleClose();
          }}
        >
          <FaTimes />
        </CloseButton>
        <Modal.Title
          css={`
            &::before {
              content: "";
              position: absolute;
              height: 30px;
              width: 10px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
            width: 100%;

            font-family: "Inter";
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
          `}
        >
          {header}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        css={`
          padding: 0;
          padding-bottom: 1em;
        `}
      >
        <div
          css={`
            width: 85%;
            display: flex;
            margin: 10px auto;
            box-shadow: 0 8px 28px 0 rgba(107, 136, 164, 0.33);
            border-radius: 30px;
          `}
        >
          {key.length !== 0 &&
            key.map((item, index) =>
              riderData[item].map(
                illness_type =>
                  illness_type.sub_header_name !== null && (
                    <Subheader
                      active={index === current}
                      onClick={() => setCurrent(index)}
                    >
                      {illness_type.sub_header_name}
                    </Subheader>
                  ),
              ),
            )}
        </div>

        <Container>
          {key.length > 1 && (
            <ListofIllness current={current} riderData={riderData} />
          )}

          {key.length === 1 &&
            key.map(i =>
              riderData[i].map(item => (
                <>
                  <ul
                    css={`
                      color: #000;

                      ${mq.sm} {
                      }
                    `}
                  >
                    {item?.data.map((data, ind) => (
                      <li
                        css={`
                          ::marker {
                            color: #ffcb00;
                          }
                          font-size: 14px;
                          margin-bottom: 7px;
                          font-family: "Inter";
                          font-weight: 500;
                          color: #000; ;
                        `}
                      >
                        {data.trim()}
                      </li>
                    ))}
                  </ul>
                </>
              )),
            )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
export default CriticalPopUp;

const ListofIllness = ({ current, riderData }) => {
  if (current === 0) return <Minor riderData={riderData} />;
  if (current === 1) return <Major riderData={riderData} />;
};

const Minor = ({ riderData }) => {
  const data = riderData[1][0].data;

  return (
    <ul css={``}>
      {data.map((illness, index) => (
        <li
          key={index}
          css={`
            ::marker {
              color: #ffcb00;
            }
            font-size: 14px;
            margin-bottom: 7px;
            font-family: "Inter";
            font-weight: 500;
            color: #000; ;
          `}
        >
          {illness.trim()}
        </li>
      ))}
    </ul>
  );
};

const Major = ({ riderData }) => {
  const data = riderData[2][0].data;
  return (
    <ul>
      {data.map((data, index) => (
        <li
          key={index}
          css={`
            ::marker {
              color: #ffcb00;
            }
            font-size: 14px;
            margin-bottom: 7px;
            font-family: "Inte";
            font-weight: 500;
            color: #000; ;
          `}
        >
          {data.trim()}
        </li>
      ))}
    </ul>
  );
};

// const PopupWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 99999;
// `;
// const Popup = styled.div`
//   position: absolute;
//   top: 30%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background: #fff;
//   box-shadow: rgb(0 0 0 / 50%) 0px 5px 15px;
//   border: 1px solid rgba(0, 0, 0, 0.2);
//   max-width: 500px;
//   border-radius: 8px;

//   ${mq.sm} {
//     width: 100%;
//     max-width: 320px;
//     padding: 15px;
//   }
// `;

const Subheader = styled.div`
  flex: 1;
  white-space: nowrap;
  height: 50px;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  justify-content: center;
  font-weight: ${props => (props.active ? "400" : "700")};
  border-radius: 30px;
  background-color: ${props =>
    props.active ? "var(--primary-color)" : "#fff"};
  color: ${props => (props.active ? "#fff" : "#000")};
  ${mq.sm} {
    font-size: 12px;
  }
`;

const CloseButton = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border-radius: 50%;
  font-size: 16px;
  color: #000;
`;
const Container = styled.div`
  padding: 20px 10px;
  height: 360px;
  overflow-y: overlay;

  ::-webkit-scrollbar {
    display: block;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dbdad5;
    outline: none;
    display: block;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #babac0;
  }

  & p {
    font-weight: 400;
    font-family: "pf_handbook_proregular";
    font-size: 20px;
  }
  & span {
    text-transform: capitalize;
    font-weight: 600;
  }

  ${mq.sm} {
    & p {
      font-weight: 400;
      font-family: "pf_handbook_proregular";
      font-size: 18px;
      line-height: 1.4;
    }
  }
`;
