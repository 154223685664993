import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInputPageHook } from "../../hooks";

const useOTPPopupForm = (otp, setOtp) => {
  const { isOTPJourney } = useInputPageHook();
  const { otpData, otpResponse } = useSelector(state => state.inputForm);
  const [inputOTP, setInputOTP] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [verified, setVerified] = useState(false);
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inputOTP.length >= 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [inputOTP.length]);

  useEffect(() => {
    if (isOTPJourney === "Yes" && otp === true) {
      setOtp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpData?.message, otpData?.uuid]);

  useEffect(() => {
    if (otpResponse?.status === true && otpResponse.message === "Verified") {
      setOtp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpResponse?.status]);

  return { verified, inputOTP, setInputOTP, disable, dispatch };
};

export default useOTPPopupForm;
