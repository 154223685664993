import React, { useState } from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import {
  CompanyInfo,
  CompanyInfoCard,
  CompanyWrapper,
  Heading,
  InfoDesc,
  InfoName,
  InfoValue,
} from "./AboutCompany.style";
import "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";
const findAboutCompany = (aboutCompany, key) => {
  return Object.values(aboutCompany).find(
    item => item.feature_key_title === key,
  );
};
const AboutCompany = () => {
  const { selectedQuote } = useSelector(state => state.quotes);
  const { aboutCompany } = useSelector(state => state.quotes.seeDetailsData);
  const {
    frontendData: { termcompanies },
  } = useSelector(state => state.frontendBoot);
  const [active, setActive] = useState("Claim Settlement Ratio");

  return (
    <Container>
      <CompanyWrapper>
        <Heading>
          About
          <span>
            {" " + termcompanies[selectedQuote.company_alias].short_name}
          </span>{" "}
          Insurance
        </Heading>
        <div
          css={`
            font-family: Inter;
            font-weight: 500;
            font-size: 14px;
            ${mq.sm} {
              font-size: 12px;
              color: grey;
            }
          `}
        >
          {
            findAboutCompany(aboutCompany, "About Company")
              .feature_key_description
          }
        </div>
      </CompanyWrapper>
      <CompanyInfo>
        {Object.values(aboutCompany).map((item, index) => {
          if (index)
            return (
              <>
                <CompanyInfoCard
                  // onClick={() => setActive(item.feature_key_title)}
                  onMouseOver={() => setActive(item.feature_key_title)}
                  onMouseOut={() => setActive("")}
                  active={active === item.feature_key_title}
                >
                  <InfoName active={active === item.feature_key_title}>
                    {item.feature_key_title}
                  </InfoName>
                  <InfoValue active={active === item.feature_key_title}>
                    {item.feature_key_value}
                  </InfoValue>
                  <InfoDesc>{item.feature_key_description}</InfoDesc>
                </CompanyInfoCard>
              </>
            );
          else return <></>;
        })}
      </CompanyInfo>
    </Container>
  );
};

export default AboutCompany;
