import { useState } from "react";
import DOMPurify from "dompurify";

export function range(start = 0, end = 10, interval = 1) {
  const rangeArr = [];
  for (let i = start; i <= end; i += interval) {
    rangeArr.push(i);
  }
  return rangeArr;
}

export function amount(number) {
  return `₹ ${parseInt(number).toLocaleString("en-In")}`;
}

export const capitalize = (str = "") =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const validateName = (str = "") =>
  str.match(/^[a-zA-Z ]+$/) && !str.includes("  ");

export const isDigits = (str = "") => !!str.match(/^[0-9]*$/);

export const extractNumbers = (str = "") => String(str).replace(/[^0-9]/g, "");

export const isAmount = (str = "") => isDigits(String(str).replace(/,/g, ""));

export function useNameInput(defaultValue = "") {
  const [name, setName] = useState(defaultValue);
  const handleChange = evt => {
    const { value: givenName } = evt.target;
    if (!validateName(givenName) && givenName) return;
    setName(givenName.replace(/\b\w/g, char => char.toUpperCase()));
  };
  return [name, handleChange];
}

export function useNumberInput(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = evt => {
    const { value: givenInput } = evt.target;

    if (givenInput && !isDigits(givenInput)) return;

    setValue(givenInput);
  };

  return [value, handleChange];
}

export function useAmountInput(defaultValue = "") {
  const [value, setValue] = useState(
    defaultValue === ""
      ? ""
      : parseInt(extractNumbers(defaultValue)).toLocaleString("en-In"),
  );

  const handleChange = evt => {
    let { value: givenValue } = evt.target;

    if (givenValue === "") {
      setValue("");
      return;
    }

    if (!isAmount(givenValue)) return;

    setValue(parseInt(extractNumbers(givenValue)).toLocaleString("en-In"));
  };

  return [value, handleChange, extractNumbers(value)];
}

export const annualIncome = min => {
  let max = 50000000;
  let step = 100000;
  if (min % 100000) {
    step = 50000;
  }
  let annualIncome = [];
  for (let i = min * 100000; i <= max; i += step) {
    annualIncome.push(i);
    if (i >= 500000) step = 100000;
    if (i >= 10000000) step = 2500000;
  }
  return annualIncome;
};

export const toWords = num => {
  let n = String(num);
  if (n.length >= 8) return `₹ ${n / 10000000} Crore`;
  if (n.length >= 6) return `₹ ${n / 100000} Lakhs`;
};

export const valueToAbr = value => {
  if (!value) return null;
  value = String(value);
  const length = value.length;
  if (length >= 8) {
    return `₹ ${calc(value / Math.pow(10, 7))} Crore`;
  } else if (length >= 6) {
    return `₹ ${calc(value / Math.pow(10, 5))} Lakhs`;
  } else if (length >= 4) {
    return `₹ ${calc(value / Math.pow(10, 3))} K`;
  } else return `₹ ${value}`;
};
export const numberToWords = price => {
  var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
    dblDigit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ],
    tensPlace = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 === dgt
        ? ""
        : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
        (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
      );
    };

  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (((price += ""), isNaN(parseInt(price)))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      switch (
        ((digit = price[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
        price.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 !== digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2]
                    ? " and"
                    : "")
              : "",
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 !== digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2]
                    ? " and"
                    : " Crore")
              : "",
          );
          break;
        default:
          return str;
      }
    str = words.reverse().join("");
  } else str = "";
  return str;
};

const sanitizer = DOMPurify.sanitize;

export function createMarkup(markup = "") {
  return { __html: sanitizer(markup) };
}

export const isValidHttpUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
function calc(value) {
  var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals;
}
export const getInWords = value => {
  if (!value) return null;
  value = String(value);
  const length = value.length;
  if (length >= 8) {
    return `${calc(value / Math.pow(10, 7))} Cr`;
  } else if (length >= 6) {
    return `${calc(value / Math.pow(10, 5))} Lac`;
  } else if (length >= 4) {
    return `${calc(value / Math.pow(10, 3))} Th`;
  } else return value;
};
